import { Component } from '@angular/core';

@Component({
  selector: 'app-specialty-wellness',
  templateUrl: './specialty-wellness.component.html',
  styleUrls: ['./specialty-wellness.component.scss']
})
export class SpecialtyWellnessComponent {

}
