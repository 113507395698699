import { Component } from '@angular/core';

@Component({
  selector: 'app-clinic-home-blogs',
  templateUrl: './clinic-home-blogs.component.html',
  styleUrls: ['./clinic-home-blogs.component.scss']
})
export class ClinicHomeBlogsComponent {

}
