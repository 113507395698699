import { Component } from '@angular/core';

@Component({
  selector: 'app-clinic-home-stats',
  templateUrl: './clinic-home-stats.component.html',
  styleUrls: ['./clinic-home-stats.component.scss']
})
export class ClinicHomeStatsComponent {

}
