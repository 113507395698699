<!-- Cookie Policy Section -->
<div class="py-16 px-6 lg:px-8 bg-white dark:bg-gray-900">
    <div class="mx-auto max-w-7xl">
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-300">
                Cookie Policy
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                Yashada Wellness & Fertility Clinic Cookies Policy
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                    Effective Date: 23 February 2023<br />
                    Last Updated: 23 February 2023
                </p>
            </div>
        </div>
        <div class="mt-10">
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Introduction</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                This Cookies Policy explains how cookies and similar tracking technologies are used on the Yashada
                Wellness
                & Fertility Clinic website. By accessing or using our website, you consent to the use of cookies as
                described in this policy.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">What are Cookies?</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                Cookies are small text files that are placed on your device (computer, smartphone, tablet) when you
                visit a website. They are widely used to make websites work more efficiently, enhance user experience,
                and provide information to website owners.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Types of Cookies We Use</h2>
            <ul class="list-disc ml-8 mb-4 text-gray-600 dark:text-gray-400">
                <li class="mb-2">
                    Essential Cookies: These cookies are necessary for the proper functioning of our website. They
                    enable basic features such as page navigation, secure access, and load balancing.
                </li>
                <li class="mb-2">
                    Performance and Analytics Cookies: We use these cookies to collect information about how visitors
                    interact with our website, including pages visited, time spent on the site, and any error messages
                    encountered. This data helps us improve the performance and functionality of our website.
                </li>
                <li class="mb-2">
                    Functionality Cookies: These cookies allow our website to remember choices you make (such as
                    language preferences or region) and provide enhanced features to personalize your experience.
                </li>
                <li class="mb-2">
                    Advertising and Targeting Cookies: We may partner with third-party advertising networks to display
                    personalized advertisements based on your browsing activities and interests. These cookies track
                    your visits to our website and other sites to provide targeted advertising.
                </li>
            </ul>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Managing Cookies</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                Most web browsers allow you to control or block cookies through their settings. You can also delete
                cookies already stored on your device. However, please note that disabling or deleting certain cookies
                may affect the functionality and user experience of our website.<br />
                To learn more about managing cookies, you can refer to the help documentation or settings of your web
                browser.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Third-Party Cookies</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                Our website may include content or features from third parties, such as social media plugins, embedded
                videos, or interactive maps. These third parties may use cookies to track your interaction with their
                content. We have no control over the cookies used by these third-party providers. Please refer to their
                respective privacy and cookies policies for more information.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Updates to the Cookies Policy</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                We reserve the right to modify or update this Cookies Policy at any time without prior notice. The
                updated policy will be effective immediately upon posting on our website. We encourage you to review
                this policy periodically to stay informed about our use of cookies.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Contact Us</h2>
            <p class="text-gray-600 dark:text-gray-400">
                If you have any questions or concerns about our Cookies Policy, please contact us at
                <a href="mailto:legal&#64;yashada.clinic"
                    class="text-indigo-500 dark:text-indigo-400 underline">legal&#64;yashada.clinic</a>.
            </p>
        </div>
    </div>
</div>