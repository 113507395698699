<div class="py-16">
    <div class="text-center py-16">
        <h2 class=" text-lg font-semibold text-indigo-600">
            Join Our Team
        </h2>
        <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900">
            Be a Part of Our Holistic Wellness Journey
        </h1>
        <div class="mt-6 max-w-xl mx-auto">
            <p class="text-lg leading-8 text-gray-600">
                At Yashada Wellness and Fertility Clinic, we are dedicated to fostering a team that shares our passion
                for
                holistic health and compassionate care. Explore career opportunities with us and contribute to
                transformative experiences for our patients.
            </p>
        </div>
    </div>

    <div class="lg:flex grid grid-cols-1 p-2 lg:flex-wrap max-w-7xl mx-auto items-baseline justify-end">
        <div class="relative mt-2 pb-2 flex items-center flex-grow">
            <input type="text" name="search" id="search" placeholder="Search" [(ngModel)]="searchQuery"
                class="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
            <div class="absolute right-0 flex py-1.5 pr-1.5">
                <a *ngIf="searchQuery" type="button" (click)="clearSearch()"
                    class="absolute inset-y-0 right-0 pr-3 flex items-center">
                    <svg class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
                        <path fill-rule="evenodd"
                            d="M6.707 5.293a1 1 0 0 1 1.414 0L10 8.586l2.879-2.879a1 1 0 1 1 1.414 1.414L11.414 10l2.879 2.879a1 1 0 0 1-1.414 1.414L10 11.414l-2.879 2.879a1 1 0 0 1-1.414-1.414L8.586 10 5.707 7.121a1 1 0 0 1 0-1.414z"
                            clip-rule="evenodd" />
                    </svg>
                </a>
            </div>
        </div>
        <div class="flex justify-end mt-2 lg:mt-0 lg:ml-2">
            <button type="button" (click)="onSearch()"
                class="w-32 h-[36px] rounded-md bg-indigo-600 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                Search
            </button>
        </div>
    </div>

    <div class="flex mx-auto max-w-7xl">
        <!-- Content area -->
        <div class="flex flex-1 flex-col overflow">
            <!-- Main content -->
            <div class="flex flex-1 items-stretch overflow-auto">
                <main class="flex-1 overflow-y-auto">
                    <div class="h-full job-container mx-auto max-w-2xl lg:mx-0 lg:max-w-none"
                        [ngClass]="{ 'grid-cols-3': filteredJobs.length > 0, 'grid-cols-1': filteredJobs.length === 0 }">
                        <div class="grid grid-cols-1 lg:grid-cols-3 gap-x-8">
                            <app-clinic-job-details *ngFor="let job of filteredJobs"
                                [job]="job"></app-clinic-job-details>
                            <div *ngIf="filteredJobs.length == 0"
                                class="grid grid-cols-1 col-start-2 lg:grid-cols-3 text-center">
                                <div class="text-cente"></div>
                                <div class="text-cente">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor"
                                        class="mx-auto h-12 w-12 text-gray-400">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z" />
                                    </svg>
                                    <h3 class="mt-2 text-sm font-semibold text-gray-900">No Matching Jobs</h3>
                                    <p class="mt-1 text-sm text-gray-500">Please search again </p>
                                </div>
                                <div class="text-cente"></div>
                            </div>
                        </div>
                        <div class="loader text-center py-4" infiniteScroll [infiniteScrollDistance]="2"
                            [scrollWindow]="true" (scrolled)="onScroll()"></div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</div>