<div id="hs-scale-animation-modal"
    class="hs-overlay hidden fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none w-full h-full"
    role="dialog" tabindex="-1" aria-labelledby="hs-scale-animation-modal-label">
    <div
        class="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
        <div
            class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-gray-900 dark:border-gray-700 dark:shadow-gray-700/70">
            <div class="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700">
                <h3 id="hs-scale-animation-modal-label" class="font-bold text-gray-800 dark:text-white">Book an
                    Appointment</h3>
                <button type="button"
                    class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-gray-700 dark:hover:bg-gray-600 dark:text-gray-400 dark:focus:bg-gray-600"
                    aria-label="Close" data-hs-overlay="#hs-scale-animation-modal">
                    <span class="sr-only">Close</span>
                    <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                    </svg>
                </button>
            </div>
            <div class="p-4 overflow-y-auto">
                <div
                    class="bg-white sm:mx-auto sm:w-full sm:max-w-md sm:overflow-hidden sm:rounded-lg dark:bg-gray-900">
                    <div class="px-6 py-8 sm:px-10">
                        <form [formGroup]="appointmentForm" (ngSubmit)="onSubmit()" class="space-y-6">
                            <!-- Name Input -->
                            <div>
                                <label for="name"
                                    class="block text-sm font-medium text-gray-700 dark:text-gray-300">Full Name</label>
                                <input id="name" formControlName="name" type="text" placeholder="Full name"
                                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 sm:text-sm" />
                                <div *ngIf="name?.invalid && (name?.dirty || name?.touched)"
                                    class="text-red-600 text-sm mt-1">
                                    <div *ngIf="name?.errors && name?.errors?.['required']">Full name is required.</div>
                                </div>
                            </div>

                            <!-- Is International Patient Checkbox -->
                            <div>
                                <label class="inline-flex items-center">
                                    <input id="isInternational" formControlName="isInternational" type="checkbox"
                                        class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded dark:border-gray-600 dark:bg-gray-700 dark:focus:ring-indigo-500" />
                                    <span class="ml-2 text-sm font-medium text-gray-700 dark:text-gray-300">I am
                                        International Patient</span>
                                </label>
                            </div>

                            <!-- Email Input (optional) -->
                            <div>
                                <label for="email"
                                    class="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
                                <input id="email" formControlName="email" type="email" placeholder="Email address"
                                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 sm:text-sm" />
                                <div *ngIf="email?.invalid && (email?.dirty || email?.touched)"
                                    class="text-red-600 text-sm mt-1">
                                    <div *ngIf="email?.errors && email?.errors?.['email']">Enter a valid email address.
                                    </div>
                                </div>
                            </div>

                            <!-- Mobile Input with Country Code Dropdown -->
                            <div class="flex flex-col space-y-1">
                                <div class="flex items-center space-x-2">
                                    <div class="w-1/4">
                                        <label for="countryCode"
                                            class="block text-sm font-medium text-gray-700 dark:text-gray-300">Code</label>
                                        <select id="countryCode" formControlName="countryCode"
                                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 sm:text-sm">
                                            <option *ngFor="let code of countryCodes" [value]="code">{{ code }}</option>
                                        </select>
                                    </div>
                                    <div class="w-3/4">
                                        <label for="mobile"
                                            class="block text-sm font-medium text-gray-700 dark:text-gray-300">Mobile
                                            Number</label>
                                        <input id="mobile" formControlName="mobile" type="text"
                                            placeholder="Mobile number"
                                            class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 sm:text-sm" />
                                    </div>
                                </div>
                                <div *ngIf="mobile?.invalid && (mobile?.dirty || mobile?.touched)"
                                    class="text-red-600 text-sm mt-1">
                                    <div *ngIf="mobile?.errors?.['required']">Both country code and mobile number are
                                        required.</div>
                                    <div *ngIf="mobile?.errors?.['pattern']">Enter a valid mobile number (10 digits).
                                    </div>
                                </div>
                            </div>

                            <!-- City Dropdown (conditional) -->
                            <div *ngIf="!isInternational">
                                <label for="city"
                                    class="block text-sm font-medium text-gray-700 dark:text-gray-300">City</label>
                                <select id="city" formControlName="city"
                                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 sm:text-sm">
                                    <option *ngFor="let city of cities" [value]="city">{{ city }}</option>
                                </select>
                                <div *ngIf="city?.invalid && (city?.dirty || city?.touched)"
                                    class="text-red-600 text-sm mt-1">
                                    <div *ngIf="city?.errors && city?.errors?.['required']">City is required.</div>
                                </div>
                            </div>

                            <!-- Country Dropdown (conditional) -->
                            <div *ngIf="isInternational">
                                <label for="country"
                                    class="block text-sm font-medium text-gray-700 dark:text-gray-300">Country</label>
                                <select id="country" formControlName="country"
                                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 sm:text-sm">
                                    <option *ngFor="let country of countries" [value]="country">{{ country }}</option>
                                </select>
                                <div *ngIf="country?.invalid && (country?.dirty || country?.touched)"
                                    class="text-red-600 text-sm mt-1">
                                    <div *ngIf="country?.errors && country?.errors?.['required']">Country is required.
                                    </div>
                                </div>
                            </div>

                            <!-- Comments Text Area -->
                            <div>
                                <label for="comments"
                                    class="block text-sm font-medium text-gray-700 dark:text-gray-300">Comments</label>
                                <textarea id="comments" formControlName="comments" rows="4"
                                    placeholder="Additional comments"
                                    class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:border-gray-600 dark:bg-gray-700 dark:text-gray-300 sm:text-sm"></textarea>
                            </div>

                            <!-- Consent Checkbox -->
                            <div class="flex items-start space-x-2">
                                <input id="consent" formControlName="consent" type="checkbox"
                                    class="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded dark:border-gray-600 dark:bg-gray-700 dark:focus:ring-indigo-500" />
                                <label for="consent" class="block text-sm text-gray-900 dark:text-gray-300">
                                    By requesting to book an appointment, you agree to our
                                    <a href="#"
                                        class="font-medium text-indigo-600 hover:underline dark:text-indigo-400">Terms</a>,
                                    <a href="#"
                                        class="font-medium text-indigo-600 hover:underline dark:text-indigo-400">Data
                                        Policy</a>, and
                                    <a href="#"
                                        class="font-medium text-indigo-600 hover:underline dark:text-indigo-400">Cookies
                                        Policy</a>.
                                </label>
                            </div>

                            <!-- Submit and Cancel Buttons -->
                            <div class="flex justify-end gap-4">
                                <button type="submit"
                                    class="py-2 px-4 bg-indigo-600 text-sm text-white rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600">
                                    Plan Your Consultation
                                </button>
                                <button type="button" data-hs-overlay="#hs-scale-animation-modal"
                                    class="py-2 px-4 bg-gray-500 text-sm text-white rounded-md shadow-sm hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500 dark:bg-gray-600 dark:hover:bg-gray-700">
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>