<div class="relative bg-white dark:bg-gray-900">
    <!-- Hero section -->
    <div class="relative py-16 overflow-hidden flex items-center justify-center">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="text-center">
                <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
                    About Us
                </h2>
                <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                    Embracing Holistic Wellness and Fertility with Expertise and Compassion
                </h1>
                <div class="mt-6 max-w-xl mx-auto">
                    <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                        At Yashada Wellness and Fertility Clinic, we combine the ancient wisdom of Ayurveda with modern
                        medical practices to provide comprehensive, compassionate, and personalized care. Our dedicated
                        team of experts is committed to guiding you through every step of your wellness and fertility
                        journey with a focus on holistic healing and individual needs.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- Mission and Vision section -->
    <div class="relative isolate py-16">
        <div aria-hidden="true"
            class="absolute inset-x-0 top-0 -z-10 transform-gpu overflow-hidden opacity-20 blur-3xl dark:opacity-10">
            <div class="w-[82.0625rem] h-[30rem]" style="clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%)">
            </div>
        </div>
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="text-center">
                <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
                    Our Mission and Vision
                </h2>
                <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                    Nurturing Wellness, Fostering Life
                </h1>
                <div class="mt-6 max-w-xl mx-auto">
                    <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                        At Yashada Wellness and Fertility Clinic, our mission and vision are rooted in the timeless
                        principles of Ayurveda. We aim to provide holistic care that nurtures your well-being and
                        supports your fertility journey, helping you realize your dreams of parenthood.
                    </p>
                </div>
            </div>
            <div
                class="mt-16 grid max-w-2xl grid-cols-1 gap-x-16 gap-y-8 text-center sm:mx-auto sm:max-w-none sm:grid-cols-2">
                <div class="rounded-2xl bg-white p-8 dark:bg-gray-800">
                    <div class="flex justify-center mb-6">
                        <div class="relative w-48 h-48">
                            <img alt="Our Mission"
                                class="w-full h-full rounded-full bg-gray-100 object-cover dark:bg-gray-700"
                                src="https://placehold.co/600x400" />
                            <div
                                class="absolute inset-0 rounded-full ring-1 ring-inset ring-gray-900/10 dark:ring-gray-800">
                            </div>
                        </div>
                    </div>
                    <h3 class="text-xl font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-100">
                        Our Mission
                    </h3>
                    <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-400">
                        Our mission is to provide compassionate, personalized, and effective Ayurvedic care that
                        enhances fertility and overall wellness. We are committed to integrating ancient Ayurvedic
                        wisdom with modern techniques to help individuals and couples achieve their dream of parenthood
                        in a natural and holistic way.
                    </p>
                </div>
                <div class="rounded-2xl bg-white p-8 dark:bg-gray-800">
                    <div class="flex justify-center mb-6">
                        <div class="relative w-48 h-48">
                            <img alt="Our Vision"
                                class="w-full h-full rounded-full bg-gray-100 object-cover dark:bg-gray-700"
                                src="https://placehold.co/600x400" />
                            <div
                                class="absolute inset-0 rounded-full ring-1 ring-inset ring-gray-900/10 dark:ring-gray-800">
                            </div>
                        </div>
                    </div>
                    <h3 class="text-xl font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-100">
                        Our Vision
                    </h3>
                    <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-400">
                        Our vision is to be a leading wellness and fertility center known for its expertise in Ayurvedic
                        treatments. We aspire to create a nurturing environment where natural healing and holistic
                        practices come together to improve lives, promote health, and bring the joy of parenthood to
                        families around the world.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- Values section -->
    <div class="mx-auto max-w-7xl py-16 px-6 lg:px-8">
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
                Our Core Values
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                Nurturing Integrity and Compassion
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                    At Yashada Wellness and Fertility Clinic, our core values guide us in delivering exceptional care
                    with a focus on holistic wellness and personalized treatment. We are dedicated to upholding these
                    values to enhance your fertility journey and overall well-being.
                </p>
            </div>
        </div>
        <dl
            class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Integrity
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Upholding honesty and strong ethical principles in every aspect of our care and services.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Compassion
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Providing empathetic and supportive care, understanding the emotional and physical challenges of our
                    patients.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Holistic Approach
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Integrating traditional Ayurvedic practices with modern medicine to offer comprehensive and balanced
                    care.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Excellence
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Committing to the highest standards in our treatments and services to ensure the best possible
                    outcomes for our patients.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Respect
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Valuing each patient’s individuality, cultural background, and personal preferences in all our
                    interactions.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Collaboration
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Fostering a team-oriented environment where our specialists work together to provide integrated and
                    effective care.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Education
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Empowering patients with knowledge about their health and treatment options to make informed
                    decisions.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Innovation
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Continuously exploring new techniques and advancements in both Ayurvedic and modern medicine to
                    enhance our care.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Community
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Contributing positively to the well-being of our local and global communities through ethical
                    practices and support.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Empowerment
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Encouraging our patients and staff to take initiative and actively participate in their wellness and
                    professional growth.
                </dd>
            </div>
        </dl>
    </div>

    <!-- Meet Our Leadership -->
    <app-clinic-home-team></app-clinic-home-team>

    <!-- Job section -->
    <div class="bg-white py-16 dark:bg-gray-900">
        <div class="px-6 sm:px-6 lg:px-8">
            <div class="mx-auto max-w-2xl text-center">
                <div class="text-center">
                    <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
                        Join Our Team
                    </h2>
                    <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                        Be a Part of Our Healing Journey
                    </h1>
                    <div class="mt-6 max-w-xl mx-auto">
                        <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                            At Yashada Wellness and Fertility Clinic, we are always seeking passionate and dedicated
                            individuals to join our team. We offer a supportive and collaborative environment where you
                            can grow professionally and contribute to our mission of holistic wellness and personalized
                            care.
                        </p>
                    </div>
                </div>
                <div class="mt-10 flex items-center justify-center gap-x-6">
                    <a class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        routerLink="/jobs">
                        Explore Job Openings
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>