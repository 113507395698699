<div class="flex justify-start space-x-4">
  <ng-container *ngFor="let tab of tabs; let i = index">
    <a (click)="activateTab(i)" *ngIf="activeTab !== i" class="cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium">{{ tab.title }}</a>
    <a (click)="activateTab(i)" *ngIf="activeTab === i" class="cursor-pointer border-indigo-500 text-indigo-600 whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium" aria-current="page">{{ tab.title }}</a>
  </ng-container>
</div>

<div class="mt-4">
  <ng-content></ng-content>
</div>
