<article class="bg-white p-5 flex max-w-xl flex-col items-start justify-between rounded-md border border-gray-200 m-2">
    <div class="flex w-full items-center gap-x-4 pb-2 text-xs">
        <time datetime="2020-06-04" class="text-gray-500 whitespace-nowrap">{{getPublishedDate()}}</time>
        <div class="flex space-x-2 w-full justify-end">
            <p *ngIf="job.job_category && job.job_category === 'Multicoptor'"
                class="flex items-center space-x-2 leading-7 text-gray-500">
                <svg class="text-gray-400 w-4 h-4" stroke-width="1.5" stroke="currentColor"
                    viewBox="0 0 512.023 512.023" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <path
                            d="m108.011 216.011c13.22 0 25.9-2.39 37.61-6.75l-24.38-24.38c-46.929 8.512-91.704-29.102-91.23-76.87 0-43.01 34.99-78 78-78 47.762-.473 85.39 44.301 76.87 91.23l24.38 24.38c26.737-68.772-27.216-146.705-101.251-145.609-143.268 5.929-143.24 210.084.001 215.999z" />
                        <path
                            d="m327.141 121.241c-8.512-46.929 29.102-91.704 76.87-91.23 43.01 0 78 34.99 78 78 .473 47.762-44.301 85.39-91.23 76.87l-24.38 24.38c68.767 26.738 146.709-27.217 145.609-101.251.001-59.549-48.449-107.999-107.999-107.999-74.024-1.094-127.999 76.843-101.25 145.61z" />
                        <path
                            d="m184.881 390.781c8.512 46.929-29.102 91.704-76.87 91.23-43.01 0-78-34.99-78-78-.473-47.762 44.301-85.39 91.23-76.87l24.38-24.38c-68.767-26.737-146.709 27.218-145.609 101.252-.001 59.549 48.449 107.999 107.999 107.999 74.024 1.094 127.999-76.843 101.25-145.61z" />
                        <path
                            d="m404.011 296.011c-13.22 0-25.9 2.39-37.61 6.75l24.38 24.38c46.929-8.512 91.704 29.102 91.23 76.87 0 43.01-34.99 78-78 78-47.762.473-85.39-44.301-76.87-91.23l-24.38-24.38c-26.737 68.773 27.217 146.706 101.251 145.609 143.268-5.929 143.241-210.084-.001-215.999z" />
                        <path d="m117.511 373.301 21.21 21.21 71.63-71.63c-8.31-5.69-15.52-12.9-21.21-21.21z" />
                        <path
                            d="m373.301 117.511c-20.565 20.565-51.264 51.264-71.63 71.63 8.31 5.69 15.52 12.9 21.21 21.21l71.63-71.63z" />
                        <path
                            d="m138.721 117.511-21.21 21.21 71.63 71.63c5.69-8.31 12.9-15.52 21.21-21.21 0 0-58.546-58.546-71.63-71.63z" />
                        <path
                            d="m301.671 322.881 71.63 71.63 21.21-21.21-71.63-71.63c-5.69 8.31-12.9 15.52-21.21 21.21z" />
                        <circle cx="256.011" cy="256.011" r="51" />
                    </g>
                </svg>
                <span class="text-xs text-gray-500">Multicoptor</span>
            </p>
            <p *ngIf="job.job_category && job.job_category === 'Plane'"
                class="flex items-center space-x-2 leading-7 text-gray-500">
                <svg class="text-gray-400 w-5 h-5" stroke-width="1.5" stroke="currentColor" viewBox="0 0 101 101"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m90.27 67-19.58-31 8.31-13a12.25 12.25 0 0 0 1.94-8.92 6.63 6.63 0 0 0 -2.86-4.31l-.46-.3c-3.73-2.38-9.18-.32-12.4 4.71l-7.81 12.18-43.21-4a1.5 1.5 0 0 0 -1.4.68l-3.2 4.96a1.5 1.5 0 0 0 .77 2.23l36.45 12.68-13.82 21.57-21.45 2.08a1.5 1.5 0 0 0 -1.21.85l-1.7 3.59a1.5 1.5 0 0 0 1.11 2.12l24.75 4.21 14.18 20.67a1.5 1.5 0 0 0 1.16.65h.07a1.5 1.5 0 0 0 1.15-.54l2.56-3a1.5 1.5 0 0 0 .27-1.46l-7.1-20.37 13.44-21 24.57 21.62a1.5 1.5 0 0 0 2.25-.32l3.21-5a1.5 1.5 0 0 0 .01-1.58zm-4.8 3.5-24.57-21.61a1.5 1.5 0 0 0 -2.25.32l-14.78 23.08a1.5 1.5 0 0 0 -.15 1.3l7.06 20.25-.74.88-13.34-19.48a1.5 1.5 0 0 0 -.43-.41l-.27-.16a1.48 1.48 0 0 0 -.56-.22l-23.28-4 .49-1 21.35-2.06a1.5 1.5 0 0 0 1.12-.68l15.25-23.79a1.5 1.5 0 0 0 -.77-2.23l-36.45-12.69 1.68-2.63 43.21 4a1.47 1.47 0 0 0 1.4-.68l8.29-12.89c2.27-3.49 6.02-5.23 8.27-3.8l.47.31a3.76 3.76 0 0 1 1.53 2.41 9.23 9.23 0 0 1 -1.54 6.66l-8.8 13.75a1.5 1.5 0 0 0 0 1.61l19.58 31z" />
                </svg>
                <span class="text-xs text-gray-500">Plane</span>
            </p>
            <p *ngIf="job.job_category && job.job_category === 'Hybrid'"
                class="flex items-center space-x-2 leading-7 text-gray-500">
                <svg class="text-gray-400 w-5 h-5" stroke-width="1.5" stroke="currentColor" viewBox="0 0 101 101"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m90.27 67-19.58-31 8.31-13a12.25 12.25 0 0 0 1.94-8.92 6.63 6.63 0 0 0 -2.86-4.31l-.46-.3c-3.73-2.38-9.18-.32-12.4 4.71l-7.81 12.18-43.21-4a1.5 1.5 0 0 0 -1.4.68l-3.2 4.96a1.5 1.5 0 0 0 .77 2.23l36.45 12.68-13.82 21.57-21.45 2.08a1.5 1.5 0 0 0 -1.21.85l-1.7 3.59a1.5 1.5 0 0 0 1.11 2.12l24.75 4.21 14.18 20.67a1.5 1.5 0 0 0 1.16.65h.07a1.5 1.5 0 0 0 1.15-.54l2.56-3a1.5 1.5 0 0 0 .27-1.46l-7.1-20.37 13.44-21 24.57 21.62a1.5 1.5 0 0 0 2.25-.32l3.21-5a1.5 1.5 0 0 0 .01-1.58zm-4.8 3.5-24.57-21.61a1.5 1.5 0 0 0 -2.25.32l-14.78 23.08a1.5 1.5 0 0 0 -.15 1.3l7.06 20.25-.74.88-13.34-19.48a1.5 1.5 0 0 0 -.43-.41l-.27-.16a1.48 1.48 0 0 0 -.56-.22l-23.28-4 .49-1 21.35-2.06a1.5 1.5 0 0 0 1.12-.68l15.25-23.79a1.5 1.5 0 0 0 -.77-2.23l-36.45-12.69 1.68-2.63 43.21 4a1.47 1.47 0 0 0 1.4-.68l8.29-12.89c2.27-3.49 6.02-5.23 8.27-3.8l.47.31a3.76 3.76 0 0 1 1.53 2.41 9.23 9.23 0 0 1 -1.54 6.66l-8.8 13.75a1.5 1.5 0 0 0 0 1.61l19.58 31z" />
                </svg>
                <span class="text-xs text-gray-500">Plane</span>
            </p>

        </div>
    </div>
    <div class="flex flex-wrap group relative">
        <h3 class="mt-1 pb-2 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
            {{job.job_title}}
        </h3>
        <div *ngIf="this.job.job_is_premium || hasKeywords()" class="flex flex-wrap gap-2 text-xs">
            <span *ngIf="job.job_is_premium"
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-500 text-white">
                Premium
            </span>
            <span *ngFor="let keyword of job.job_keywords.split(',')"
                class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-500 text-white">
                {{ keyword.trim() }}
            </span>
        </div>

        <div class="flex space-x-1 space-y-2 space-x-reverse space-y-reverse flex-wrap mt-2">
            <p class="flex pr-2 text-sm  text-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="text-gray-400 w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                </svg>
                <span class="text-sm pl-2 text-gray-500">{{job.job_location}}</span>
            </p>
            <p class="flex items-center text-sm  text-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="text-gray-400 w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
                </svg>
                <span class="text-sm pl-2 text-gray-500 whitespace-nowrap">{{job.job_experience}}</span>
            </p>
            <p class="flex items-center text-sm  text-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="text-gray-400 w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span class="text-sm pl-2 text-gray-500 whitespace-nowrap">{{job.job_type}}</span>
            </p>
            <p class="flex items-center text-sm text-gray-500">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="text-gray-400 w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15 8.25H9m6 3H9m3 6l-3-3h1.5a3 3 0 100-6M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span class="text-sm pl-2 text-gray-500 whitespace-nowrap">{{job.job_salary_range}}</span>
            </p>
        </div>
        <app-tabs>
            <app-tab [title]="'Overview'">
                <p class="mt-5 text-sm leading-6 text-gray-500">
                    {{job.job_short_description}}
                </p>
            </app-tab>
            <app-tab [title]="'Duties'">
                <ng-container *ngIf="responsibilities && responsibilities.length > 0">
                    <ul class="list-disc list-inside">
                        <li *ngFor="let responsibility of responsibilities"
                            class="items-baseline text-sm leading-6 text-gray-500 flex">
                            <span class="h-1.5 w-1.5 flex-shrink-0 rounded-full bg-indigo-600"></span>
                            <span class="pl-2">{{ responsibility.trim() }}</span>
                        </li>
                    </ul>
                </ng-container>
            </app-tab>
            <app-tab [title]="'Skills'">
                <ng-container *ngIf="requirements && requirements.length > 0">
                    <ul class="list-disc list-inside">
                        <li *ngFor="let requirement of requirements"
                            class="text-sm leading-6 text-gray-500 flex items-baseline">
                            <span class="h-1.5 w-1.5 flex-shrink-0 rounded-full bg-indigo-600"></span>
                            <span class="pl-2">{{ requirement.trim() }}</span>
                        </li>
                    </ul>
                </ng-container>
            </app-tab>
        </app-tabs>

    </div>
    <!--     <div class="mt-5 w-full flex justify-end">
        <div
            class="flex justify-between pr-5 w-full space-x-1 space-y-2 items-start space-x-reverse space-y-reverse flex-wrap mt-2">
            <a class="flex pr-2 text-sm space-x-2 text-gray-500 hover:text-gray-900">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
                </svg>
                <span class="">{{job.job_likes}}</span>
                <span class="sr-only">Likes</span>
            </a>
            <a class="flex items-center space-x-2 text-sm text-gray-500 hover:text-gray-900 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                </svg>
                <span class="">{{job.job_shares}}</span>
                <span class="sr-only">Shares</span>
            </a>
            <a class="flex items-center space-x-2 text-sm  text-gray-500 hover:text-gray-900 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                <span class="">{{job.job_views}}</span>
                <span class="sr-only">Views</span>
            </a>
            <a class="flex items-center space-x-2 text-sm text-gray-500 hover:text-gray-900 cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-5 h-5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
                </svg>
                <span class="">{{job.job_applications}}</span>
                <span class="sr-only">Applications</span>
            </a>
        </div>
    </div> -->
    <div class="mt-3 w-full flex justify-end">
        <a (click)="openApplyForJobDialog()" target="_blank"
            class="cursor-pointer rounded-md bg-indigo-600 px-3.5 text-center w-16 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
            Apply
        </a>
    </div>
</article>