import { Component } from '@angular/core';

@Component({
  selector: 'app-legal-home',
  templateUrl: './legal-home.component.html',
  styleUrls: ['./legal-home.component.scss']
})
export class LegalHomeComponent {

}
