<!-- Contact Us section for both locations -->
<div class="relative isolate py-16 bg-white dark:bg-gray-900">
    <div aria-hidden="true"
        class="absolute inset-x-0 top-0 -z-10 transform-gpu overflow-hidden opacity-20 blur-3xl dark:opacity-10">
        <div class="w-[82.0625rem] h-[30rem]" style="clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%)">
        </div>
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
                Contact Us
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                Get in Touch with Yashada Wellness and Fertility Clinic
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                    We are here to support you on your journey to wellness and fertility. Reach out to us at our Satara
                    or Medha locations.
                </p>
            </div>
        </div>
        <div
            class="mt-16 grid max-w-2xl grid-cols-1 gap-x-16 gap-y-8 text-center sm:mx-auto sm:max-w-none sm:grid-cols-2">
            <!-- Satara Location -->
            <div class="rounded-2xl bg-white p-8 dark:bg-gray-800">
                <h3 class="text-xl font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-100 text-center">
                    Satara Location
                </h3>
                <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-400 text-center">
                    Yashada Wellness & Fertility Clinic<br>
                    321/2A, Nandadeep, Shende Colony, Karanje Peth,<br>
                    Karanje, Satara, Maharashtra 415002, India<br>
                    Phone: +91 9766603547<br>
                    Timings: 10AM - 4PM (Closed on Wednesdays)
                </p>
                <div class="mt-6 flex justify-center">
                    <iframe
                        src="https://www.google.com/maps?q=Yashada+Wellness+%26+Fertility+Clinic,+Satara+@17.79461240940623,73.82935431624404&z=15&output=embed"
                        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>
            <!-- Medha Location -->
            <div class="rounded-2xl bg-white p-8 dark:bg-gray-800">
                <h3 class="text-xl font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-100 text-center">
                    Medha Location
                </h3>
                <p class="mt-4 text-base leading-7 text-gray-600 dark:text-gray-400 text-center">
                    Yashada Wellness & Fertility Clinic<br>
                    Krishna Complex, Bajar Chauk,<br>
                    Medha, Satara, Maharashtra 415012, India<br>
                    Phone: +91 9766603547<br>
                    Timings: 6AM - 9PM (Closed on Wednesdays)
                </p>
                <div class="mt-6 flex justify-center">
                    <iframe
                        src="https://www.google.com/maps?q=Yashada+Wellness+%26+Fertility+Clinic,+Medha+@17.693733543950902,73.99299811966335&z=15&output=embed"
                        width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>
        </div>
    </div>
</div>