<!-- Slider -->
<div data-hs-carousel='{
    "loadingClasses": "opacity-0",
    "isAutoPlay": true
  }' class="relative">
    <div class="hs-carousel relative overflow-hidden w-full min-h-96 bg-white dark:bg-gray-900" style="height: 75vh">
        <div
            class="hs-carousel-body absolute top-0 bottom-0 start-0 flex flex-nowrap transition-transform duration-700 opacity-0">
            <!-- First Slide -->
            <div class="hs-carousel-slide">
                <!-- Overview Section -->
                <div class="relative overflow-hidden">
                    <img src="assets/images/clinic.jpg" alt=""
                        class="absolute inset-0 -z-10 h-full w-full object-cover" />
                    <div class="absolute inset-0 bg-black opacity-50 dark:bg-black dark:opacity-50"></div>
                    <!-- Dark overlay -->
                    <div class="relative pb-16 pt-6 sm:pb-24">
                        <main class="mt-16 sm:mt-24">
                            <div class="mx-auto max-w-7xl">
                                <div class="lg:grid lg:grid-cols-12 lg:gap-8">
                                    <div
                                        class="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
                                        <div>
                                            <div class="hidden sm:mb-4 sm:flex sm:justify-center lg:justify-start">
                                                <!-- Content remains the same -->
                                            </div>
                                            <h1
                                                class="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl">
                                                Welcome to Yashada Wellness & Fertility Clinic
                                            </h1>
                                            <p
                                                class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                                Providing compassionate care and advanced treatments for your wellness
                                                and fertility journey. Let us support you in achieving
                                                your health goals.
                                            </p>
                                            <div class="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                                                <div class="flex flex-wrap items-start justify-between">
                                                    <div class="flex justify-center px-1 space-x-2">
                                                        <button aria-label=" Start Your Journey with Us"
                                                            class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            aria-controls="hs-subscription-with-image"
                                                            data-hs-overlay="#hs-subscription-with-image">
                                                            <div class="space-x-2"><i
                                                                    class="fa-regular fa-circle-play"></i> <span>Why
                                                                    Choose Us</span></div>
                                                        </button>

                                                        <button aria-controls="hs-scale-animation-modal"
                                                            data-hs-overlay="#hs-scale-animation-modal" type="button"
                                                            class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:focus:bg-gray-800">
                                                            <div class="space-x-2">
                                                                <i class="fa-regular fa-calendar"></i>
                                                                <span>Start Your Journey with Us</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>

            <!-- Second Slide -->
            <div class="hs-carousel-slide">
                <!-- Wellness Section -->
                <div class="relative overflow-hidden">
                    <img src="assets/images/wellness.jpg" alt="Wellness Program"
                        class="absolute inset-0 -z-10 h-full w-full object-cover" />
                    <div class="absolute inset-0 bg-black opacity-50 dark:bg-black dark:opacity-60"></div>
                    <!-- Dark overlay -->
                    <div class="relative pb-16 pt-6 sm:pb-24">
                        <main class="mt-16 sm:mt-24">
                            <div class="mx-auto max-w-7xl">
                                <div class="lg:grid lg:grid-cols-12 lg:gap-8">
                                    <div
                                        class="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
                                        <div>
                                            <div class="hidden sm:mb-4 sm:flex sm:justify-center lg:justify-start">
                                                <!-- Content remains the same -->
                                            </div>
                                            <h1
                                                class="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl">
                                                Transform Your Health with Our Wellness Program</h1>
                                            <p
                                                class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                                Rejuvenate your mind, body, and soul through our comprehensive wellness
                                                program. Combining ancient Ayurvedic wisdom with modern practices, we
                                                help you achieve lasting well-being.
                                            </p>
                                            <div class="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                                                <div class="flex flex-wrap items-start justify-between">
                                                    <div class="flex justify-center px-1 space-x-2">
                                                        <button aria-label="Explore Our Wellness Approach"
                                                            class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            aria-controls="hs-subscription-with-image"
                                                            data-hs-overlay="#hs-subscription-with-image">
                                                            <div class="space-x-2"><i
                                                                    class="fa-regular fa-circle-play"></i> <span>Learn
                                                                    More</span></div>
                                                        </button>

                                                        <button aria-controls="hs-scale-animation-modal"
                                                            data-hs-overlay="#hs-scale-animation-modal" type="button"
                                                            class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:focus:bg-gray-800">
                                                            <div class="space-x-2">
                                                                <i class="fa-regular fa-calendar"></i>
                                                                <span>Join Our Program</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>

            </div>

            <!-- Third Slide -->
            <div class="hs-carousel-slide">
                <!-- Fertility Section -->
                <div class="relative overflow-hidden">
                    <img src="assets/images/pregnant-woman.jpg" alt="Ayurvedic Fertility Treatment"
                        class="absolute inset-0 -z-10 h-full w-full object-cover" />
                    <div class="absolute inset-0 bg-black opacity-50 dark:bg-black dark:opacity-60"></div>
                    <!-- Dark overlay -->
                    <div class="relative pb-16 pt-6 sm:pb-24">
                        <main class="mt-16 sm:mt-24">
                            <div class="mx-auto max-w-7xl">
                                <div class="lg:grid lg:grid-cols-12 lg:gap-8">
                                    <div
                                        class="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
                                        <div>
                                            <div class="hidden sm:mb-4 sm:flex sm:justify-center lg:justify-start">
                                                <!-- Content remains the same -->
                                            </div>
                                            <h1
                                                class="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl">
                                                Ayurvedic Fertility Treatment at Yashada</h1>
                                            <p
                                                class="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                                                Discover the natural path to fertility with personalized Ayurvedic
                                                treatments. Our holistic approach nurtures your body, mind,
                                                and spirit, supporting your journey to parenthood.
                                            </p>
                                            <div class="mt-5 w-full sm:mx-auto sm:max-w-lg lg:ml-0">
                                                <div class="flex flex-wrap items-start justify-between">
                                                    <div class="flex justify-center px-1 space-x-2">
                                                        <button aria-label="Learn About Our Approach"
                                                            class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                            aria-controls="hs-subscription-with-image"
                                                            data-hs-overlay="#hs-subscription-with-image">
                                                            <div class="space-x-2"><i
                                                                    class="fa-regular fa-circle-play"></i> <span>Why
                                                                    Choose Us</span></div>
                                                        </button>

                                                        <button aria-controls="hs-scale-animation-modal"
                                                            data-hs-overlay="#hs-scale-animation-modal" type="button"
                                                            class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:focus:bg-gray-800">
                                                            <div class="space-x-2">
                                                                <i class="fa-regular fa-calendar"></i>
                                                                <span>Start Your Journey with Us</span>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <button type="button"
        class="hs-carousel-prev hs-carousel:disabled:opacity-50 disabled:pointer-events-none absolute inset-y-0 start-0 inline-flex justify-center items-center w-[46px] h-full text-gray-800 hover:bg-gray-800/10 focus:outline-none focus:bg-gray-800/10 rounded-s-lg dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10">
        <span class="text-2xl" aria-hidden="true">
            <svg class="shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="m15 18-6-6 6-6"></path>
            </svg>
        </span>
        <span class="sr-only">Previous</span>
    </button>
    <button type="button"
        class="hs-carousel-next hs-carousel:disabled:opacity-50 disabled:pointer-events-none absolute inset-y-0 end-0 inline-flex justify-center items-center w-[46px] h-full text-gray-800 hover:bg-gray-800/10 focus:outline-none focus:bg-gray-800/10 rounded-e-lg dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10">
        <span class="sr-only">Next</span>
        <span class="text-2xl" aria-hidden="true">
            <svg class="shrink-0 size-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="m9 18 6-6-6-6"></path>
            </svg>
        </span>
    </button>

    <div class="hs-carousel-pagination flex justify-center absolute bottom-3 start-0 end-0 space-x-2">
        <span
            class="hs-carousel-active:bg-indigo-700 hs-carousel-active:border-indigo-700 size-3 border border-gray-400 rounded-full cursor-pointer dark:border-gray-600 dark:hs-carousel-active:bg-indigo-500 dark:hs-carousel-active:border-indigo-500"></span>
        <span
            class="hs-carousel-active:bg-indigo-700 hs-carousel-active:border-indigo-700 size-3 border border-gray-400 rounded-full cursor-pointer dark:border-gray-600 dark:hs-carousel-active:bg-indigo-500 dark:hs-carousel-active:border-indigo-500"></span>
        <span
            class="hs-carousel-active:bg-indigo-700 hs-carousel-active:border-indigo-700 size-3 border border-gray-400 rounded-full cursor-pointer dark:border-gray-600 dark:hs-carousel-active:bg-indigo-500 dark:hs-carousel-active:border-indigo-500"></span>
    </div>
</div>
<!-- End Slider -->

<div id="hs-subscription-with-image"
    class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto bg-black/50 dark:bg-black/70"
    role="dialog" tabindex="-1" aria-labelledby="hs-subscription-with-image-label">
    <div
        class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
        <div class="relative flex flex-col bg-white dark:bg-gray-900 shadow-lg rounded-xl">
            <div class="absolute top-2 z-[10] end-2">
                <button type="button" (click)="onCloseModal()"
                    class="inline-flex justify-center items-center size-8 text-sm font-semibold rounded-lg border border-transparent bg-white/10 text-white hover:bg-white/20 disabled:opacity-50 disabled:pointer-events-none"
                    aria-label="Close" data-hs-overlay="#hs-subscription-with-image">
                    <span class="sr-only">Close</span>
                    <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                        viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round">
                        <path d="M18 6 6 18" />
                        <path d="m6 6 12 12" />
                    </svg>
                </button>
            </div>

            <div class="aspect-w-16 aspect-h-8">
                <app-vjs-player
                    [options]="{ autoplay: true, sources: [{ src: 'https://media.vimejs.com/720p.mp4', type: 'video/mp4' }] }"></app-vjs-player>
            </div>

            <div class="p-4 sm:p-10 text-center overflow-y-auto">
                <h3 id="hs-subscription-with-image-label"
                    class="mb-2 text-2xl font-bold text-gray-800 dark:text-gray-200">Yeahhhh 🎉</h3>
                <p class="text-gray-500 dark:text-gray-500">Thank you for your subscription. You will be sent the
                    next issue of our newsletter shortly.</p>

                <div class="mt-6 flex justify-center gap-x-4">
                    <button type="button"
                        class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700 disabled:opacity-50 disabled:pointer-events-none"
                        data-hs-overlay="#hs-subscription-with-image">
                        Got it
                    </button>
                    <a class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-indigo-600 hover:text-indigo-800 focus:outline-none focus:text-indigo-800 disabled:opacity-50 disabled:pointer-events-none dark:text-indigo-500 dark:hover:text-indigo-400 dark:focus:text-indigo-400"
                        href="#">
                        Settings
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>