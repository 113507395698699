import { Component } from '@angular/core';

@Component({
  selector: 'app-staff-calender',
  templateUrl: './staff-calender.component.html',
  styleUrls: ['./staff-calender.component.scss']
})
export class StaffCalenderComponent {

}
