<!-- Services Offered Section -->
<div class="py-16 px-6 lg:px-8 bg-white dark:bg-gray-900">
    <div class="mx-auto max-w-7xl">
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-300">
                Our Services
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                Holistic Solutions for Your Wellness Journey
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                    At Yashada Wellness and Fertility Clinic, we offer a range of holistic services designed to support
                    and
                    enhance your well-being. Our experienced team utilizes a blend of traditional Ayurvedic practices
                    and
                    modern techniques to provide comprehensive and personalized care.
                </p>
            </div>
            <div class="mt-10 flex items-center justify-center gap-x-6">
                <button aria-label="Explore Our Services"
                    class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    id="explore-services" aria-haspopup="dialog" aria-expanded="false"
                    aria-controls="hs-scale-animation-modal" data-hs-overlay="#hs-scale-animation-modal">
                    Explore Our Services
                </button>
            </div>
        </div>
        <dl
            class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 text-gray-900 dark:text-gray-100 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Ayurvedic Consultations
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Personalized Ayurvedic consultations to assess your health and develop customized treatment plans
                    based
                    on your unique needs and wellness goals.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Fertility Treatments
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Specialized fertility treatments combining traditional Ayurvedic therapies with modern practices to
                    support and enhance reproductive health.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Detox and Rejuvenation Programs
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Comprehensive detox and rejuvenation programs designed to cleanse the body, boost vitality, and
                    promote
                    overall well-being through a blend of Ayurvedic treatments and dietary plans.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Yoga and Meditation
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Classes and sessions in yoga and meditation to help balance your mind, body, and spirit, supporting
                    stress relief and mental clarity.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Herbal and Natural Remedies
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Access to a wide range of authentic Ayurvedic herbs and natural remedies tailored to your specific
                    health needs and wellness goals.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Personalized Wellness Plans
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Tailored wellness plans that integrate Ayurvedic principles with modern health practices to address
                    your
                    individual needs and support long-term health.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Holistic Health Workshops
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Educational workshops on various aspects of holistic health, including Ayurvedic nutrition,
                    lifestyle
                    practices, and stress management techniques.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Wellness Counseling
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Professional wellness counseling to guide you through your health journey, offering support and
                    advice
                    on maintaining a balanced and healthy lifestyle.
                </dd>
            </div>
        </dl>
    </div>
</div>