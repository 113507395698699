import { Component } from '@angular/core';

@Component({
  selector: 'app-specialty-fertility',
  templateUrl: './specialty-fertility.component.html',
  styleUrls: ['./specialty-fertility.component.scss']
})
export class SpecialtyFertilityComponent {

}
