import { Component } from '@angular/core';

@Component({
  selector: 'app-patient-calender',
  templateUrl: './patient-calender.component.html',
  styleUrls: ['./patient-calender.component.scss']
})
export class PatientCalenderComponent {

}
