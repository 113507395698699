import { Component } from '@angular/core';

@Component({
  selector: 'app-legal-credits',
  templateUrl: './legal-credits.component.html',
  styleUrls: ['./legal-credits.component.scss']
})
export class LegalCreditsComponent {

}
