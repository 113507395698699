import { Component } from '@angular/core';

@Component({
  selector: 'app-legal-cookies',
  templateUrl: './legal-cookies.component.html',
  styleUrls: ['./legal-cookies.component.scss']
})
export class LegalCookiesComponent {

}
