import { Component } from '@angular/core';

@Component({
  selector: 'app-clinic-about',
  templateUrl: './clinic-about.component.html',
  styleUrls: ['./clinic-about.component.scss']
})
export class ClinicAboutComponent {

}
