import { Component } from '@angular/core';

@Component({
  selector: 'app-doctor-calender',
  templateUrl: './doctor-calender.component.html',
  styleUrls: ['./doctor-calender.component.scss']
})
export class DoctorCalenderComponent {

}
