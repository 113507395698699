<!-- Announcement Banner -->
<div class="bg-gradient-to-r from-red-500 via-purple-400 to-indigo-500">
    <div class="max-w-[85rem] px-4 py-4 sm:px-6 lg:px-8 mx-auto">
        <!-- Grid -->
        <div class="grid justify-center md:grid-cols-2 md:justify-between md:items-center gap-2">
            <div class="text-center md:text-start">
                <p class="text-xs text-white/80 uppercase tracking-wider">
                    Begin Your Journey With Us
                </p>
                <p class="mt-1 text-white font-medium">
                    Join over 1,500 families who have experienced the benefits of our treatments.
                </p>
            </div>
            <!-- End Col -->

            <div class="mt-3 text-center md:text-start md:flex md:justify-end md:items-center">
                <a aria-label="Schedule Your Visit"
                    class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border-2 border-white text-white hover:border-white/70 hover:text-white/70 focus:outline-none focus:border-white/70 focus:text-white/70 disabled:opacity-50 disabled:pointer-events-none"
                    id="book-appointment" aria-haspopup="dialog" aria-expanded="false"
                    aria-controls="hs-scale-animation-modal" data-hs-overlay="#hs-scale-animation-modal">
                    Schedule Your Visit
                </a>
            </div>
            <!-- End Col -->
        </div>
        <!-- End Grid -->
    </div>
</div>
<!-- End Announcement Banner -->