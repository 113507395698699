import { Component, AfterViewInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-layout-scroll',
  templateUrl: './layout-scroll.component.html',
  styleUrls: ['./layout-scroll.component.scss']
})
export class LayoutScrollComponent implements AfterViewInit {

  constructor(private renderer: Renderer2) { }

  ngAfterViewInit(): void {
    const navButtons = document.getElementById('nav-buttons') as HTMLElement;
    const goToTopButton = document.getElementById('go-to-top') as HTMLElement;
    const goToBottomButton = document.getElementById('go-to-bottom') as HTMLElement;
    const chatButton = document.getElementById('chat') as HTMLElement;
    let inactivityTimeout: any;

    const updateButtonVisibility = () => {
      if (window.scrollY === 0) {
        goToTopButton.classList.add('hidden');
      } else {
        goToTopButton.classList.remove('hidden');
      }

      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        goToBottomButton.classList.add('hidden');
      } else {
        goToBottomButton.classList.remove('hidden');
      }
    };

    const showButtons = () => {
      navButtons.classList.remove('opacity-0');
      resetInactivityTimeout();
    };

    const hideButtons = () => {
      navButtons.classList.add('opacity-0');
    };

    const resetInactivityTimeout = () => {
      clearTimeout(inactivityTimeout);
      inactivityTimeout = setTimeout(hideButtons, 2000);
    };

    window.addEventListener('scroll', () => {
      updateButtonVisibility();
      showButtons();
    });

    document.addEventListener('mousemove', showButtons);
    document.addEventListener('keydown', showButtons);
    document.addEventListener('touchstart', showButtons);

    goToTopButton.addEventListener('click', () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    });

    goToBottomButton.addEventListener('click', () => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    });

    chatButton.addEventListener('click', () => {
      // Implement chat functionality here
      console.log('Chat clicked!');
    });

    // Initial check
    updateButtonVisibility();
    showButtons();
  }
}
