<div *ngIf="show" class="h-screen w-screen fixed inset-0 flex items-center justify-center z-10 bg-white">
  <div class="content">
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="space-y-12">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Applicant Information</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Please provide all the required details below.</p>
          <div class="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <label for="first-name" class="block text-sm font-medium leading-6 text-gray-900">
                Full name<span class="text-red-500">*</span>
              </label>
              <div class="mt-2">
                <input formControlName="fullName" id="first-name" autocomplete="given-name"
                  class="pl-3 block w-full rounded-md border border-gray-200 py-1.5 text-gray-900 shadow-sm placeholder:text-gray-400 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <div *ngIf="form.controls['fullName'].invalid && form.controls['fullName'].touched"
                  class="text-red-500 mt-1 text-sm">
                  Full name is required.
                </div>
              </div>
            </div>
            <div class="col-span-full">
              <label for="email" class="block text-sm font-medium leading-6 text-gray-900">
                Email address<span class="text-red-500">*</span>
              </label>
              <div class="mt-2">
                <input type="email" name="email" id="email" [value]="userEmail" disabled
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                  placeholder="you@example.com">
              </div>
            </div>
            <div class="col-span-full">
              <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-900">
                Resume<span class="text-red-500">*</span>
              </label>
              <div class="col-span-full pt-2">
                <label for="file-input" class="sr-only">Choose file</label>
                <input (change)="handleFileChange($event)" formControlName="resumeFile" type="file" id="file-input"
                  class="block w-full border border-gray-200 shadow-sm rounded-md text-sm focus:z-10 focus:border-indigo-500 focus:ring-indigo-500 file:bg-transparent file:border-0 file:bg-gray-100 file:mr-4 file:py-3 file:px-4">
                <div *ngIf="form.controls['resumeFile'].invalid && form.controls['resumeFile'].touched"
                  class="text-red-500 text-sm pt-1">
                  Resume is required.
                </div>
                <div *ngIf="fileSizeExceeded" class="text-red-500 text-sm pt-1">
                  File size exceeds the maximum limit of 5MB.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex mt-5 items-center justify-end gap-x-6">
        <button *ngIf="messageType === 'success'" (click)="close()" type="button"
          class="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100">Close</button>
        <button *ngIf="messageType !== 'success'" (click)="close()" type="button"
          class="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100">Cancel</button>
        <button *ngIf="messageType !== 'success'" type="submit"
          class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Apply</button>
      </div>
      <div *ngIf="message" class="mt-5 text-sm"
        [ngClass]="{'text-green-500': messageType === 'success', 'text-red-500': messageType === 'error'}">
        {{ message }}
      </div>
    </form>
  </div>
</div>