import { Component } from '@angular/core';

@Component({
  selector: 'app-staff-account',
  templateUrl: './staff-account.component.html',
  styleUrls: ['./staff-account.component.scss']
})
export class StaffAccountComponent {

}
