import { Component } from '@angular/core';

@Component({
  selector: 'app-clinic-services',
  templateUrl: './clinic-services.component.html',
  styleUrls: ['./clinic-services.component.scss']
})
export class ClinicServicesComponent {

}
