<!-- Credits Policy Section -->
<div class="py-16 px-6 lg:px-8 bg-white dark:bg-gray-900">
    <div class="mx-auto max-w-7xl">
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-300">
                Credits Policy
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                Yashada Wellness & Fertility Clinic Credits Policy
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                    Effective Date: 23 February 2023<br />
                    Last Updated: 23 February 2023
                </p>
            </div>
        </div>
        <div class="mt-10">
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Introduction</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                Thank you for visiting our website. This Website Credits Policy explains how website content,
                intellectual property, and third-party attribution are handled on our website. By accessing or using our
                website, you agree to comply with this policy.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Content Ownership</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                All original content on our website, including text, graphics, images, videos, and other multimedia
                elements, is the property of Yashada Wellness & Fertility Clinic unless otherwise stated. This content
                is protected by applicable intellectual property laws and international treaties. Any unauthorized use,
                reproduction, or distribution of this content is strictly prohibited.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Third-Party Content and Attribution</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                We may include third-party content on our website, such as images, videos, icons, or graphics. We make
                reasonable efforts to ensure that we have the necessary rights or permissions to use such content.
                Third-party content is credited appropriately, either through explicit attribution or by referencing the
                source.
            </p>
            <p class="mb-4 text-gray-600 dark:text-gray-400">Third-party content credits may appear in various forms,
                such as:</p>
            <ol class="list-decimal ml-8 mb-4 text-gray-600 dark:text-gray-400">
                <li class="mb-2">Attribution within the content itself.</li>
                <li class="mb-2">Attribution in the form of captions or alt text for images or videos.</li>
                <li class="mb-2">Attribution in the form of hyperlinks or citations within the text.</li>
            </ol>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                We strive to accurately attribute all third-party content used on our website. If you believe there is
                an error or omission in the attribution, please contact us at
                <a href="mailto:legal&#64;yashada.clinic"
                    class="text-indigo-500 dark:text-indigo-400 underline">legal&#64;yashada.clinic</a> to rectify the
                situation.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">User-Generated Content</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                Our website may allow users to submit or contribute content, such as comments, reviews, or testimonials.
                By submitting content to our website, users grant us a non-exclusive, royalty-free, perpetual, and
                worldwide license to use, reproduce, modify, adapt, publish, translate, distribute, and display such
                content in any media.
            </p>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                If you believe that your copyrighted work has been used on our website without proper authorization,
                please contact us at
                <a href="mailto:legal&#64;yashada.clinic"
                    class="text-indigo-500 dark:text-indigo-400 underline">legal&#64;yashada.clinic</a> with the
                following
                information:
            </p>
            <ol class="list-decimal ml-8 mb-4 text-gray-600 dark:text-gray-400">
                <li class="mb-2">Identification of the copyrighted work claimed to have been infringed.</li>
                <li class="mb-2">Sufficient information to locate the allegedly infringing material on our website.</li>
                <li class="mb-2">Your contact information, including name, address, telephone number, and email address.
                </li>
                <li class="mb-2">A statement that you have a good-faith belief that the use of the copyrighted material
                    is not authorized by the copyright owner, its agent, or the law.</li>
                <li class="mb-2">A statement, made under penalty of perjury, that the information provided in your
                    notice is accurate and that you are the copyright owner or authorized to act on behalf of the
                    copyright owner.</li>
            </ol>
            <p class="mb-4 text-gray-600 dark:text-gray-400">We will promptly investigate and take appropriate action
                upon receiving a valid copyright infringement notice.</p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Updates to the Website Credits Policy
            </h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                We reserve the right to modify or update this Website Credits Policy at any time without prior notice.
                The updated policy will be effective immediately upon posting on our website. We encourage you to review
                this policy periodically to stay informed about how we handle website credits and content ownership.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Contact Us</h2>
            <p class="text-gray-600 dark:text-gray-400">
                If you have any questions or concerns about our Website Credits Policy, please contact us at
                <a href="mailto:legal&#64;yashada.clinic"
                    class="text-indigo-500 dark:text-indigo-400 underline">legal&#64;yashada.clinic</a>.
            </p>
        </div>
    </div>
</div>