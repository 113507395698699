<!-- Blog Section -->
<div class="bg-white py-24 sm:py-32 dark:bg-gray-900">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl text-center">
            <div class="text-center">
                <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
                    Our Insights and Updates
                </h2>
                <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                    Explore the Latest Trends and Expert Advice
                </h1>
                <div class="mt-6 max-w-xl mx-auto">
                    <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                        Stay informed with our latest blog posts. At Yashada Wellness and Fertility Clinic, we share
                        valuable insights, updates, and tips on holistic wellness, fertility, and Ayurvedic practices.
                        Our blog is a resource to help you stay updated and empowered on your journey to well-being.
                    </p>
                </div>
                <div class="mt-10 flex items-center justify-center gap-x-6">
                    <button aria-label="Start Your Journey with Us"
                        class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600 dark:focus:ring-indigo-400"
                        id="book-appointment" aria-haspopup="dialog" aria-expanded="false"
                        aria-controls="hs-scale-animation-modal" data-hs-overlay="#hs-scale-animation-modal">
                        Book Appointment for Consultation
                    </button>
                </div>
            </div>
        </div>
        <div class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <article class="flex flex-col items-start justify-between">
                <div class="relative w-full">
                    <img src="assets/images/blogs/blog-ayurvedic-wellness.jpg" alt=""
                        class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]">
                    <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10 dark:ring-gray-800">
                    </div>
                </div>
                <div class="max-w-xl">
                    <div class="mt-8 flex items-center gap-x-4 text-xs">
                        <time datetime="2024-08-01" class="text-gray-500 dark:text-gray-400">Aug 01, 2024</time>
                        <a href="#"
                            class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700">Wellness</a>
                    </div>
                    <div class="group relative">
                        <h3
                            class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600 dark:text-gray-100 group-hover:dark:text-gray-300">
                            <a href="#">
                                <span class="absolute inset-0"></span>
                                The Benefits of Ayurvedic Treatments for Modern Living
                            </a>
                        </h3>
                        <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600 dark:text-gray-400">Discover how
                            traditional Ayurvedic
                            practices can enhance your well-being and integrate with contemporary lifestyles. Our expert
                            advice covers the benefits and applications of Ayurvedic treatments in modern contexts.</p>
                    </div>
                    <div class="relative mt-8 flex items-center gap-x-4">
                        <img src="assets/images/snehal.jpeg" alt=""
                            class="h-10 w-10 rounded-full bg-gray-100 dark:bg-gray-800">
                        <div class="text-sm leading-6">
                            <p class="font-semibold text-gray-900 dark:text-gray-100">
                                <a href="#">
                                    <span class="absolute inset-0"></span>
                                    Dr. Snehal Sabale
                                </a>
                            </p>
                            <p class="text-gray-600 dark:text-gray-400">Co-Founder / CEO</p>
                        </div>
                    </div>
                </div>
            </article>

            <article class="flex flex-col items-start justify-between">
                <div class="relative w-full">
                    <img src="assets/images/blogs/blog-ayurvedic-fertility.jpg" alt=""
                        class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]">
                    <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10 dark:ring-gray-800">
                    </div>
                </div>
                <div class="max-w-xl">
                    <div class="mt-8 flex items-center gap-x-4 text-xs">
                        <time datetime="2024-08-15" class="text-gray-500 dark:text-gray-400">Aug 15, 2024</time>
                        <a href="#"
                            class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700">Fertility</a>
                    </div>
                    <div class="group relative">
                        <h3
                            class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600 dark:text-gray-100 group-hover:dark:text-gray-300">
                            <a href="#">
                                <span class="absolute inset-0"></span>
                                Enhancing Fertility with Ayurvedic Practices
                            </a>
                        </h3>
                        <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600 dark:text-gray-400">Explore how
                            Ayurvedic methods can
                            support fertility and reproductive health. This blog post offers practical advice and
                            insights into how holistic approaches can complement modern fertility treatments.</p>
                    </div>
                    <div class="relative mt-8 flex items-center gap-x-4">
                        <img src="assets/images/snehal.jpeg" alt=""
                            class="h-10 w-10 rounded-full bg-gray-100 dark:bg-gray-800">
                        <div class="text-sm leading-6">
                            <p class="font-semibold text-gray-900 dark:text-gray-100">
                                <a href="#">
                                    <span class="absolute inset-0"></span>
                                    Dr. Snehal Sabale
                                </a>
                            </p>
                            <p class="text-gray-600 dark:text-gray-400">Co-Founder / COO</p>
                        </div>
                    </div>
                </div>
            </article>

            <article class="flex flex-col items-start justify-between">
                <div class="relative w-full">
                    <img src="assets/images/blogs/blog-ayurvedic-lifestyle.jpg" alt=""
                        class="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]">
                    <div class="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10 dark:ring-gray-800">
                    </div>
                </div>
                <div class="max-w-xl">
                    <div class="mt-8 flex items-center gap-x-4 text-xs">
                        <time datetime="2024-08-23" class="text-gray-500 dark:text-gray-400">Aug 23, 2024</time>
                        <a href="#"
                            class="relative z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600 hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-300 dark:hover:bg-gray-700">Health
                            Tips</a>
                    </div>
                    <div class="group relative">
                        <h3
                            class="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600 dark:text-gray-100 group-hover:dark:text-gray-300">
                            <a href="#">
                                <span class="absolute inset-0"></span>
                                Simple Lifestyle Changes for Better Health
                            </a>
                        </h3>
                        <p class="mt-5 line-clamp-3 text-sm leading-6 text-gray-600 dark:text-gray-400">Learn how small
                            adjustments in your
                            daily routine can lead to significant improvements in your overall health. Our blog post
                            provides actionable tips and strategies for a healthier lifestyle.</p>
                    </div>
                    <div class="relative mt-8 flex items-center gap-x-4">
                        <img src="assets/images/snehal.jpeg" alt=""
                            class="h-10 w-10 rounded-full bg-gray-100 dark:bg-gray-800">
                        <div class="text-sm leading-6">
                            <p class="font-semibold text-gray-900 dark:text-gray-100">
                                <a href="#">
                                    <span class="absolute inset-0"></span>
                                    Dr. Snehal Sabale
                                </a>
                            </p>
                            <p class="text-gray-600 dark:text-gray-400">Co-Founder / CEO</p>
                        </div>
                    </div>
                </div>
            </article>

            <!-- More blog posts can be added here -->
        </div>
    </div>
</div>