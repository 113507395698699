import { Component } from '@angular/core';

@Component({
  selector: 'app-layout-banner',
  templateUrl: './layout-banner.component.html',
  styleUrls: ['./layout-banner.component.scss']
})
export class LayoutBannerComponent {

}
