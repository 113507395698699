import { ApplicationRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { switchMap, tap } from 'rxjs/operators';

import { createAction, createReducer, on, createFeatureSelector, createSelector, props } from '@ngrx/store';

//  Interfaces and Initial s
export interface GeneralPreferences {
    theme: string;
    language: string;
}

export const initialGeneralPreferences: GeneralPreferences = {
    theme: 'light',
    language: 'en',
};

export interface CookiePreferences {
    necessary: boolean;
    preferences: boolean;
    statistics: boolean;
    marketing: boolean;
}

export const initialCookiePreferences: CookiePreferences = {
    necessary: true, // This is usually always true
    preferences: false,
    statistics: false,
    marketing: false,
};

// Actions
// General Preferences Actions
export const loadGeneralPreferences = createAction('[General Preferences] Load Preferences');
export const saveGeneralPreferences = createAction(
    '[General Preferences] Save Preferences',
    props<{ preferences: GeneralPreferences }>()
);
export const updateTheme = createAction(
    '[General Preferences] Update Theme',
    props<{ theme: string }>()
);
export const updateLanguage = createAction(
    '[General Preferences] Update Language',
    props<{ language: string }>()
);

// Cookie Preferences Actions
export const loadCookiePreferences = createAction('[Cookie Preferences] Load Preferences');
export const saveCookiePreferences = createAction(
    '[Cookie Preferences] Save Preferences',
    props<{ preferences: CookiePreferences }>()
);
export const updateCookiePreferences = createAction(
    '[Cookie Preferences] Update Preferences',
    props<{ preferences: CookiePreferences }>()
);

// Reducers
// General Preferences Reducer
export const generalPreferencesReducer = createReducer(
    initialGeneralPreferences,
    on(loadGeneralPreferences, (state) => {
        const storedPreferences = localStorage.getItem('generalPreferences');
        return storedPreferences ? JSON.parse(storedPreferences) : state;
    }),
    on(saveGeneralPreferences, (state, { preferences }) => {
        localStorage.setItem('generalPreferences', JSON.stringify(preferences));
        return { ...state, ...preferences };
    }),
    on(updateTheme, (state, { theme }) => ({ ...state, theme })),
    on(updateLanguage, (state, { language }) => ({ ...state, language }))
);

// Cookie Preferences Reducer
export const cookiePreferencesReducer = createReducer(
    initialCookiePreferences,
    on(loadCookiePreferences, (state) => {
        const storedPreferences = localStorage.getItem('cookiePreferences');
        return storedPreferences ? JSON.parse(storedPreferences) : state;
    }),
    on(saveCookiePreferences, (state, { preferences }) => {
        localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
        return { ...state, ...preferences };
    }),
    on(updateCookiePreferences, (state, { preferences }) => ({ ...state, ...preferences }))
);

// Selectors
// General Preferences Selectors
export const selectGeneralPreferences = createFeatureSelector<GeneralPreferences>('generalPreferences');

export const selectTheme = createSelector(
    selectGeneralPreferences,
    (state: GeneralPreferences) => state.theme
);

export const selectLanguage = createSelector(
    selectGeneralPreferences,
    (state: GeneralPreferences) => state.language
);

// Cookie Preferences Selectors
export const selectCookiePreferences = createFeatureSelector<CookiePreferences>('cookiePreferences');

export const selectNecessaryCookies = createSelector(
    selectCookiePreferences,
    (state: CookiePreferences) => state.necessary
);

export const selectPreferencesCookies = createSelector(
    selectCookiePreferences,
    (state: CookiePreferences) => state.preferences
);

export const selectStatisticsCookies = createSelector(
    selectCookiePreferences,
    (state: CookiePreferences) => state.statistics
);

export const selectMarketingCookies = createSelector(
    selectCookiePreferences,
    (state: CookiePreferences) => state.marketing
);

export function stateInitializerFactory(
    store: Store,
    appRef: ApplicationRef
): () => Promise<void> {
    return () => {
        return new Promise<void>((resolve) => {
            // Dispatch actions to load preferences
            store.dispatch(loadGeneralPreferences());
            store.dispatch(loadCookiePreferences());

            // Wait for the store to be updated with the preferences
            store.select((state: any) => state.generalPreferences).pipe(
                switchMap((generalPreferences: GeneralPreferences) =>
                    store.select((state: any) => state.cookiePreferences).pipe(
                        tap((cookiePreferences: CookiePreferences) => {
                            // Preferences loaded, resolve the promise
                            resolve();
                        })
                    )
                )
            ).subscribe();
        });
    };
}
