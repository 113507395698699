<!-- Educational Resources Section -->
<div class="mx-auto max-w-7xl py-16 px-6 lg:px-8 bg-white dark:bg-gray-900">
    <div class="text-center">
        <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
            Educational Resources
        </h2>
        <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
            Empowering You with Knowledge for Better Health
        </h1>
        <div class="mt-6 max-w-xl mx-auto">
            <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                At Yashada Wellness and Fertility Clinic, we believe that knowledge is key to achieving and maintaining
                optimal health. Our educational resources are designed to provide you with the information and tools you
                need to make informed decisions about your well-being.
            </p>
        </div>
        <div class="mt-10 flex items-center justify-center gap-x-6">
            <button aria-label="Explore Educational Resources"
                class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                id="explore-resources" aria-haspopup="dialog" aria-expanded="false"
                aria-controls="hs-scale-animation-modal" data-hs-overlay="#hs-scale-animation-modal">
                Explore Our Resources
            </button>
        </div>
    </div>
    <dl
        class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
        <div>
            <dt class="font-semibold text-gray-900 dark:text-gray-100">
                Ayurvedic Health Guides
            </dt>
            <dd class="mt-1 text-gray-600 dark:text-gray-400">
                Comprehensive guides on Ayurvedic principles, lifestyle practices, and dietary recommendations to
                support your journey to wellness.
            </dd>
        </div>
        <div>
            <dt class="font-semibold text-gray-900 dark:text-gray-100">
                Fertility Education
            </dt>
            <dd class="mt-1 text-gray-600 dark:text-gray-400">
                In-depth resources on fertility, reproductive health, and Ayurvedic approaches to enhancing your chances
                of conception naturally.
            </dd>
        </div>
        <div>
            <dt class="font-semibold text-gray-900 dark:text-gray-100">
                Wellness Webinars
            </dt>
            <dd class="mt-1 text-gray-600 dark:text-gray-400">
                Join our expert-led webinars on various health topics, including stress management, holistic nutrition,
                and Ayurvedic treatments.
            </dd>
        </div>
        <div>
            <dt class="font-semibold text-gray-900 dark:text-gray-100">
                Video Tutorials
            </dt>
            <dd class="mt-1 text-gray-600 dark:text-gray-400">
                Access a library of video tutorials that demonstrate Ayurvedic techniques, yoga practices, and wellness
                routines.
            </dd>
        </div>
        <div>
            <dt class="font-semibold text-gray-900 dark:text-gray-100">
                Research Articles
            </dt>
            <dd class="mt-1 text-gray-600 dark:text-gray-400">
                Stay informed with the latest research and findings in the fields of Ayurvedic medicine and holistic
                health, curated by our team.
            </dd>
        </div>
        <div>
            <dt class="font-semibold text-gray-900 dark:text-gray-100">
                Patient Success Stories
            </dt>
            <dd class="mt-1 text-gray-600 dark:text-gray-400">
                Learn from the experiences of others who have achieved their health goals through our treatments and
                programs, inspiring you on your own journey.
            </dd>
        </div>
        <div>
            <dt class="font-semibold text-gray-900 dark:text-gray-100">
                Healthy Recipes
            </dt>
            <dd class="mt-1 text-gray-600 dark:text-gray-400">
                Discover a collection of healthy and delicious recipes based on Ayurvedic dietary principles, designed
                to nourish your body and mind.
            </dd>
        </div>
        <div>
            <dt class="font-semibold text-gray-900 dark:text-gray-100">
                Personalized Wellness Plans
            </dt>
            <dd class="mt-1 text-gray-600 dark:text-gray-400">
                Utilize our educational tools to create personalized wellness plans that align with your health goals
                and lifestyle.
            </dd>
        </div>
    </dl>
</div>