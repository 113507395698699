import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Job } from './job.model';
import { API, Storage } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class JobService {
  private jobs: Job[] = [
    {
      "job_title": "Ayurvedic Practitioner",
      "job_code": "AP001",
      "job_company_email": "careers@yashadawellness.com",
      "job_company_phone": "+91 1234567890",
      "job_short_description": "Join our team as an Ayurvedic Practitioner to provide personalized treatments and holistic wellness solutions based on traditional Ayurvedic principles.",
      "job_keywords": "Ayurveda, Wellness, Holistic Health, Herbal Medicine, Patient Care",
      "job_location": "Medha, Satara",
      "job_experience": "2 years",
      "job_type": "Full-time",
      "job_responsibilities": "Conduct patient consultations and assessments.\nDevelop and administer customized Ayurvedic treatments.\nMaintain accurate patient records and follow-up care.\nEducate patients on Ayurvedic principles and lifestyle recommendations.\nCollaborate with other healthcare professionals to ensure comprehensive patient care.",
      "job_requirements": "BAMS degree or equivalent qualification in Ayurveda.\nProven experience in Ayurvedic practice.\nStrong knowledge of Ayurvedic treatments and herbal medicine.\nExcellent patient communication and interpersonal skills.\nAbility to work in a team environment and maintain professionalism.",
      "job_category": "Healthcare",
      "job_sub_category": "Ayurvedic Medicine",
      "job_classification": "Mid-level",
      "job_salary_range": "8,00,000 - 12,00,000",
      "job_is_premium": false,
      "job_header_image": "https://example.com/images/ayurvedic_practitioner_header.jpg",
      "job_header_image_alt": "Ayurvedic Practitioner Header Image",
      "job_header_image_credit": "John Doe",
      "job_publish_date": "1719705600",
      "job_author_profile_image": "https://example.com/images/dr_mahesh_sabale.jpg",
      "job_author": "Dr. Mahesh Sabale",
      "job_author_credentials": "Co-Founder & Senior Ayurvedic Practitioner",
      "job_author_summary": "Experienced Ayurvedic practitioner with a passion for holistic health and patient care.",
      "job_author_linkedin": "https://www.linkedin.com/in/dr-mahesh-sabale",
      "job_company_logo": "https://example.com/images/yashada_logo.svg",
      "job_company_name": "Yashada Wellness & Fertility Center",
      "job_company_title": "Ayurvedic Wellness Clinic",
      "job_company_description": "Specializing in personalized Ayurvedic treatments and holistic health solutions.",
      "job_company_linkedin": "https://www.linkedin.com/company/yashada-wellness",
      "job_company_facebook": "https://www.facebook.com/yashadawellness",
      "job_company_instagram": "https://www.instagram.com/yashadawellness",
      "job_company_twitter": "https://twitter.com/yashadawellness",
      "job_company_github": "https://github.com/yashadawellness",
      "job_company_youtube": "https://www.youtube.com/@yashadawellness",
      "job_likes": 30,
      "job_views": 150,
      "job_applications": 15,
      "job_shares": 40,
      "job_status": "active"
    },
    {
      "job_title": "Nutritional Consultant",
      "job_code": "NC002",
      "job_company_email": "careers@yashadawellness.com",
      "job_company_phone": "+91 1234567890",
      "job_short_description": "We are looking for a knowledgeable Nutritional Consultant to provide dietary guidance and support for our patients, helping them achieve their health and wellness goals.",
      "job_keywords": "Nutrition, Dietetics, Wellness, Patient Counseling, Health Optimization",
      "job_location": "Medha, Satara",
      "job_experience": "1 year",
      "job_type": "Part-time",
      "job_responsibilities": "Conduct dietary assessments and consultations.\nCreate personalized nutrition plans for patients.\nMonitor patient progress and adjust plans as needed.\nEducate patients on healthy eating habits and lifestyle changes.\nCollaborate with Ayurvedic practitioners to integrate nutrition into treatment plans.",
      "job_requirements": "Degree in Nutrition, Dietetics, or a related field.\nExperience in nutritional consulting or counseling.\nStrong understanding of dietary guidelines and health optimization.\nExcellent communication skills and patient interaction.\nAbility to work independently and as part of a team.",
      "job_category": "Healthcare",
      "job_sub_category": "Nutrition",
      "job_classification": "Entry-level",
      "job_salary_range": "4,00,000 - 6,00,000",
      "job_is_premium": false,
      "job_header_image": "https://example.com/images/nutritional_consultant_header.jpg",
      "job_header_image_alt": "Nutritional Consultant Header Image",
      "job_header_image_credit": "Jane Doe",
      "job_publish_date": "1719705600",
      "job_author_profile_image": "https://example.com/images/dr_snehal_sabale.jpg",
      "job_author": "Dr. Snehal Sabale",
      "job_author_credentials": "Co-Founder & Senior Ayurvedic Practitioner",
      "job_author_summary": "Dedicated practitioner with expertise in Ayurvedic medicine and nutrition.",
      "job_author_linkedin": "https://www.linkedin.com/in/dr-snehal-sabale",
      "job_company_logo": "https://example.com/images/yashada_logo.svg",
      "job_company_name": "Yashada Wellness & Fertility Center",
      "job_company_title": "Ayurvedic Wellness Clinic",
      "job_company_description": "Offering expert guidance in holistic health and wellness through Ayurvedic principles.",
      "job_company_linkedin": "https://www.linkedin.com/company/yashada-wellness",
      "job_company_facebook": "https://www.facebook.com/yashadawellness",
      "job_company_instagram": "https://www.instagram.com/yashadawellness",
      "job_company_twitter": "https://twitter.com/yashadawellness",
      "job_company_github": "https://github.com/yashadawellness",
      "job_company_youtube": "https://www.youtube.com/@yashadawellness",
      "job_likes": 20,
      "job_views": 80,
      "job_applications": 8,
      "job_shares": 25,
      "job_status": "active"
    },
    {
      "job_title": "Wellness Coordinator",
      "job_code": "WC003",
      "job_company_email": "careers@yashadawellness.com",
      "job_company_phone": "+91 1234567890",
      "job_short_description": "Join us as a Wellness Coordinator to manage and coordinate wellness programs, ensuring a high-quality experience for our patients.",
      "job_keywords": "Wellness Management, Program Coordination, Patient Care, Event Planning",
      "job_location": "Medha, Satara",
      "job_experience": "2 years",
      "job_type": "Full-time",
      "job_responsibilities": "Plan and coordinate wellness programs and events.\nManage patient appointments and follow-ups.\nCollaborate with practitioners to develop program content.\nEnsure high standards of patient care and service.\nHandle administrative tasks and maintain program records.",
      "job_requirements": "Experience in wellness or program coordination.\nStrong organizational and management skills.\nExcellent interpersonal and communication skills.\nAbility to multitask and manage various aspects of wellness programs.\nProficiency in administrative software and tools.",
      "job_category": "Administrative",
      "job_sub_category": "Wellness Coordination",
      "job_classification": "Mid-level",
      "job_salary_range": "6,00,000 - 8,00,000",
      "job_is_premium": false,
      "job_header_image": "https://example.com/images/wellness_coordinator_header.jpg",
      "job_header_image_alt": "Wellness Coordinator Header Image",
      "job_header_image_credit": "John Smith",
      "job_publish_date": "1719705600",
      "job_author_profile_image": "https://example.com/images/dr_mahesh_sabale.jpg",
      "job_author": "Dr. Mahesh Sabale",
      "job_author_credentials": "Co-Founder & Senior Ayurvedic Practitioner",
      "job_author_summary": "Expert in managing wellness programs with a focus on enhancing patient experiences.",
      "job_author_linkedin": "https://www.linkedin.com/in/dr-mahesh-sabale",
      "job_company_logo": "https://example.com/images/yashada_logo.svg",
      "job_company_name": "Yashada Wellness & Fertility Center",
      "job_company_title": "Ayurvedic Wellness Clinic",
      "job_company_description": "Dedicated to providing exceptional wellness programs and holistic health solutions.",
      "job_company_linkedin": "https://www.linkedin.com/company/yashada-wellness",
      "job_company_facebook": "https://www.facebook.com/yashadawellness",
      "job_company_instagram": "https://www.instagram.com/yashadawellness",
      "job_company_twitter": "https://twitter.com/yashadawellness",
      "job_company_github": "https://github.com/yashadawellness",
      "job_company_youtube": "https://www.youtube.com/@yashadawellness",
      "job_likes": 15,
      "job_views": 70,
      "job_applications": 5,
      "job_shares": 20,
      "job_status": "active"
    }
  ]

  constructor() {
  }

  generateRandomBoolean() {
    return Math.random() < 0.5;
  }

  cloneObject(obj: any) {
    return Object.assign({}, obj);
  }

  fetchNextPage() {

  }

  onScroll() {
    this.fetchNextPage();
  }

  getJobs(): Job[] {
    return this.jobs;
  }

  getTop3TrendingJobs(): Job[] {
    return this.jobs.slice(0, 3);
  }

  // Get job by ID
  getJobById(id: string): Observable<Job | undefined> {
    const job = this.jobs.find((job) => job.job_id === id);
    return of(job);
  }

  // Create a new job
  createJob(newJob: Job): Observable<void> {
    // Assign a unique ID to the new job (assuming you have a job_id property in the Job interface)
    newJob.job_id = this.generateUniqueId();
    this.jobs.push(newJob);
    return of();
  }

  // Update an existing job
  updateJob(updatedJob: Job): Observable<void> {
    const index = this.jobs.findIndex(
      (job) => job.job_id === updatedJob.job_id
    );
    if (index !== -1) {
      this.jobs[index] = updatedJob;
    }
    return of();
  }

  // Delete a job
  deleteJob(jobId: string): Observable<void> {
    const index = this.jobs.findIndex((job) => job.job_id === jobId);
    if (index !== -1) {
      this.jobs.splice(index, 1);
    }
    return of();
  }

  // New method to filter jobs by search query
  filterJobsByQuery(query: string): Observable<Job[]> {
    const filteredJobs = this.jobs.filter((job) => {
      const lowerQuery = query.toLowerCase();
      return (
        job.job_title.toLowerCase().includes(lowerQuery) ||
        job.job_short_description.toLowerCase().includes(lowerQuery) ||
        job.job_keywords.toLowerCase().includes(lowerQuery) ||
        job.job_location.toLowerCase().includes(lowerQuery) ||
        job.job_type.toLowerCase().includes(lowerQuery) ||
        job.job_responsibilities.toLowerCase().includes(lowerQuery) ||
        job.job_requirements.toLowerCase().includes(lowerQuery) ||
        job.job_company_name.toLowerCase().includes(lowerQuery) ||
        job.job_company_description.toLowerCase().includes(lowerQuery)
      );
    });
    return of(filteredJobs);
  }

  // Generate a unique ID (dummy implementation)
  private generateUniqueId(): string {
    return Math.random().toString(36).substr(2, 9);
  }

}
