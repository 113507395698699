<div class="bg-white py-16 sm:py-32 dark:bg-gray-900">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
                Meet Our Leadership
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                Leading with Compassion and Expertise
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                    At Yashada Wellness and Fertility Clinic, our leadership team is dedicated to advancing holistic
                    wellness and fertility care with both passion and expertise. Each member brings a wealth of
                    knowledge in Ayurvedic medicine and modern healthcare practices, guiding our mission to provide
                    personalized and effective care for every patient.
                </p>
            </div>
            <div class="mt-10 flex items-center justify-center gap-x-6">
                <button aria-label="Start Your Journey with Us"
                    class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600"
                    id="book-appointment" aria-haspopup="dialog" aria-expanded="false"
                    aria-controls="hs-scale-animation-modal" data-hs-overlay="#hs-scale-animation-modal">
                    Talk to Our Team
                </button>
            </div>
        </div>
        <ul class="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none"
            role="list">
            <li class="flex flex-col gap-6 xl:flex-row">
                <img alt="Dr. Snehal Sabale" class="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                    src="assets/images/snehal.jpeg" />
                <div class="flex-auto">
                    <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-100">
                        Dr. Snehal Sabale
                    </h3>
                    <p class="text-base leading-7 text-gray-600 dark:text-gray-400">
                        CEO & Founder
                    </p>
                    <p class="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">
                        Dr. Snehal Sabale, a highly skilled Ayurvedic practitioner with a BAMS degree, boasts over
                        16 years of experience in delivering comprehensive Ayurvedic care. As the COO and Founder of
                        Yashada Wellness and Fertility Clinic, she is dedicated to advancing our clinic's
                        operational excellence and enhancing patient outcomes through holistic approaches.
                    </p>
                </div>
            </li>
            <!-- <li class="flex flex-col gap-6 xl:flex-row">
                <img alt="Dr. Mahesh Sabale" class="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                    src="assets/images/mahesh.jpg" />
                <div class="flex-auto">
                    <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-100">
                        Dr. Mahesh Sabale
                    </h3>
                    <p class="text-base leading-7 text-gray-600 dark:text-gray-400">
                        CEO & Founder
                    </p>
                    <p class="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">
                        Dr. Mahesh Sabale, a distinguished Ayurvedic physician with a BAMS degree, brings over 16
                        years of specialized experience in Ayurvedic treatments. As the CEO and Founder of Yashada
                        Wellness and Fertility Center, his profound knowledge and dedication to holistic health
                        drive our mission to deliver personalized and effective care.
                    </p>
                </div>
            </li> -->
            <li class="flex flex-col gap-6 xl:flex-row">
                <img alt="Abhijit Shingate" class="aspect-[4/5] w-52 flex-none rounded-2xl object-cover"
                    src="assets/images/mahesh.jpg" />
                <div class="flex-auto">
                    <h3 class="text-lg font-semibold leading-8 tracking-tight text-gray-900 dark:text-gray-100">
                        Abhijit Shingate
                    </h3>
                    <p class="text-base leading-7 text-gray-600 dark:text-gray-400">
                        CTO
                    </p>
                    <p class="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">
                        Abhijit's deep passion lies in the realm of innovation. Throughout his career, he has
                        consistently achieved remarkable results, committing himself to harnessing data-driven solutions
                        and state-of-the-art AI technologies to transform the drone and robotics industry.
                    </p>
                </div>
            </li>
        </ul>
    </div>
</div>