import { Component } from '@angular/core';

@Component({
  selector: 'app-clinic-contact',
  templateUrl: './clinic-contact.component.html',
  styleUrls: ['./clinic-contact.component.scss']
})
export class ClinicContactComponent {

}
