<div class="bg-white py-24 sm:py-32 dark:bg-gray-900">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="mx-auto max-w-2xl lg:max-w-none">
            <div class="text-center">
                <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
                    Transforming Lives with Proven Results
                </h2>
                <h2 class="text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                    Our Impact at Yashada Wellness
                </h2>
                <p class="mt-4 text-lg leading-8 text-gray-600 dark:text-gray-400">
                    We are committed to delivering exceptional care and results through our holistic approach to
                    wellness and fertility.
                </p>
                <div class="mt-10 flex items-center justify-center gap-x-6">
                    <button aria-label="Start Your Journey with Us"
                        class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600"
                        id="book-appointment" aria-haspopup="dialog" aria-expanded="false"
                        aria-controls="hs-scale-animation-modal" data-hs-overlay="#hs-scale-animation-modal">
                        Become Part Of Yashada Family
                    </button>
                </div>
            </div>
            <dl
                class="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
                <div class="flex flex-col bg-gray-400/5 p-8 dark:bg-gray-800">
                    <dt class="text-sm font-semibold leading-6 text-gray-600 dark:text-gray-400">Patients Treated</dt>
                    <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 dark:text-gray-100">
                        1,500+</dd>
                </div>
                <div class="flex flex-col bg-gray-400/5 p-8 dark:bg-gray-800">
                    <dt class="text-sm font-semibold leading-6 text-gray-600 dark:text-gray-400">Years of Experience
                    </dt>
                    <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 dark:text-gray-100">16+
                    </dd>
                </div>
                <div class="flex flex-col bg-gray-400/5 p-8 dark:bg-gray-800">
                    <dt class="text-sm font-semibold leading-6 text-gray-600 dark:text-gray-400">Success Rate</dt>
                    <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 dark:text-gray-100">92%
                    </dd>
                </div>
                <div class="flex flex-col bg-gray-400/5 p-8 dark:bg-gray-800">
                    <dt class="text-sm font-semibold leading-6 text-gray-600 dark:text-gray-400">Countries Served</dt>
                    <dd class="order-first text-3xl font-semibold tracking-tight text-gray-900 dark:text-gray-100">20+
                    </dd>
                </div>
            </dl>
        </div>
    </div>
</div>