import { APP_INITIALIZER, ApplicationRef, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws/aws-exports.js';
import { HttpLoaderFactory, SharedModule } from './shared/shared.module';
import { DropdownComponent } from './lib/dropdown/dropdown.component';
import { MailChimpService } from './shared/mailchimp.service';
import { LaddaModule } from 'angular2-ladda';
import { TypeaheadComponent } from './lib/typeahead/typeahead.component';
import { ApplyComponent } from './apply/apply.component';
import { ApplyService } from './apply/apply.service';
import { TabComponent } from './lib/tab/tab.component';
import { TabsComponent } from './lib/tabs/tabs.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { JobService } from './shared/job.service';
import { JobOptionsService } from './shared/job-options.service';
import { LoginService } from './login/login.service';
import { LoginComponent } from './login/login.component';
import { PatientDetailsComponent } from './patient/patient-details/patient-details.component';
import { PatientListComponent } from './patient/patient-list/patient-list.component';
import { DoctorHomeComponent } from './doctor/doctor-home/doctor-home.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { PatientHomeComponent } from './patient/patient-home/patient-home.component';
import { StaffHomeComponent } from './staff/staff-home/staff-home.component';
import { StaffListComponent } from './staff/staff-list/staff-list.component';
import { StaffDetailsComponent } from './staff/staff-details/staff-details.component';
import { LayoutHeaderComponent } from './layout/layout-header/layout-header.component';
import { LayoutFooterComponent } from './layout/layout-footer/layout-footer.component';
import { LayoutBodyComponent } from './layout/layout-body/layout-body.component';
import { LayoutScrollComponent } from './layout/layout-scroll/layout-scroll.component';
import { LayoutBannerComponent } from './layout/layout-banner/layout-banner.component';
import { LayoutCookieComponent } from './layout/layout-cookie/layout-cookie.component';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { cookiePreferencesReducer, generalPreferencesReducer, stateInitializerFactory } from './shared/state.store';
import { ClinicAboutComponent } from './clinic/clinic-about/clinic-about.component';
import { ClinicHomeComponent } from './clinic/clinic-home/clinic-home.component';
import { ClinicBlogsComponent } from './clinic/clinic-blogs/clinic-blogs.component';
import { ClinicCasesComponent } from './clinic/clinic-cases/clinic-cases.component';
import { ClinicJobsComponent } from './clinic/clinic-jobs/clinic-jobs.component';
import { ClinicComponent } from './clinic/clinic/clinic.component';
import { DoctorComponent } from './doctor/doctor/doctor.component';
import { PatientComponent } from './patient/patient/patient.component';
import { AdminComponent } from './admin/admin/admin.component';
import { ClinicCaseDetailsComponent } from './clinic/clinic-cases/clinic-case-details/clinic-case-details.component';
import { ClinicBlogDetailsComponent } from './clinic/clinic-blogs/clinic-blog-details/clinic-blog-details.component';
import { ClinicJobDetailsComponent } from './clinic/clinic-jobs/clinic-job-details/clinic-job-details.component';
import { StaffComponent } from './staff/staff/staff.component';
import { LegalComponent } from './legal/legal/legal.component';
import { LegalHomeComponent } from './legal/legal-home/legal-home.component';
import { LegalCookiesComponent } from './legal/legal-cookies/legal-cookies.component';
import { LegalTermsComponent } from './legal/legal-terms/legal-terms.component';
import { LegalPrivacyComponent } from './legal/legal-privacy/legal-privacy.component';
import { LegalCreditsComponent } from './legal/legal-credits/legal-credits.component';
import { PatientAccountComponent } from './patient/patient-account/patient-account.component';
import { DoctorAccountComponent } from './doctor/doctor-account/doctor-account.component';
import { StaffAccountComponent } from './staff/staff-account/staff-account.component';
import { PatientCalenderComponent } from './patient/patient-calender/patient-calender.component';
import { StaffCalenderComponent } from './staff/staff-calender/staff-calender.component';
import { DoctorCalenderComponent } from './doctor/doctor-calender/doctor-calender.component';
import { ClinicHomeHeroComponent } from './clinic/clinic-home/clinic-home-hero/clinic-home-hero.component';
import { ClinicHomeServicesComponent } from './clinic/clinic-home/clinic-home-services/clinic-home-services.component';
import { ClinicHomeCasesComponent } from './clinic/clinic-home/clinic-home-cases/clinic-home-cases.component';
import { ClinicHomeScheduleComponent } from './clinic/clinic-home/clinic-home-schedule/clinic-home-schedule.component';
import { ClinicHomeBlogsComponent } from './clinic/clinic-home/clinic-home-blogs/clinic-home-blogs.component';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { AppMissingTranslationHandler } from './shared/translation.handler';
import FindFlagFromLanguageKeyPipe from './shared/find-flag-from-language-key.pipe';
import FindLanguageFromKeyPipe from './shared/find-language-from-key.pipe';
import { ClinicHomeCtaComponent } from './clinic/clinic-home/clinic-home-cta/clinic-home-cta.component';
import { ClinicHomeStatsComponent } from './clinic/clinic-home/clinic-home-stats/clinic-home-stats.component';
import { ClinicHomePlansComponent } from './clinic/clinic-home/clinic-home-plans/clinic-home-plans.component';
import { ClinicHomeTeamComponent } from './clinic/clinic-home/clinic-home-team/clinic-home-team.component';
import { ClinicHomeFaqComponent } from './clinic/clinic-home/clinic-home-faq/clinic-home-faq.component';
import { VideoJsPlayerComponent } from './shared/vjs.component';
import { SpecialtyComponent } from './specialty/specialty/specialty.component';
import { SpecialtyFertilityComponent } from './specialty/specialty-fertility/specialty-fertility.component';
import { SpecialtyPanchakarmaComponent } from './specialty/specialty-panchakarma/specialty-panchakarma.component';
import { LegalRefundComponent } from './legal/legal-refund/legal-refund.component';
import { ClinicContactComponent } from './clinic/clinic-contact/clinic-contact.component';
import { SpecialtyWellnessComponent } from './specialty/specialty-wellness/specialty-wellness.component';
import { ClinicServicesComponent } from './clinic/clinic-services/clinic-services.component';
import { ClinicFacilitiesComponent } from './clinic/clinic-facilities/clinic-facilities.component';
import { ClinicPackagesComponent } from './clinic/clinic-packages/clinic-packages.component';
import { ClinicFaqsComponent } from './clinic/clinic-faqs/clinic-faqs.component';
import { ClinicTeamComponent } from './clinic/clinic-team/clinic-team.component';
import { DoctorListComponent } from './doctor/doctor-list/doctor-list.component';
import { DoctorDetailsComponent } from './doctor/doctor-details/doctor-details.component';
import { ClinicHomeFacilitiesComponent } from './clinic/clinic-home/clinic-home-facilities/clinic-home-facilities.component';
import { ClinicEducationComponent } from './clinic/clinic-education/clinic-education.component';

Amplify.configure(awsconfig);

@NgModule({
  declarations: [
    FindFlagFromLanguageKeyPipe,
    FindLanguageFromKeyPipe,
    AppComponent,
    DropdownComponent,
    TypeaheadComponent,
    TypeaheadComponent,
    ApplyComponent,
    TabComponent,
    TabsComponent,
    LoginComponent,
    PatientDetailsComponent,
    PatientListComponent,
    DoctorHomeComponent,
    AdminHomeComponent,
    PatientHomeComponent,
    StaffHomeComponent,
    StaffListComponent,
    StaffDetailsComponent,
    LayoutHeaderComponent,
    LayoutFooterComponent,
    LayoutBodyComponent,
    LayoutScrollComponent,
    LayoutBannerComponent,
    LayoutCookieComponent,
    ClinicAboutComponent,
    ClinicHomeComponent,
    ClinicBlogsComponent,
    ClinicCasesComponent,
    ClinicJobsComponent,
    ClinicComponent,
    DoctorComponent,
    PatientComponent,
    AdminComponent,
    ClinicCaseDetailsComponent,
    ClinicBlogDetailsComponent,
    ClinicJobDetailsComponent,
    StaffComponent,
    LegalComponent,
    LegalHomeComponent,
    LegalCookiesComponent,
    LegalTermsComponent,
    LegalPrivacyComponent,
    LegalCreditsComponent,
    PatientAccountComponent,
    DoctorAccountComponent,
    StaffAccountComponent,
    PatientCalenderComponent,
    StaffCalenderComponent,
    DoctorCalenderComponent,
    ClinicHomeHeroComponent,
    ClinicHomeServicesComponent,
    ClinicHomeCasesComponent,
    ClinicHomeScheduleComponent,
    ClinicHomeBlogsComponent,
    ClinicHomeCtaComponent,
    ClinicHomeStatsComponent,
    ClinicHomePlansComponent,
    ClinicHomeTeamComponent,
    ClinicHomeFaqComponent,
    VideoJsPlayerComponent,
    SpecialtyComponent,
    SpecialtyFertilityComponent,
    SpecialtyPanchakarmaComponent,
    LegalRefundComponent,
    ClinicContactComponent,
    SpecialtyWellnessComponent,
    ClinicServicesComponent,
    ClinicFacilitiesComponent,
    ClinicPackagesComponent,
    ClinicFaqsComponent,
    ClinicTeamComponent,
    DoctorListComponent,
    DoctorDetailsComponent,
    ClinicHomeFacilitiesComponent,
    ClinicEducationComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({
      generalPreferences: generalPreferencesReducer,
      cookiePreferences: cookiePreferencesReducer,
    }),
    SharedModule,
    AppRoutingModule,
    AmplifyAuthenticatorModule,
    LaddaModule,
    InfiniteScrollModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: AppMissingTranslationHandler },
      defaultLanguage: 'en'
    }),

  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: stateInitializerFactory,
      deps: [Store, ApplicationRef],
      multi: true
    },
    MailChimpService,
    ApplyService,
    JobService,
    JobOptionsService,
    LoginService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {

}
