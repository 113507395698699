import { Component } from '@angular/core';

@Component({
  selector: 'app-clinic-faqs',
  templateUrl: './clinic-faqs.component.html',
  styleUrls: ['./clinic-faqs.component.scss']
})
export class ClinicFaqsComponent {

}
