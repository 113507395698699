<div class="bg-white dark:bg-gray-900">
    <!-- Pricing -->
    <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <!-- Title -->
        <div class="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
            <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
                Discover Our Packages
            </h2>
            <h2 class="text-2xl font-bold md:text-4xl md:leading-tight text-gray-900 dark:text-gray-100">Fertility
                Treatment
                Packages</h2>
            <p class="mt-1 text-gray-600 dark:text-gray-400">Our tailored packages adapt to your unique needs, ensuring
                comprehensive support at every stage.</p>
            <div class="mt-10 flex items-center justify-center gap-x-6">
                <button aria-label="Start Your Journey with Us"
                    class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-700 dark:hover:bg-indigo-800 dark:focus:ring-indigo-400"
                    id="book-appointment" aria-haspopup="dialog" aria-expanded="false"
                    aria-controls="hs-scale-animation-modal" data-hs-overlay="#hs-scale-animation-modal">
                    Book Appointment To Customize Package
                </button>
            </div>
        </div>
        <!-- End Title -->

        <!-- Switch -->
        <div class="flex justify-center items-center">
            <label class="min-w-14 text-sm text-gray-500 dark:text-gray-400 me-3">Monthly</label>

            <input type="checkbox" id="hs-basic-with-description"
                class="relative w-[3.25rem] h-7 p-px bg-gray-100 border-transparent text-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:ring-indigo-600 disabled:opacity-50 disabled:pointer-events-none checked:bg-none checked:text-indigo-600 checked:border-indigo-600 focus:checked:border-indigo-600 dark:bg-gray-800 dark:border-gray-700 dark:checked:bg-indigo-500 dark:checked:border-indigo-500 dark:focus:ring-offset-gray-600
            before:inline-block before:size-6 before:bg-white checked:before:bg-white before:translate-x-0 checked:before:translate-x-full before:rounded-full before:shadow before:transform before:ring-0 before:transition before:ease-in-out before:duration-200 dark:before:bg-gray-400 dark:checked:before:bg-white"
                checked>

            <label class="relative min-w-14 text-sm text-gray-500 dark:text-gray-400 ms-3">
                Annual
                <span class="absolute -top-10 start-auto -end-28">
                    <span class="flex items-center">
                        <svg class="w-14 h-8 -me-6" width="45" height="25" viewBox="0 0 45 25" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M43.2951 3.47877C43.8357 3.59191 44.3656 3.24541 44.4788 2.70484C44.5919 2.16427 44.2454 1.63433 43.7049 1.52119L43.2951 3.47877ZM4.63031 24.4936C4.90293 24.9739 5.51329 25.1423 5.99361 24.8697L13.8208 20.4272C14.3011 20.1546 14.4695 19.5443 14.1969 19.0639C13.9242 18.5836 13.3139 18.4152 12.8336 18.6879L5.87608 22.6367L1.92723 15.6792C1.65462 15.1989 1.04426 15.0305 0.563943 15.3031C0.0836291 15.5757 -0.0847477 16.1861 0.187863 16.6664L4.63031 24.4936ZM43.7049 1.52119C32.7389 -0.77401 23.9595 0.99522 17.3905 5.28788C10.8356 9.57127 6.58742 16.2977 4.53601 23.7341L6.46399 24.2659C8.41258 17.2023 12.4144 10.9287 18.4845 6.96211C24.5405 3.00476 32.7611 1.27399 43.2951 3.47877L43.7049 1.52119Z"
                                fill="currentColor" class="fill-gray-300 dark:fill-gray-700" />
                        </svg>
                        <span
                            class="mt-3 inline-block whitespace-nowrap text-[11px] leading-5 font-semibold tracking-wide uppercase bg-indigo-600 text-white rounded-full py-1 px-2.5 dark:bg-indigo-700 dark:text-gray-100">Save
                            up to 10%</span>
                    </span>
                </span>
            </label>
        </div>
        <!-- End Switch -->

        <!-- Grid -->
        <div class="mt-12 grid sm:grid-cols-2 lg:grid-cols-4 gap-6 lg:items-center">
            <!-- Card -->
            <div class="flex flex-col border border-gray-200 text-center rounded-xl p-8 dark:border-gray-800">
                <h4 class="font-medium text-lg text-gray-800 dark:text-gray-200">Basic</h4>
                <span class="mt-7 font-bold text-5xl text-gray-800 dark:text-gray-200">₹500</span>
                <p class="mt-2 text-sm text-gray-500 dark:text-gray-500">Single session package</p>

                <ul class="mt-7 space-y-2.5 text-sm">
                    <li class="flex gap-x-2">
                        <svg class="shrink-0 mt-0.5 size-4 text-indigo-600 dark:text-indigo-500"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                        <span class="text-gray-800 dark:text-gray-400">
                            1 consultation
                        </span>
                    </li>

                    <li class="flex gap-x-2">
                        <svg class="shrink-0 mt-0.5 size-4 text-indigo-600 dark:text-indigo-500"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                        <span class="text-gray-800 dark:text-gray-400">
                            Basic treatment plan
                        </span>
                    </li>

                    <li class="flex gap-x-2">
                        <svg class="shrink-0 mt-0.5 size-4 text-indigo-600 dark:text-indigo-500"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                        <span class="text-gray-800 dark:text-gray-400">
                            Standard support
                        </span>
                    </li>
                </ul>

                <a class="mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:focus:bg-gray-800"
                    href="#">
                    Book Now
                </a>
            </div>
            <!-- End Card -->

            <!-- Card -->
            <div
                class="flex flex-col border-2 border-indigo-600 text-center shadow-xl rounded-xl p-8 dark:border-indigo-700">
                <p class="mb-3"><span
                        class="inline-flex items-center gap-1.5 px-3 py-1.5 text-sm font-semibold leading-6 text-indigo-600 ring-1 ring-indigo-600/10 rounded-full dark:ring-indigo-700/10 dark:text-indigo-400 dark:ring-indigo-500/20">
                        <svg class="w-4 h-4" width="16" height="16" viewBox="0 0 16 16" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8 0.75a7.25 7.25 0 1 0 0 14.5A7.25 7.25 0 0 0 8 0.75zm0 13.5a6.25 6.25 0 1 1 0-12.5 6.25 6.25 0 0 1 0 12.5zm-1-8a.75.75 0 0 1 1.5 0v2.25a.75.75 0 0 1-1.5 0V6.25zm0 3a.75.75 0 1 1 1.5 0 .75.75 0 0 1-1.5 0z"
                                fill="currentColor" />
                        </svg>
                        <span class="font-medium">Recommended</span>
                    </span></p>
                <h4 class="font-medium text-lg text-gray-800 dark:text-gray-200">Standard</h4>
                <span class="mt-7 font-bold text-5xl text-gray-800 dark:text-gray-200">₹2000</span>
                <p class="mt-2 text-sm text-gray-500 dark:text-gray-500">Complete session package</p>

                <ul class="mt-7 space-y-2.5 text-sm">
                    <li class="flex gap-x-2">
                        <svg class="shrink-0 mt-0.5 size-4 text-indigo-600 dark:text-indigo-500"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                        <span class="text-gray-800 dark:text-gray-400">
                            3 consultations
                        </span>
                    </li>

                    <li class="flex gap-x-2">
                        <svg class="shrink-0 mt-0.5 size-4 text-indigo-600 dark:text-indigo-500"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                        <span class="text-gray-800 dark:text-gray-400">
                            Comprehensive treatment plan
                        </span>
                    </li>

                    <li class="flex gap-x-2">
                        <svg class="shrink-0 mt-0.5 size-4 text-indigo-600 dark:text-indigo-500"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                        <span class="text-gray-800 dark:text-gray-400">
                            Priority support
                        </span>
                    </li>
                </ul>

                <a class="mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:focus:bg-gray-800"
                    href="#">
                    Book Now
                </a>
            </div>
            <!-- End Card -->

            <!-- Card -->
            <div class="flex flex-col border border-gray-200 text-center rounded-xl p-8 dark:border-gray-800">
                <h4 class="font-medium text-lg text-gray-800 dark:text-gray-200">Premium</h4>
                <span class="mt-7 font-bold text-5xl text-gray-800 dark:text-gray-200">₹3500</span>
                <p class="mt-2 text-sm text-gray-500 dark:text-gray-500">Extended session package</p>

                <ul class="mt-7 space-y-2.5 text-sm">
                    <li class="flex gap-x-2">
                        <svg class="shrink-0 mt-0.5 size-4 text-indigo-600 dark:text-indigo-500"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                        <span class="text-gray-800 dark:text-gray-400">
                            5 consultations
                        </span>
                    </li>

                    <li class="flex gap-x-2">
                        <svg class="shrink-0 mt-0.5 size-4 text-indigo-600 dark:text-indigo-500"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                        <span class="text-gray-800 dark:text-gray-400">
                            All-inclusive treatment plan
                        </span>
                    </li>

                    <li class="flex gap-x-2">
                        <svg class="shrink-0 mt-0.5 size-4 text-indigo-600 dark:text-indigo-500"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                        <span class="text-gray-800 dark:text-gray-400">
                            VIP support
                        </span>
                    </li>
                </ul>

                <a class="mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:focus:bg-gray-800"
                    href="#">
                    Book Now
                </a>
            </div>
            <!-- End Card -->

            <!-- Card -->
            <div class="flex flex-col border border-gray-200 text-center rounded-xl p-8 dark:border-gray-800">
                <h4 class="font-medium text-lg text-gray-800 dark:text-gray-200">Ultimate</h4>
                <span class="mt-7 font-bold text-5xl text-gray-800 dark:text-gray-200">₹5000</span>
                <p class="mt-2 text-sm text-gray-500 dark:text-gray-500">Exclusive session package</p>

                <ul class="mt-7 space-y-2.5 text-sm">
                    <li class="flex gap-x-2">
                        <svg class="shrink-0 mt-0.5 size-4 text-indigo-600 dark:text-indigo-500"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                        <span class="text-gray-800 dark:text-gray-400">
                            10 consultations
                        </span>
                    </li>

                    <li class="flex gap-x-2">
                        <svg class="shrink-0 mt-0.5 size-4 text-indigo-600 dark:text-indigo-500"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                        <span class="text-gray-800 dark:text-gray-400">
                            Personalized treatment plan
                        </span>
                    </li>

                    <li class="flex gap-x-2">
                        <svg class="shrink-0 mt-0.5 size-4 text-indigo-600 dark:text-indigo-500"
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                            <polyline points="20 6 9 17 4 12" />
                        </svg>
                        <span class="text-gray-800 dark:text-gray-400">
                            24/7 support
                        </span>
                    </li>
                </ul>

                <a class="mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:focus:bg-gray-800"
                    href="#">
                    Book Now
                </a>
            </div>
            <!-- End Card -->
        </div>
    </div>
</div>