<!-- Facilities and Equipment Section -->
<div class="py-16 px-6 lg:px-8 bg-white dark:bg-gray-900">
    <div class="mx-auto max-w-7xl">
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
                Our Facilities and Equipment
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                Comprehensive Care with State-of-the-Art Resources
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                    At Yashada Wellness and Fertility Clinic, we are dedicated to providing exceptional care through our
                    state-of-the-art facilities and equipment. Our team of skilled professionals utilizes the latest
                    advancements in Ayurvedic medicine and technology to offer personalized and effective treatments.
                </p>
            </div>
            <div class="mt-10 flex items-center justify-center gap-x-6">
                <button aria-label="Start Your Journey with Us"
                    class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600 dark:focus:ring-indigo-400"
                    id="book-appointment" aria-haspopup="dialog" aria-expanded="false"
                    aria-controls="hs-scale-animation-modal" data-hs-overlay="#hs-scale-animation-modal">
                    Know More About Our Services &amp; Facilities
                </button>
            </div>
        </div>
        <dl
            class="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Advanced Diagnostic Tools
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Utilizing cutting-edge diagnostic equipment to accurately assess and monitor your health, ensuring
                    precise and effective treatment plans.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Ayurvedic Treatment Rooms
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Offering serene and well-equipped treatment rooms designed to enhance the therapeutic experience and
                    support holistic healing.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Fertility Enhancement Facilities
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Providing specialized facilities for fertility treatments, including modern diagnostic and
                    therapeutic equipment tailored to support your journey.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Herbal Medicine Dispensary
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    A well-stocked dispensary offering a wide range of authentic Ayurvedic herbs and formulations to
                    complement your treatment regimen.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Experienced Ayurvedic Practitioners
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Our team includes highly trained practitioners with extensive experience in Ayurvedic medicine,
                    dedicated to providing personalized and effective care.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Wellness and Relaxation Areas
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Designed spaces for relaxation and wellness, including meditation and yoga areas to support your
                    overall well-being and enhance the healing process.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Personalized Patient Care
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Our dedicated staff offers personalized care plans and ongoing support, ensuring that each patient's
                    needs and preferences are met with compassion and expertise.
                </dd>
            </div>
            <div>
                <dt class="font-semibold text-gray-900 dark:text-gray-100">
                    Cutting-Edge Treatment Technologies
                </dt>
                <dd class="mt-1 text-gray-600 dark:text-gray-400">
                    Incorporating the latest technologies and advancements in both Ayurvedic and modern medicine to
                    enhance the effectiveness and safety of our treatments.
                </dd>
            </div>
        </dl>
    </div>
</div>