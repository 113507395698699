import { Component, OnInit, ViewChild } from '@angular/core';
import { VideoJsPlayerComponent } from 'src/app/shared/vjs.component';
import videojs from 'video.js';

@Component({
  selector: 'app-clinic-home-hero',
  templateUrl: './clinic-home-hero.component.html',
  styleUrls: ['./clinic-home-hero.component.scss']
})
export class ClinicHomeHeroComponent implements OnInit {

  public video!: HTMLVideoElement;
  public player: any;

  @ViewChild(VideoJsPlayerComponent) vjsPlayer!: VideoJsPlayerComponent;

  ngOnInit() {

  }

  onCloseModal() {
    if (this.vjsPlayer) {
      this.vjsPlayer.destroyPlayer();
    }
  }
}
