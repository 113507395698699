import { Component, Input, OnInit } from '@angular/core';
import { ApplyService } from './apply.service';
import { Job } from 'src/app/shared/job.model';
import { Amplify, Auth } from 'aws-amplify';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JobService } from 'src/app/shared/job.service';
import { MailChimpService, MailChimpResponse } from '../shared/mailchimp.service';

@Component({
  selector: 'apply',
  templateUrl: './apply.component.html',
})
export class ApplyComponent implements OnInit {
  form!: FormGroup;
  @Input() show = false;
  @Input() job!: Job;

  userName!: string;
  userEmail!: string;

  selectedFile!: File;
  fileSizeExceeded = false;

  message: string | null = null;
  messageType: 'success' | 'error' | null = null;
  isSubmitting = false;

  constructor(
    private applyService: ApplyService,
    private fb: FormBuilder,
    private jobService: JobService,
    private mailChimpService: MailChimpService
  ) {
    this.loadCurrentUserAttributes();
  }

  ngOnInit() {
    this.form = this.fb.group({
      fullName: ['', Validators.required],
      resumeFile: [null, Validators.required],
    });
  }

  handleFileChange(event: any) {
    const file: File = event.target.files[0];
    if (file.size <= 5 * 1024 * 1024) {
      this.selectedFile = file;
      this.fileSizeExceeded = false;
    } else {
      this.clearFile();
      this.fileSizeExceeded = true;
    }
  }

  clearFile() {
    this.selectedFile = null!;
    this.fileSizeExceeded = false;
    const fileInput: HTMLInputElement | null = document.querySelector('#file-input');
    if (fileInput) {
      fileInput.value = ''; // This is allowed to clear the file input
    }
  }

  close() {
    this.applyService.hide();
  }

  async submitForm() {
    if (this.form.valid) {
      this.isSubmitting = true;
      try {
        const fullName = this.form.get('fullName')?.value;
        const response: MailChimpResponse = await this.mailChimpService.subscribe(fullName, this.userEmail, this.job);
        if (response.result === 'success') {
          this.message = 'Application submitted successfully!';
          this.messageType = 'success';
          this.isSubmitting = false;
        } else {
          this.message = `Failed to submit application: ${response.msg}`;
          this.messageType = 'error';
        }
      } catch (error:any) {
        this.message = `Failed to submit application: ${error.message}`;
        this.messageType = 'error';
      }
    } else {
      // Mark fields as touched to trigger validation messages
      this.form.markAllAsTouched();
    }
  }

  async loadCurrentUserAttributes() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const { attributes } = user;
      const { name, email } = attributes;
      this.userEmail = email;
      this.userName = name;
    } catch (error) {
      console.error('Error retrieving user information:', error);
    }
  }
}
