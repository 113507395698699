import { Component } from '@angular/core';

@Component({
  selector: 'app-clinic-cases',
  templateUrl: './clinic-cases.component.html',
  styleUrls: ['./clinic-cases.component.scss']
})
export class ClinicCasesComponent {

}
