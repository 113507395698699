import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CookiePreferences, saveCookiePreferences, selectCookiePreferences } from 'src/app/shared/state.store'; // Adjust path if needed

@Component({
  selector: 'app-layout-cookie',
  templateUrl: './layout-cookie.component.html',
  styleUrls: ['./layout-cookie.component.scss']
})
export class LayoutCookieComponent implements OnInit {

  cookiePreferences$: Observable<CookiePreferences>;
  showPopup: boolean = false;
  preferences: CookiePreferences = {
    necessary: true,
    preferences: false,
    statistics: false,
    marketing: false,
  };

  constructor(private store: Store<any>) { // Adjust type based on your AppState
    this.cookiePreferences$ = this.store.pipe(select(selectCookiePreferences));
  }

  ngOnInit() {
    // Subscribe to the observable to get existing preferences
    this.cookiePreferences$.subscribe(prefs => {
      if (prefs) {
        this.preferences = { ...prefs };
        this.applyCookiePreferences(prefs);
      } else {
        this.showPopup = true;
      }
    });
  }

  private applyCookiePreferences(preferences: CookiePreferences): void {
    if (preferences.necessary) {
      // Ensure necessary cookies are enabled
    }
    if (preferences.preferences) {
      // Enable cookies for preferences
    }
    if (preferences.statistics) {
      // Enable cookies for statistics
    }
    if (preferences.marketing) {
      // Enable cookies for marketing
    }
  }

  updateCookiePreferences(preferences: CookiePreferences): void {
    this.store.dispatch(saveCookiePreferences({ preferences }));
  }

  allowAll() {
    this.preferences = {
      necessary: true,
      preferences: true,
      statistics: true,
      marketing: true,
    };
    this.savePreferences();
  }

  rejectAll() {
    this.preferences = {
      necessary: true,
      preferences: false,
      statistics: false,
      marketing: false,
    };
    this.savePreferences();
  }

  savePreferences() {
    this.store.dispatch(saveCookiePreferences({ preferences: this.preferences }));
    this.showPopup = false;
  }
}
