import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { ApplyComponent } from './apply.component';
import { Job } from 'src/app/shared/job.model';

@Injectable({
  providedIn: 'root',
})
export class ApplyService {
  componentRef!: ComponentRef<ApplyComponent>;
  private isShowing = false;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  show(job:Job) {
    if (!this.isShowing) {
      this.isShowing = true;
      this.componentRef = this.componentFactoryResolver
        .resolveComponentFactory(ApplyComponent)
        .create(this.injector);
      this.componentRef.instance.show = true;
      this.componentRef.instance.job = job;
      this.appRef.attachView(this.componentRef.hostView);
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
    }
  }

  hide() {
    if (this.isShowing) {
      setTimeout(() => {
        this.appRef.detachView(this.componentRef.hostView);
        this.componentRef.instance.show = true;
        this.componentRef.destroy();
        this.isShowing = false;
      }, 500);
    }
  }
}
