<div *ngIf="show"
  class="h-screen w-screen fixed inset-0 flex items-center justify-center z-10 bg-gray-100 dark:bg-gray-900">
  <div class="login-window">
    <button (click)="close()" class="absolute top-1 right-6 mt-4 mr-4 text-black dark:text-white focus:outline-none">
      <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
      </svg>
    </button>
    <amplify-authenticator [socialProviders]="['facebook', 'google']">
      <div *amplifySlot="'header'">
        <div class="sm:mx-auto sm:w-full sm:max-w-md pb-5">
          <img class="mx-auto h-20 w-auto" src="assets/images/logo.png" alt="Yashada Logo">
          <!-- {{ edit_1 }} -->
          <h2 class="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900 dark:text-gray-100">
            Yashada Clinic</h2>
          <!-- {{ edit_2 }} -->
          <p class="mt-2 text-center text-base text-gray-600 dark:text-gray-300">Your health, our priority.</p>
        </div>
      </div>

      <div *amplifySlot="'footer'">
        <div class="flex items-center justify-center z-10 bg-white dark:bg-gray-800 px-10 pb-5">
          <div class="login-window">
            <div class="text-center">
              <p class="text-sm text-gray-600 dark:text-gray-400">
                By signing in and creating an account, you agree to our
                <a href="/terms" class="text-blue-500 underline">terms</a> and
                <a href="/privacy" class="text-blue-500 underline">privacy policy</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </amplify-authenticator>
  </div>
</div>