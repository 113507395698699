<!-- Panchakarma section -->
<div class="relative isolate py-16">
    <div aria-hidden="true" class="absolute inset-x-0 top-0 -z-10 transform-gpu overflow-hidden opacity-20 blur-3xl">
        <div class="w-[82.0625rem] h-[30rem]" style="clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%)">
        </div>
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600">
                Panchakarma Therapy
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 ">
                Detoxify, Rejuvenate, and Revitalize with Panchakarma
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600">
                    Panchakarma, the ancient Ayurvedic detoxification therapy, is at the heart of our wellness
                    practices.
                    At Yashada Wellness and Fertility Center, we offer specialized Panchakarma treatments that cleanse
                    the body, balance the doshas, and promote deep healing and rejuvenation.
                </p>
            </div>
        </div>
        <div
            class="mt-16 grid max-w-2xl grid-cols-1 gap-x-16 gap-y-8 text-center sm:mx-auto sm:max-w-none sm:grid-cols-2">
            <div class="rounded-2xl bg-white p-8">
                <div class="flex justify-center mb-6">
                    <div class="relative w-48 h-48">
                        <img alt="Detoxification" class="w-full h-full rounded-full bg-gray-100 object-cover"
                            src="https://assets.exadatum.com/assets/images/other/detoxification.webp" />
                        <div class="absolute inset-0 rounded-full ring-1 ring-inset ring-gray-900/10">
                        </div>
                    </div>
                </div>
                <h3 class="text-xl font-semibold leading-8 tracking-tight text-gray-900 text-center">
                    Detoxification
                </h3>
                <p class="mt-4 text-base leading-7 text-gray-600 text-center">
                    Panchakarma is a powerful detoxification process that eliminates toxins from the body, restoring
                    balance and vitality. Our treatments are tailored to your unique constitution and health needs,
                    ensuring effective and gentle detoxification.
                </p>
            </div>
            <div class="rounded-2xl bg-white p-8">
                <div class="flex justify-center mb-6">
                    <div class="relative w-48 h-48">
                        <img alt="Rejuvenation" class="w-full h-full rounded-full bg-gray-100 object-cover"
                            src="https://assets.exadatum.com/assets/images/other/rejuvenation.webp" />
                        <div class="absolute inset-0 rounded-full ring-1 ring-inset ring-gray-900/10">
                        </div>
                    </div>
                </div>
                <h3 class="text-xl font-semibold leading-8 tracking-tight text-gray-900 text-center">
                    Rejuvenation
                </h3>
                <p class="mt-4 text-base leading-7 text-gray-600 text-center">
                    Beyond detoxification, Panchakarma also focuses on rejuvenating the body and mind. Our therapies
                    promote deep relaxation, improve energy levels, and support overall health and well-being.
                </p>
            </div>
        </div>
    </div>
</div>