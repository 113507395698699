<!-- Wellness Program section -->
<div class="relative isolate py-16">
    <div aria-hidden="true" class="absolute inset-x-0 top-0 -z-10 transform-gpu overflow-hidden opacity-20 blur-3xl">
        <div class="w-[82.0625rem] h-[30rem]" style="clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%)">
        </div>
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600">
                Wellness Program
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 ">
                Rejuvenate Your Body and Mind with Our Wellness Programs
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600">
                    Our wellness programs at Yashada Wellness and Fertility Center are designed to restore balance
                    and promote overall well-being. Combining traditional Ayurvedic practices with modern insights, we
                    offer personalized programs that cater to your specific health needs and lifestyle.
                </p>
            </div>
        </div>
        <div
            class="mt-16 grid max-w-2xl grid-cols-1 gap-x-16 gap-y-8 text-center sm:mx-auto sm:max-w-none sm:grid-cols-2">
            <div class="rounded-2xl bg-white p-8">
                <div class="flex justify-center mb-6">
                    <div class="relative w-48 h-48">
                        <img alt="Personalized Care" class="w-full h-full rounded-full bg-gray-100 object-cover"
                            src="https://assets.exadatum.com/assets/images/other/personalized_care.webp" />
                        <div class="absolute inset-0 rounded-full ring-1 ring-inset ring-gray-900/10">
                        </div>
                    </div>
                </div>
                <h3 class="text-xl font-semibold leading-8 tracking-tight text-gray-900 text-center">
                    Personalized Care
                </h3>
                <p class="mt-4 text-base leading-7 text-gray-600 text-center">
                    Our wellness programs are tailored to your unique needs, focusing on physical, mental, and
                    emotional well-being. We use a holistic approach to address stress, detoxification, and lifestyle
                    modifications to achieve optimal health.
                </p>
            </div>
            <div class="rounded-2xl bg-white p-8">
                <div class="flex justify-center mb-6">
                    <div class="relative w-48 h-48">
                        <img alt="Holistic Healing" class="w-full h-full rounded-full bg-gray-100 object-cover"
                            src="https://assets.exadatum.com/assets/images/other/holistic_healing.webp" />
                        <div class="absolute inset-0 rounded-full ring-1 ring-inset ring-gray-900/10">
                        </div>
                    </div>
                </div>
                <h3 class="text-xl font-semibold leading-8 tracking-tight text-gray-900 text-center">
                    Holistic Healing
                </h3>
                <p class="mt-4 text-base leading-7 text-gray-600 text-center">
                    Our holistic healing practices include Ayurvedic therapies, meditation, yoga, and diet plans that
                    work together to rejuvenate your body and mind. We aim to empower you with tools and knowledge
                    for long-term wellness and balance.
                </p>
            </div>
        </div>
    </div>
</div>