<!-- Privacy Policy Section -->
<div class="py-16 px-6 lg:px-8 bg-white dark:bg-gray-900">
    <div class="mx-auto max-w-7xl">
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-300">
                Privacy Policy
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                Yashada Wellness & Fertility Clinic Privacy Policy
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                    Effective Date: 23 February 2023<br />
                    Last Updated: 23 February 2023
                </p>
            </div>
        </div>
        <div class="mt-10">
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Introduction</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                Thank you for visiting our website. This Privacy Policy explains how we collect, use, and protect your
                personal information when you access or use our website. By accessing or using our website, you consent
                to the terms of this Privacy Policy.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Information We Collect</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">When you access or use our website, we may collect certain
                information from you, including:</p>
            <ul class="list-disc ml-8 mb-4 text-gray-600 dark:text-gray-400">
                <li class="mb-2">Personal information such as your name, email address, and contact details, which you
                    provide voluntarily when submitting forms or contacting us.</li>
                <li class="mb-2">Usage information, including your IP address, browser type, operating system, and
                    referring URLs, collected automatically as you navigate through our website.</li>
                <li class="mb-2">Cookies and similar tracking technologies to enhance your browsing experience and
                    collect information about how you interact with our website.</li>
            </ul>
            <p class="mb-4 text-gray-600 dark:text-gray-400">We use the collected information for the following
                purposes:</p>
            <ul class="list-disc ml-8 mb-4 text-gray-600 dark:text-gray-400">
                <li class="mb-2">To provide and maintain our website, including improving its functionality and user
                    experience.</li>
                <li class="mb-2">To respond to your inquiries, requests, or feedback.</li>
                <li class="mb-2">To personalize your experience on our website and deliver targeted content and
                    advertisements.</li>
                <li class="mb-2">To analyze website usage and trends, and for statistical purposes.</li>
                <li class="mb-2">To enforce our website's terms of use and protect against potential fraud or
                    unauthorized access.</li>
            </ul>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Third-Party Services and Links</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                Our website may contain links to third-party websites or services that are not owned or controlled by
                us. We are not responsible for the privacy practices or content of those third-party websites or
                services. We recommend reviewing their respective privacy policies to understand how your information is
                collected, used, and shared.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Data Security</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                We implement reasonable security measures to protect your personal information from unauthorized access,
                alteration, disclosure, or destruction. However, please be aware that no method of transmission over the
                internet or electronic storage is completely secure, and we cannot guarantee absolute security of your
                information.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Your Rights</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                You have the right to access, update, or delete your personal information that we hold. If you would
                like to exercise these rights, please contact us using the information provided below.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Updates to the Privacy Policy</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                We reserve the right to modify or update this Privacy Policy at any time without prior notice. The
                updated policy will be effective immediately upon posting on our website. We encourage you to review
                this policy periodically to stay informed about how we handle your personal information.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Contact Us</h2>
            <p class="text-gray-600 dark:text-gray-400">
                If you have any questions or concerns about our Privacy Policy, please contact us at
                <a href="mailto:legal&#64;yashada.clinic"
                    class="text-indigo-500 dark:text-indigo-400 underline">legal&#64;yashada.clinic</a>.
            </p>
        </div>
    </div>
</div>