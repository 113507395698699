import { Component } from '@angular/core';

@Component({
  selector: 'app-clinic-home-services',
  templateUrl: './clinic-home-services.component.html',
  styleUrls: ['./clinic-home-services.component.scss']
})
export class ClinicHomeServicesComponent {

}
