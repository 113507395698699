import { Component } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Auth } from 'aws-amplify';
import { LoginService } from 'src/app/login/login.service';
import { JobService } from 'src/app/shared/job.service';
import { Observable } from 'rxjs';
import { GeneralPreferences, updateLanguage, updateTheme } from './shared/state.store';
import { Store, select } from '@ngrx/store';
import { selectGeneralPreferences } from './shared/state.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

}
