<!-- Ayurvedic Fertility Treatment section -->
<div class="relative isolate py-16">
    <div aria-hidden="true" class="absolute inset-x-0 top-0 -z-10 transform-gpu overflow-hidden opacity-20 blur-3xl">
        <div class="w-[82.0625rem] h-[30rem]" style="clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%)">
        </div>
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600">
                Ayurvedic Fertility Treatment
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 ">
                Embracing Ayurveda for Holistic Fertility Care
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600">
                    At Yashada Wellness and Fertility Center, we specialize in Ayurvedic fertility treatments that
                    align with the body's natural rhythms. Our approach is tailored to each individual, focusing on
                    restoring balance and promoting overall health to enhance fertility.
                </p>
            </div>
        </div>
        <div
            class="mt-16 grid max-w-2xl grid-cols-1 gap-x-16 gap-y-8 text-center sm:mx-auto sm:max-w-none sm:grid-cols-2">
            <div class="rounded-2xl bg-white p-8">
                <div class="flex justify-center mb-6">
                    <div class="relative w-48 h-48">
                        <img alt="Our Approach" class="w-full h-full rounded-full bg-gray-100 object-cover"
                            src="https://assets.exadatum.com/assets/images/other/ayurvedic_treatment.webp" />
                        <div class="absolute inset-0 rounded-full ring-1 ring-inset ring-gray-900/10">
                        </div>
                    </div>
                </div>
                <h3 class="text-xl font-semibold leading-8 tracking-tight text-gray-900 text-center">
                    Our Approach
                </h3>
                <p class="mt-4 text-base leading-7 text-gray-600 text-center">
                    Our Ayurvedic fertility treatment approach is centered on personalized care. We work to identify
                    and treat the root causes of infertility, using a combination of dietary changes, herbal remedies,
                    Panchakarma therapies, and lifestyle adjustments.
                </p>
            </div>
            <div class="rounded-2xl bg-white p-8">
                <div class="flex justify-center mb-6">
                    <div class="relative w-48 h-48">
                        <img alt="Success Stories" class="w-full h-full rounded-full bg-gray-100 object-cover"
                            src="https://assets.exadatum.com/assets/images/other/success_stories.webp" />
                        <div class="absolute inset-0 rounded-full ring-1 ring-inset ring-gray-900/10">
                        </div>
                    </div>
                </div>
                <h3 class="text-xl font-semibold leading-8 tracking-tight text-gray-900 text-center">
                    Success Stories
                </h3>
                <p class="mt-4 text-base leading-7 text-gray-600 text-center">
                    We are proud of the countless success stories from individuals and couples who have achieved their
                    dreams of parenthood through our Ayurvedic treatments. Each journey is unique, and we celebrate
                    every success with the same passion and commitment to holistic wellness.
                </p>
            </div>
        </div>
    </div>
</div>