import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JobOptionsService {
  jobRoleOptions: string[] = [
    'Drone Pilot',
    'Drone Instructor',
    'Drone Engineer',
  ];

  jobExpLevelOptions: string[] = ['Internship', 'Associate', 'Engineer'];

  jobTypeOptions: string[] = ['Full Time', 'Part Time', 'Contract'];

  jobLocationOptions: string[] = [
    'Pune, Maharashtra, India',
    'Mumbai, Maharashtra, India',
    'Delhi, India',
  ];

  constructor() {}

  getJobRoleOptions(): string[] {
    return this.jobRoleOptions;
  }

  getJobExpLevelOptions(): string[] {
    return this.jobExpLevelOptions;
  }

  getJobTypeOptions(): string[] {
    return this.jobTypeOptions;
  }

  getJobLocationOptions(): string[] {
    return this.jobLocationOptions;
  }
}
