import { Component } from '@angular/core';

@Component({
  selector: 'app-specialty-panchakarma',
  templateUrl: './specialty-panchakarma.component.html',
  styleUrls: ['./specialty-panchakarma.component.scss']
})
export class SpecialtyPanchakarmaComponent {

}
