<footer aria-labelledby="footer-heading" class="bg-gray-50 dark:bg-gray-900">
    <h2 class="sr-only" id="footer-heading">Footer</h2>
    <div class="mx-auto max-w-7xl px-6 pb-8 pt-16 lg:px-8 lg:pt-24">
        <div class="xl:grid xl:grid-cols-3 xl:gap-8">
            <div class="grid grid-cols-2 gap-8 xl:col-span-2">
                <div class="md:grid md:grid-cols-2 md:gap-8">
                    <div>
                        <h3 class="text-base font-medium text-gray-900 dark:text-gray-100">Specialties</h3>
                        <ul class="mt-4 space-y-4" role="list">
                            <li>
                                <a class="text-base text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    routerLink="/specialty/fertility">
                                    Ayurvedic Treatments
                                </a>
                            </li>
                            <li>
                                <a class="text-base text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    routerLink="/specialty/fertility">
                                    Fertility Treatments
                                </a>
                            </li>
                            <li>
                                <a class="text-base text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    routerLink="/specialty/panchakarma">
                                    Panchakarma
                                </a>
                            </li>
                            <li>
                                <a class="text-base text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    routerLink="/specialty/wellness">
                                    Wellness Programs
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-12 md:mt-0">
                        <h3 class="text-base font-medium text-gray-900 dark:text-gray-100">Resources</h3>
                        <ul class="mt-4 space-y-4" role="list">
                            <li>
                                <a class="text-base text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    href="#blogs"> Blogs </a>
                            </li>
                            <li>
                                <a class="text-base text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    href="#case-studies"> Case
                                    Studies </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="md:grid md:grid-cols-2 md:gap-8">
                    <div>
                        <h3 class="text-base font-medium text-gray-900 dark:text-gray-100">Clinic</h3>
                        <ul class="mt-4 space-y-4" role="list">
                            <li>
                                <a class="text-base text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    routerLink="/cases">
                                    Testimonials
                                </a>
                            </li>
                            <li>
                                <a class="text-base text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    routerLink="/about">
                                    About Us
                                </a>
                            </li>
                            <li>
                                <a class="text-base text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    routerLink="/jobs">
                                    Careers
                                </a>
                            </li>
                            <li>
                                <a class="text-base text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    routerLink="/contact">
                                    Contact Us
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="mt-12 md:mt-0">
                        <h3 class="text-base font-medium text-gray-900 dark:text-gray-100">Legal</h3>
                        <ul class="mt-4 space-y-4" role="list">
                            <li>
                                <a class="text-base leading-6 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    routerLink="/legal/privacy">
                                    Privacy Policy
                                </a>
                            </li>
                            <li>
                                <a class="text-base leading-6 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    routerLink="/legal/terms">
                                    Terms of Service
                                </a>
                            </li>
                            <li>
                                <a class="text-base leading-6 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    routerLink="/legal/credits">
                                    Credits Policy
                                </a>
                            </li>
                            <li>
                                <a class="text-base leading-6 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    routerLink="/legal/cookies">
                                    Cookies Policy
                                </a>
                            </li>
                            <li>
                                <a class="text-base leading-6 text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300"
                                    routerLink="/legal/refund">
                                    Refund Policy
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="mt-12 xl:mt-0">
                <h3 class="text-base font-medium text-gray-900 dark:text-gray-100">Subscribe to our newsletter</h3>
                <p class="mt-4 text-base text-gray-500 dark:text-gray-400">Get the latest updates, articles, and
                    wellness tips delivered to
                    your inbox.</p>
                <form class="mt-4 sm:flex sm:max-w-md" id="email-subscribe-form">
                    <label class="sr-only" for="email-address"> Email address </label>
                    <input aria-labelledby="email-subscribe-form" autocomplete="email"
                        class="w-full min-w-0 appearance-none rounded-md border border-gray-300 bg-white px-4 py-2 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:placeholder-gray-400 focus:outline-none focus:ring-indigo-500 dark:border-gray-700 dark:bg-gray-900 dark:text-gray-100 dark:placeholder-gray-600"
                        id="email-address" name="email-address" placeholder="Enter your email" required=""
                        type="email" />
                    <div class="mt-3 rounded-md sm:ml-3 sm:mt-0 sm:flex-shrink-0">
                        <button aria-labelledby="email-subscribe-form"
                            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-indigo-500 dark:hover:bg-indigo-600"
                            data-style="slide-left" id="submit-button" type="submit">
                            Subscribe
                        </button>
                    </div>
                </form>
                <p class="hidden text-red-500 mt-2 sm:max-w-md" id="error-message" role="alert">Please enter a valid
                    email address.</p>
                <p class="hidden text-green-500 mt-2 sm:max-w-md" id="success-message" role="status">Thank you for
                    subscribing!</p>
            </div>
        </div>
        <div class="mt-12 border-t border-gray-200 pt-8 md:flex md:items-center md:justify-between lg:mt-16">
            <div class="flex space-x-6 md:order-2">
                <a class="text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300"
                    href="https://www.linkedin.com/company/yashada" aria-label="LinkedIn">
                    <span class="sr-only"> LinkedIn </span>
                    <svg aria-hidden="true" class="h-6 w-6" fill="currentColor" id="linkedin" viewbox="0 0 24 24">
                        <path
                            d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z">
                        </path>
                    </svg>
                </a>
                <a class="text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300"
                    href="https://www.facebook.com/yashada/" aria-label="Facebook">
                    <span class="sr-only"> Facebook </span>
                    <svg aria-hidden="true" class="h-6 w-6" fill="currentColor" viewbox="0 0 24 24">
                        <path clip-rule="evenodd"
                            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                            fill-rule="evenodd"></path>
                    </svg>
                </a>
                <a class="text-gray-400 hover:text-gray-500 dark:text-gray-400 dark:hover:text-gray-300"
                    href="https://www.instagram.com/yashada" aria-label="Instagram">
                    <span class="sr-only"> Instagram </span>
                    <svg aria-hidden="true" class="h-6 w-6" fill="currentColor" id="instagram" viewbox="0 0 24 24">
                        <path
                            d="M12,0A12,12,0,0,0,0,12,12,12,0,0,0,12,24,12,12,0,0,0,24,12,12,12,0,0,0,12,0Zm7.2,7.2a2.78,2.78,0,0,1,.077.6,16.232,16.232,0,0,1-.077,1.8,2.827,2.827,0,0,1-.713,1.992,2.827,2.827,0,0,1-1.992.713,16.232,16.232,0,0,1-1.8.077,16.232,16.232,0,0,1-1.8-.077,2.827,2.827,0,0,1-1.992-.713,2.827,2.827,0,0,1-.713-1.992,16.232,16.232,0,0,1-.077-1.8A16.232,16.232,0,0,1,7.2,7.8a2.827,2.827,0,0,1,.713-1.992,2.827,2.827,0,0,1,1.992-.713,16.232,16.232,0,0,1,1.8-.077,16.232,16.232,0,0,1,1.8.077,2.827,2.827,0,0,1,1.992.713,2.827,2.827,0,0,1,.713,1.992A16.232,16.232,0,0,1,19.2,7.2Zm-7.2,9.6A4.2,4.2,0,1,1,16.8,12,4.2,4.2,0,0,1,12,16.8Zm0-10.8A6.6,6.6,0,1,0,18.6,12,6.6,6.6,0,0,0,12,6Zm7.8-.6a1.2,1.2,0,1,0,1.2,1.2A1.2,1.2,0,0,0,19.8,5.4Z">
                        </path>
                    </svg>
                </a>
            </div>
            <p class="mt-8 text-base text-gray-400 md:order-1 md:mt-0 dark:text-gray-500"> &copy; <span
                    id="current-year">2024</span> <a href="https://www.yashada.clinic"
                    class="hover:text-gray-500 dark:hover:text-gray-300"> Yashada Wellness
                    &amp; Fertility Clinic</a>.
                All rights reserved.</p>
        </div>
    </div>
</footer>