<div class="bg-white dark:bg-gray-900">
    <div class="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
        <div class="mx-auto max-w-2xl text-center">
            <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
                Engage with Us
            </h2>
            <h2 class="text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-100 sm:text-4xl">
                Enhance Your Well-being.<br>Discover our holistic approach today.
            </h2>
            <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600 dark:text-gray-400">
                Experience the fusion of ancient Ayurvedic wisdom with modern wellness practices. Join us to start your
                journey towards a healthier and more balanced life.
            </p>
            <div class="mt-10 flex items-center justify-center gap-x-6">
                <button aria-label="Start Your Journey with Us"
                    class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    id="book-appointment" aria-haspopup="dialog" aria-expanded="false"
                    aria-controls="hs-scale-animation-modal" data-hs-overlay="#hs-scale-animation-modal">
                    Start Your Journey with Us
                </button>
            </div>
        </div>
    </div>
</div>