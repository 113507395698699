import { Component, OnInit } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Auth } from 'aws-amplify';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/login/login.service';
import { GeneralPreferences, selectGeneralPreferences, updateTheme, updateLanguage } from 'src/app/shared/state.store';

@Component({
  selector: 'app-layout-header',
  templateUrl: './layout-header.component.html',
  styleUrls: ['./layout-header.component.scss']
})
export class LayoutHeaderComponent implements OnInit {

  generalPreferences$: Observable<GeneralPreferences>;

  currentTheme: string = "light";

  languages: string[] = [
    'en',
    'mr',
    'hd'
    // jhipster-needle-i18n-language-constant - JHipster will add/remove languages in this array
  ];

  constructor(private store: Store, public translateService: TranslateService, public authenticator: AuthenticatorService, public loginService: LoginService) {
    this.generalPreferences$ = this.store.pipe(select(selectGeneralPreferences));
  }

  ngOnInit() {
    this.loginService.checkAuthStatus();
    this.generalPreferences$.subscribe(prefs => {
      if (prefs) {
        this.applyTheme(prefs.theme);
        this.applyLanguage(prefs.language);
      }
    });
  }

  close() {
    this.loginService.hide();
  }
  async signOut() {
    try {
      this.loginService.isStatusCheckDone = false;
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  openSignUpDialog() {
    this.loginService.show();
  }

  private applyLanguage(language: string): void {
    this.translateService.use(language);
  }

  changeTheme(newTheme: string): void {
    this.store.dispatch(updateTheme({ theme: newTheme }));
  }

  changeLanguage(newLanguage: string): void {
    this.store.dispatch(updateLanguage({ language: newLanguage }));
  }

  // Method to toggle the theme between light and dark
  toggleTheme() {
    const newTheme = this.currentTheme === 'dark' ? 'light' : 'dark';
    this.changeTheme(newTheme);
    this.applyTheme(newTheme);
  }

  // Method to apply the stored theme or default theme
  // Method to apply the theme class to the <html> element
  private applyTheme(theme: string) {
    this.currentTheme = theme;
    const html = document.querySelector('html');
    if (html) {
      if (theme === 'light') {
        html.classList.remove('dark');
        html.classList.add('light');
      } else {
        html.classList.remove('light');
        html.classList.add('dark');
      }
    }
  }
}