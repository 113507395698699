import { Component, Input } from '@angular/core';
import { Auth } from 'aws-amplify';
import { ApplyService } from 'src/app/apply/apply.service';
import { LoginService } from 'src/app/login/login.service';
import { Job } from 'src/app/shared/job.model';

@Component({
  selector: 'app-clinic-job-details',
  templateUrl: './clinic-job-details.component.html',
  styleUrls: ['./clinic-job-details.component.scss']
})
export class ClinicJobDetailsComponent {

  @Input() job!: Job;

  responsibilities!: string[];

  requirements!: string[];

  constructor(private applyService: ApplyService, public loginService: LoginService) { }

  ngOnInit() {
    if (!this.job.job_responsibilities) {
      this.responsibilities = [];
    } else {
      this.responsibilities = this.job.job_responsibilities.split('\n');
    }
    if (!this.job.job_requirements) {
      this.requirements = [];
    } else {
      this.requirements = this.job.job_requirements.split('\n');
    }
  }

  openApplyForJobDialog() {
    Auth.currentAuthenticatedUser()
      .then(() => {
        console.log('User is authenticated');
        this.applyService.show(this.job);
      })
      .catch(() => {
        console.log('User is not authenticated');
        this.loginService.show();
      });
  }

  getPublishedDate(): string {
    return this.fromEpochToDate(parseInt(this.job.job_publish_date));
  }

  getTimeSincePublished(): string {
    return this.toTimeSincePublished(parseInt(this.job.job_publish_date));
  }

  hasKeywords() {
    return (
      this.job.job_keywords &&
      this.job.job_keywords != null &&
      this.job.job_keywords != undefined &&
      this.job.job_keywords.trim() !== ''
    );
  }

  // Function to convert epoch time to date
  fromEpochToDate(epoch: number) {
    const date = new Date(epoch * 1000); // Multiply by 1000 as JavaScript works with milliseconds
    return date.toDateString(); // Convert to a human-readable date string
  }

  toTimeSincePublished(publishedEpoch: number) {
    const now = Date.now() / 1000; // Convert current time to epoch
    const diffInSeconds = now - publishedEpoch;

    const years = Math.floor(diffInSeconds / (365 * 24 * 60 * 60));
    const months = Math.floor(diffInSeconds / (30 * 24 * 60 * 60));
    const days = Math.floor(diffInSeconds / (24 * 60 * 60));
    const hours = Math.floor(diffInSeconds / (60 * 60));
    const minutes = Math.floor(diffInSeconds / 60);

    if (years > 0) {
      return years + ' year' + (years !== 1 ? 's' : '');
    } else if (months > 0) {
      return months + ' month' + (months !== 1 ? 's' : '');
    } else if (days > 0) {
      return days + ' day' + (days !== 1 ? 's' : '');
    } else if (hours > 0) {
      return hours + ' hour' + (hours !== 1 ? 's' : '');
    } else {
      return minutes + ' minute' + (minutes !== 1 ? 's' : '');
    }
  }
}
