import { Component } from '@angular/core';

@Component({
  selector: 'app-clinic-team',
  templateUrl: './clinic-team.component.html',
  styleUrls: ['./clinic-team.component.scss']
})
export class ClinicTeamComponent {

}
