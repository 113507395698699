import { Component } from '@angular/core';
import { JobOptionsService } from 'src/app/shared/job-options.service';
import { Job } from 'src/app/shared/job.model';
import { JobService } from 'src/app/shared/job.service';

@Component({
  selector: 'app-clinic-jobs',
  templateUrl: './clinic-jobs.component.html',
  styleUrls: ['./clinic-jobs.component.scss']
})
export class ClinicJobsComponent {
  searchQuery: string = '';
  filteredJobs: Job[] = [];

  constructor(
    public jobService: JobService,
    public jobOptionsService: JobOptionsService
  ) { }

  ngOnInit(): void {
    this.filteredJobs = this.jobService.getJobs();
  }

  onSearch(): void {
    this.jobService.filterJobsByQuery(this.searchQuery).subscribe((jobs: Job[]) => {
      this.filteredJobs = jobs;
    });
  }

  clearSearch(): void {
    this.searchQuery = '';
    this.filteredJobs = this.jobService.getJobs(); // Reset the filtered jobs to the initial state
  }

  onScroll(): void {
    //this.jobService.onScroll();
    //this.filteredJobs = this.jobService.getJobs(); // update the filtered jobs after scrolling
  }

}
