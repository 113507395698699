<div class="fixed bottom-4 right-4 flex flex-col space-y-2 opacity-0" id="nav-buttons">
    <!-- Go to Top Button -->
    <button aria-label="Go to top"
        class="inline-flex items-center justify-center w-8 h-8 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        id="go-to-top">
        <i class="fas fa-arrow-up"></i>
    </button>

    <!-- Go to Bottom Button -->
    <button aria-label="Go to bottom"
        class="inline-flex items-center justify-center w-8 h-8 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        id="go-to-bottom">
        <i class="fas fa-arrow-down"></i>
    </button>

    <!-- Chat on WhatsApp Button -->
    <a aria-label="Chat on WhatsApp"
        class="inline-flex items-center justify-center w-8 h-8 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-400"
        id="chat" href="https://wa.me/917030559550?text=Hello%20Yashada%20Clinic" target="_blank">
        <i class="fab fa-whatsapp"></i>
    </a>

    <!-- Plan Your Consultation Button -->
    <button aria-label="Plan Your Consultation"
        class="inline-flex items-center justify-center w-8 h-8 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-400"
        id="book-appointment" href="/book-appointment" aria-haspopup="dialog" aria-expanded="false"
        aria-controls="hs-scale-animation-modal" data-hs-overlay="#hs-scale-animation-modal">
        <i class="fas fa-calendar-check"></i>
    </button>

    <!-- Google Map Location Button -->
    <a aria-label="Google Map Location"
        class="inline-flex items-center justify-center w-8 h-8 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400"
        id="map-location" target="_blank" href="https://www.google.com/maps?q=17.7946481638003, 73.8293650455696">
        <i class="fas fa-map-marker-alt"></i>
    </a>
</div>