import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
} from '@angular/core';
import { LoginComponent } from './login.component';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  componentRef!: ComponentRef<LoginComponent>;
  private isShowing = false;
  public isUserAuthenticated: boolean = false;
  public isStatusCheckDone: boolean = false;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {
    this.checkAuthStatus();
  }

  show() {
    if (!this.isShowing) {
      this.isShowing = true;
      this.componentRef = this.componentFactoryResolver
        .resolveComponentFactory(LoginComponent)
        .create(this.injector);
      this.componentRef.instance.show = true;
      this.appRef.attachView(this.componentRef.hostView);
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
      document.body.appendChild(domElem);
    }
  }

  hide() {
    if (this.isShowing) {
      setTimeout(() => {
        this.appRef.detachView(this.componentRef.hostView);
        this.componentRef.instance.show = true;
        this.componentRef.destroy();
        this.isShowing = false;
      }, 500);
    }
  }

  async checkAuthStatus() {
    await Auth.currentAuthenticatedUser()
      .then(() => {
        this.isUserAuthenticated = true;
        console.log('User is authenticated');
      })
      .catch((error) => {
        // User is not authenticated
        // You can redirect to a login page or perform other actions
        this.isUserAuthenticated = false;
        console.log(error);
        console.log('User is not authenticated');
      });
      this.isStatusCheckDone = true;
      return this.isUserAuthenticated;
  }
}
