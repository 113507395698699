<div class="bg-white dark:bg-gray-900">
    <div class="px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <!-- Title -->
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-400">
                FAQs
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                Your Questions Answered About Our Fertility Treatment Packages
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                    At Yashada Wellness and Fertility Clinic, we understand that navigating fertility treatments can
                    raise many questions. Here, we address some of the most common inquiries to provide clarity and
                    support as you embark on your journey with us.
                </p>
            </div>
            <div class="mt-10 flex items-center justify-center gap-x-6">
                <button aria-label="Book Appointment If You Have Questions"
                    class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    id="book-appointment" aria-haspopup="dialog" aria-expanded="false"
                    aria-controls="hs-scale-animation-modal" data-hs-overlay="#hs-scale-animation-modal">
                    Book Appointment If You Have Questions
                </button>
            </div>
        </div>
        <!-- End Title -->

        <div class="mt-16 max-w-5xl mx-auto">
            <!-- Grid -->
            <div class="grid sm:grid-cols-2 gap-6 md:gap-12">
                <div>
                    <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        Can I switch packages once enrolled?
                    </h3>
                    <p class="mt-2 text-gray-600 dark:text-gray-400">
                        Yes, you can switch packages if your needs change. Contact our support team to discuss the
                        options and make adjustments as needed.
                    </p>
                </div>
                <!-- End Col -->

                <div>
                    <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        What does each package include?
                    </h3>
                    <p class="mt-2 text-gray-600 dark:text-gray-400">
                        Each package includes a combination of consultations, treatments, and follow-up care tailored to
                        your specific needs. Detailed descriptions are available for each package on our website.
                    </p>
                </div>
                <!-- End Col -->

                <div>
                    <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        Are the treatments covered by insurance?
                    </h3>
                    <p class="mt-2 text-gray-600 dark:text-gray-400">
                        Coverage for fertility treatments can vary. We recommend checking with your insurance provider
                        to
                        understand your benefits. Our team can assist with documentation if needed.
                    </p>
                </div>
                <!-- End Col -->

                <div>
                    <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        How do I know which package is right for me?
                    </h3>
                    <p class="mt-2 text-gray-600 dark:text-gray-400">
                        Our team offers personalized consultations to help you choose the package that best aligns with
                        your fertility goals and health needs. Reach out to schedule a consultation.
                    </p>
                </div>
                <!-- End Col -->

                <div>
                    <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        What if I need to cancel or reschedule my appointment?
                    </h3>
                    <p class="mt-2 text-gray-600 dark:text-gray-400">
                        You can cancel or reschedule your appointment by contacting us at least 24 hours in advance. We
                        strive to accommodate your needs and will work with you to find a suitable alternative.
                    </p>
                </div>
                <!-- End Col -->

                <div>
                    <h3 class="text-lg font-semibold text-gray-800 dark:text-gray-200">
                        Are there any additional costs I should be aware of?
                    </h3>
                    <p class="mt-2 text-gray-600 dark:text-gray-400">
                        All costs associated with your chosen package are outlined upfront. Any additional costs will be
                        communicated transparently before they are incurred.
                    </p>
                </div>
                <!-- End Col -->
            </div>
            <!-- End Grid -->
        </div>
    </div>
</div>