import { Component } from '@angular/core';

@Component({
  selector: 'app-legal-privacy',
  templateUrl: './legal-privacy.component.html',
  styleUrls: ['./legal-privacy.component.scss']
})
export class LegalPrivacyComponent {

}
