/* eslint-disable */
// EDIT redirectSignIn variable below to set app specific url
const awsConfig = {
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_p8NEfOnGi',
  aws_user_pools_web_client_id: '2g1lp37gpk4bpsk7krte8esrqt',
  oauth: {
    domain: 'us-east-1.auth.exadatum.com',
    scope: [
      'email',
      'openid',
      'phone',
      'profile',
      'aws.cognito.signin.user.admin',
    ],
    //redirectSignIn: 'http://localhost:4200',
    //redirectSignOut: 'http://localhost:4200',
    redirectSignIn: 'https://jobs.exadatum.com/',
    redirectSignOut: 'https://jobs.exadatum.com',
    responseType: 'code',
  },
  federationTarget: 'COGNITO_USER_POOLS',
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: ['FACEBOOK', 'GOOGLE'],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['SMS', 'TOTP'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 10,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS',
    ],
  },
  aws_cognito_verification_mechanisms: ['EMAIL', 'PHONE_NUMBER'],
  aws_user_files_s3_bucket_region: 'us-east-1', // (required) - Amazon S3 bucket region
  aws_user_files_s3_bucket: 'job-applications' // (required) - Amazon S3 bucket URI
};

export default awsConfig;
