import { Component } from '@angular/core';

@Component({
  selector: 'app-clinic-facilities',
  templateUrl: './clinic-facilities.component.html',
  styleUrls: ['./clinic-facilities.component.scss']
})
export class ClinicFacilitiesComponent {

}
