import { Component } from '@angular/core';

@Component({
  selector: 'app-legal-refund',
  templateUrl: './legal-refund.component.html',
  styleUrls: ['./legal-refund.component.scss']
})
export class LegalRefundComponent {

}
