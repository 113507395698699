import { Component } from '@angular/core';

@Component({
  selector: 'app-clinic-packages',
  templateUrl: './clinic-packages.component.html',
  styleUrls: ['./clinic-packages.component.scss']
})
export class ClinicPackagesComponent {

}
