import { Component } from '@angular/core';

@Component({
  selector: 'app-clinic-blogs',
  templateUrl: './clinic-blogs.component.html',
  styleUrls: ['./clinic-blogs.component.scss']
})
export class ClinicBlogsComponent {

}
