import { Component } from '@angular/core';

@Component({
  selector: 'app-clinic-education',
  templateUrl: './clinic-education.component.html',
  styleUrls: ['./clinic-education.component.scss']
})
export class ClinicEducationComponent {

}
