<!-- ========== HEADER ========== -->
<header
    class="flex flex-wrap py-3 md:justify-start md:flex-nowrap z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-900 dark:border-gray-700">
    <nav
        class="relative max-w-[85rem] w-full mx-auto md:flex md:items-center md:justify-between md:gap-3 py-2 px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between items-center gap-x-1">
            <a class="-m-1.5 p-1.5 flex items-center gap-x-2" href="/">
                <span class="sr-only"> Yashada </span>
                <img alt="Exadatum Logo" class="h-8 w-auto" src="assets/images/logo.png" />
                <span
                    class="text-lg font-semibold text-gray-900 dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                    Yashada </span>
            </a>
            <!-- Collapse Button -->
            <button type="button"
                class="hs-collapse-toggle md:hidden relative size-9 flex justify-center items-center font-medium text-[12px] rounded-lg border border-gray-200 text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                id="hs-header-base-collapse" aria-expanded="false" aria-controls="hs-header-base"
                aria-label="Toggle navigation" data-hs-collapse="#hs-header-base">
                <svg class="hs-collapse-open:hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                    stroke-linejoin="round">
                    <line x1="3" x2="21" y1="6" y2="6" />
                    <line x1="3" x2="21" y1="12" y2="12" />
                    <line x1="3" x2="21" y1="18" y2="18" />
                </svg>
                <svg class="hs-collapse-open:block shrink-0 hidden size-4" xmlns="http://www.w3.org/2000/svg" width="24"
                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round">
                    <path d="M18 6 6 18" />
                    <path d="m6 6 12 12" />
                </svg>
                <span class="sr-only">Toggle navigation</span>
            </button>
            <!-- End Collapse Button -->
        </div>

        <!-- Collapse -->
        <div id="hs-header-base"
            class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow md:block"
            aria-labelledby="hs-header-base-collapse">
            <div
                class="overflow-hidden overflow-y-auto max-h-[75vh] [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-gray-700 dark:[&::-webkit-scrollbar-thumb]:bg-gray-500">
                <div class="py-2 md:py-0 flex flex-col md:flex-row md:items-center gap-0.5 md:gap-1">
                    <div class="grow">
                        <div class="flex flex-col md:flex-row md:justify-end md:items-center gap-0.5 md:gap-3">

                            <!-- Ayurvedic Fertility Program Menu -->
                            <div
                                class="hs-dropdown [--strategy:static] md:[--strategy:absolute] [--adaptive:none] [--is-collapse:true] md:[--is-collapse:false]">
                                <button id="hs-header-base-mega-menu-ayurvedic-fertility" type="button"
                                    class="hs-dropdown-toggle w-full p-2 flex font-medium items-center text-sm text-gray-800 hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                    aria-haspopup="menu" aria-expanded="false"
                                    aria-label="Ayurvedic Fertility Program Menu">
                                    <svg class="shrink-0 size-4 me-3 md:me-2 block md:hidden"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <path
                                            d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" />
                                        <circle cx="12" cy="12" r="3" />
                                    </svg>
                                    Fertility Programs
                                    <svg class="hs-dropdown-open:-rotate-180 md:hs-dropdown-open:rotate-0 duration-300 shrink-0 size-4 ms-auto md:ms-1"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <path d="m6 9 6 6 6-6" />
                                    </svg>
                                </button>

                                <div class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 relative w-full min-w-60 hidden z-10 top-full start-0 before:absolute before:-top-5 before:start-0 before:w-full before:h-5"
                                    role="menu" aria-orientation="vertical"
                                    aria-labelledby="hs-header-base-mega-menu-ayurvedic-fertility">
                                    <div
                                        class="md:mx-6 lg:mx-8 md:bg-white md:rounded-lg md:shadow-md dark:md:bg-gray-800">
                                        <!-- Grid -->
                                        <div class="py-1 md:p-2 md:grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                                            <div class="flex flex-col">
                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path d="M20 12h-8m0 0H4m12 0V4M12 12v8" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Customized Fertility Plans</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Personalized Ayurvedic plans to enhance fertility and
                                                            support reproductive health.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path
                                                            d="M18 6c-1.5 0-3 1.5-3 3v2a3 3 0 0 0 3 3c1.5 0 3-1.5 3-3V9c0-1.5-1.5-3-3-3zM8 6c-1.5 0-3 1.5-3 3v2a3 3 0 0 0 3 3c1.5 0 3-1.5 3-3V9c0-1.5-1.5-3-3-3zM12 18h6m-6 0H6" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Herbal Supplements</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Natural herbal supplements designed to support reproductive
                                                            health and balance.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path d="M12 4v16m-8-8h16" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Detoxification Programs</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Comprehensive detox programs to cleanse and prepare the body
                                                            for conception.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->
                                            </div>
                                            <!-- End Col -->

                                            <div class="flex flex-col">
                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path d="M12 4v16m-8-8h16" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Diet and Lifestyle Counseling</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Guidance on diet and lifestyle adjustments to support
                                                            fertility and overall health.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path
                                                            d="M17 12.5c-.5 0-1.5-.5-1.5-2s1-2 1.5-2 1.5.5 1.5 2-.5 2-1.5 2zM21 6H3m18 12H3m18-6H3" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Fertility Yoga and Meditation</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Yoga and meditation sessions tailored to enhance fertility
                                                            and reduce stress.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->
                                            </div>
                                            <!-- End Col -->
                                        </div>
                                        <!-- End Grid -->
                                    </div>
                                </div>
                            </div>
                            <!-- End Ayurvedic Fertility Program Menu -->

                            <!-- Wellness Programs Menu -->
                            <div
                                class="hs-dropdown [--strategy:static] md:[--strategy:absolute] [--adaptive:none] [--is-collapse:true] md:[--is-collapse:false]">
                                <button id="hs-header-base-mega-menu-wellness-programs" type="button"
                                    class="hs-dropdown-toggle w-full p-2 flex font-medium items-center text-sm text-gray-800 hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                    aria-haspopup="menu" aria-expanded="false" aria-label="Wellness Programs Menu">
                                    <svg class="shrink-0 size-4 me-3 md:me-2 block md:hidden"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <path
                                            d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" />
                                        <circle cx="12" cy="12" r="3" />
                                    </svg>
                                    Wellness Programs
                                    <svg class="hs-dropdown-open:-rotate-180 md:hs-dropdown-open:rotate-0 duration-300 shrink-0 size-4 ms-auto md:ms-1"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <path d="m6 9 6 6 6-6" />
                                    </svg>
                                </button>

                                <div class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 relative w-full min-w-60 hidden z-10 top-full start-0 before:absolute before:-top-5 before:start-0 before:w-full before:h-5"
                                    role="menu" aria-orientation="vertical"
                                    aria-labelledby="hs-header-base-mega-menu-wellness-programs">
                                    <div
                                        class="md:mx-6 lg:mx-8 md:bg-white md:rounded-lg md:shadow-md dark:md:bg-gray-800">
                                        <!-- Grid -->
                                        <div class="py-1 md:p-2 md:grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                                            <div class="flex flex-col">
                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path
                                                            d="M5 3h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2z" />
                                                        <path d="M3 10h18" />
                                                        <path d="M10 3v18" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Stress Management</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Learn techniques to manage and reduce stress through our
                                                            specialized wellness programs.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <circle cx="12" cy="12" r="10" />
                                                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                                        <path d="M12 17h.01" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Mindfulness and Meditation</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Participate in guided sessions to enhance mindfulness and
                                                            achieve mental clarity.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <rect x="3" y="4" width="18" height="8" rx="3" />
                                                        <path d="M4 12v4a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4v-4" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Nutrition and Diet Planning</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Receive personalized diet plans and nutritional advice to
                                                            support your health goals.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->
                                            </div>
                                            <!-- End Col -->

                                            <div class="flex flex-col">
                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path d="M10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0z" />
                                                        <path d="M12 6v6l4 4" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Fitness and Exercise</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Engage in fitness programs designed to improve your physical
                                                            well-being.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path
                                                            d="M17 12.5c-.5 0-1.5-.5-1.5-2s1-2 1.5-2 1.5.5 1.5 2-.5 2-1.5 2zM21 6H3m18 12H3m18-6H3" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Holistic Health Assessments</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Get comprehensive health assessments to identify and address
                                                            your wellness needs.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->
                                            </div>
                                            <!-- End Col -->
                                        </div>
                                        <!-- End Grid -->
                                    </div>
                                </div>
                            </div>
                            <!-- End Wellness Programs Menu -->

                            <!-- Clinic Menu -->
                            <div
                                class="hs-dropdown [--strategy:static] md:[--strategy:absolute] [--adaptive:none] [--is-collapse:true] md:[--is-collapse:false]">
                                <button id="hs-header-base-mega-menu-fullwidth" type="button"
                                    class="hs-dropdown-toggle w-full p-2 flex font-medium items-center text-sm text-gray-800 hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                    aria-haspopup="menu" aria-expanded="false" aria-label="Mega Menu">
                                    <svg class="shrink-0 size-4 me-3 md:me-2 block md:hidden"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <path
                                            d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" />
                                        <circle cx="12" cy="12" r="3" />
                                    </svg>
                                    About Clinic
                                    <svg class="hs-dropdown-open:-rotate-180 md:hs-dropdown-open:rotate-0 duration-300 shrink-0 size-4 ms-auto md:ms-1"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <path d="m6 9 6 6 6-6" />
                                    </svg>
                                </button>

                                <div class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 relative w-full min-w-60 hidden z-10 top-full start-0 before:absolute before:-top-5 before:start-0 before:w-full before:h-5"
                                    role="menu" aria-orientation="vertical"
                                    aria-labelledby="hs-header-base-mega-menu-fullwidth">
                                    <div
                                        class="md:mx-6 lg:mx-8 md:bg-white md:rounded-lg md:shadow-md dark:md:bg-gray-800">
                                        <!-- Grid -->
                                        <div class="py-1 md:p-2 md:grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                                            <div class="flex flex-col">
                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/about">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path d="M12 5v14M5 12h14" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Our Clinic</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Learn more about Yashada Wellness & Fertility Center,
                                                            including our values and mission.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/team">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path d="M12 5v14M5 12h14" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Our Team</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">Meet the
                                                            experienced professionals behind our clinic.</p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/services">
                                                    <svg class=" shrink-0 size-4 mt-1 text-gray-800
                                                    dark:text-gray-200" xmlns="http://www.w3.org/2000/svg" width="24"
                                                        height="24" viewBox="0 0 24 24" fill="none"
                                                        stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                        stroke-linejoin="round">
                                                        <path d="M12 5v14M5 12h14" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Our Services</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Explore the range of Ayurvedic treatments and wellness
                                                            programs we offer.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/facilities">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path d="M12 5v14M5 12h14" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Our Facilities
                                                        </p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Discover our facilities designed to support
                                                            your wellness journey.
                                                        </p>
                                                    </div>

                                                </a>
                                                <!-- End Link -->
                                            </div>
                                            <!-- End Col -->

                                            <div class="flex flex-col">
                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/cases">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path d="M12 5v14M5 12h14" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Patient Stories</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">Read
                                                            testimonials and experiences from our patients.</p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/contact">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path d="M12 5v14M5 12h14" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Contact Us</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">Get in
                                                            touch with us for any inquiries or appointments.</p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/jobs">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path d="M12 5v14M5 12h14" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Careers</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">Join a
                                                            team of passionate healthcare professionals.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->
                                            </div>
                                            <!-- End Col -->

                                            <div class="mt-2 md:mt-0 flex flex-col">
                                                <span
                                                    class="ms-2.5 mb-2 font-semibold text-xs uppercase text-gray-800 dark:text-gray-200">Customer
                                                    stories</span>

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-5 items-center rounded-xl hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/">
                                                    <img class="size-32 rounded-lg"
                                                        src="https://images.unsplash.com/photo-1648737967328-690548aec14f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=320&h=320&q=80"
                                                        alt="Avatar" />
                                                    <div class="grow">
                                                        <p class="text-sm text-gray-800 dark:text-gray-400">
                                                            Our patients share their positive experiences with our
                                                            clinic and treatments.
                                                        </p>
                                                        <p
                                                            class="mt-3 inline-flex items-center gap-x-1 text-sm text-indigo-600 decoration-2 group-hover:underline group-focus:underline font-medium dark:text-indigo-400">
                                                            Learn more
                                                            <svg class="shrink-0 size-4"
                                                                xmlns="http://www.w3.org/2000/svg" width="24"
                                                                height="24" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round">
                                                                <path d="m9 18 6-6-6-6" />
                                                            </svg>
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->
                                            </div>
                                            <!-- End Col -->
                                        </div>
                                        <!-- End Grid -->
                                    </div>
                                </div>
                            </div>
                            <!-- End Clinic Menu -->

                            <!-- Resources Menu -->
                            <div
                                class="hs-dropdown [--strategy:static] md:[--strategy:absolute] [--adaptive:none] [--is-collapse:true] md:[--is-collapse:false]">
                                <button id="hs-header-base-mega-menu-fullwidth" type="button"
                                    class="hs-dropdown-toggle w-full p-2 flex font-medium items-center text-sm text-gray-800 hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                    aria-haspopup="menu" aria-expanded="false" aria-label="Mega Menu">
                                    <svg class="shrink-0 size-4 me-3 md:me-2 block md:hidden"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <path
                                            d="M12.22 2h-.44a2 2 0 0 0-2 2v.18a2 2 0 0 1-1 1.73l-.43.25a2 2 0 0 1-2 0l-.15-.08a2 2 0 0 0-2.73.73l-.22.38a2 2 0 0 0 .73 2.73l.15.1a2 2 0 0 1 1 1.72v.51a2 2 0 0 1-1 1.74l-.15.09a2 2 0 0 0-.73 2.73l.22.38a2 2 0 0 0 2.73.73l.15-.08a2 2 0 0 1 2 0l.43.25a2 2 0 0 1 1 1.73V20a2 2 0 0 0 2 2h.44a2 2 0 0 0 2-2v-.18a2 2 0 0 1 1-1.73l.43-.25a2 2 0 0 1 2 0l.15.08a2 2 0 0 0 2.73-.73l.22-.39a2 2 0 0 0-.73-2.73l-.15-.08a2 2 0 0 1-1-1.74v-.5a2 2 0 0 1 1-1.74l.15-.09a2 2 0 0 0 .73-2.73l-.22-.38a2 2 0 0 0-2.73-.73l-.15.08a2 2 0 0 1-2 0l-.43-.25a2 2 0 0 1-1-1.73V4a2 2 0 0 0-2-2z" />
                                        <circle cx="12" cy="12" r="3" />
                                    </svg>
                                    Resources
                                    <svg class="hs-dropdown-open:-rotate-180 md:hs-dropdown-open:rotate-0 duration-300 shrink-0 size-4 ms-auto md:ms-1"
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round">
                                        <path d="m6 9 6 6 6-6" />
                                    </svg>
                                </button>

                                <div class="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] md:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 relative w-full min-w-60 hidden z-10 top-full start-0 before:absolute before:-top-5 before:start-0 before:w-full before:h-5"
                                    role="menu" aria-orientation="vertical"
                                    aria-labelledby="hs-header-base-mega-menu-fullwidth">
                                    <div
                                        class="md:mx-6 lg:mx-8 md:bg-white md:rounded-lg md:shadow-md dark:md:bg-gray-800">
                                        <!-- Grid -->
                                        <div class="py-1 md:p-2 md:grid md:grid-cols-2 lg:grid-cols-3 gap-4">
                                            <div class="flex flex-col">

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <circle cx="12" cy="12" r="10" />
                                                        <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                                                        <path d="M12 17h.01" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Wellness Programs</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Learn more about our wellness initiatives that promote
                                                            mental, physical, and spiritual well-being.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <rect x="3" y="4" width="18" height="8" rx="3" />
                                                        <path d="M4 12v4a4 4 0 0 0 4 4h8a4 4 0 0 0 4-4v-4" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Fertility Care</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Discover our specialized treatments and support for
                                                            fertility health and wellness.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->
                                            </div>
                                            <!-- End Col -->

                                            <div class="flex flex-col">
                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/cases">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <circle cx="12" cy="12" r="10" />
                                                        <path d="M8 12a4 4 0 1 1 8 0 4 4 0 0 1-8 0z" />
                                                        <path d="M12 8v4l2 2" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Patient Stories</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Read about our patients' experiences and how our treatments
                                                            have positively impacted their lives.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/education">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <rect x="2" y="7" width="20" height="14" rx="2" ry="2" />
                                                        <path d="M16 7V5a4 4 0 0 0-8 0v2" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Educational Resources</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Access informative articles, videos, and guides on various
                                                            aspects of health and wellness.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->


                                            </div>
                                            <!-- End Col -->

                                            <div class="flex flex-col">

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/blogs">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            Blogs</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Stay updated with the latest articles and insights from our
                                                            health and wellness experts.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->

                                                <!-- Link -->
                                                <a class="p-3 flex gap-x-4 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 rounded-lg dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                                    routerLink="/faqs">
                                                    <svg class="shrink-0 size-4 mt-1 text-gray-800 dark:text-gray-200"
                                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                        stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                                        <path d="M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01" />
                                                    </svg>
                                                    <div class="grow">
                                                        <p class="font-medium text-sm text-gray-800 dark:text-gray-200">
                                                            FAQs</p>
                                                        <p class="text-sm text-gray-500 dark:text-gray-500">
                                                            Get answers to the most frequently asked questions about our
                                                            services and treatments.
                                                        </p>
                                                    </div>
                                                </a>
                                                <!-- End Link -->
                                            </div>
                                            <!-- End Col -->
                                        </div>
                                        <!-- End Grid -->
                                    </div>
                                </div>
                            </div>
                            <!-- End Resources Menu -->
                        </div>
                    </div>

                    <div class="my-2 md:my-0 md:mx-2">
                        <div class="w-full h-px md:w-px md:h-4 bg-gray-100 md:bg-gray-300 dark:bg-gray-700"></div>
                    </div>

                    <!-- Button Group -->
                    <div class="flex flex-wrap items-center gap-x-1.5">
                        <button aria-label="Plan Your Consultation"
                            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-gray-900 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 md:text-white md:bg-indigo-600 md:hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 md:focus:ring-indigo-500"
                            id="book-appointment" aria-haspopup="dialog" aria-expanded="false"
                            aria-controls="hs-scale-animation-modal" data-hs-overlay="#hs-scale-animation-modal">
                            <i class="fa-regular fa-calendar md:hidden"></i>
                            <span class="hidden md:inline-block">Lets Meet</span>
                        </button>

                        <button aria-label="My Account"
                            class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-gray-900 bg-white dark:text-gray-100 dark:bg-gray-800 hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:focus:ring-indigo-300"
                            id="book-appointment" aria-haspopup="dialog" aria-expanded="false"
                            aria-controls="hs-scale-animation-modal" data-hs-overlay="#hs-scale-animation-modal">
                            <i class="fa-regular fa-user md:hidden"></i>
                            <span class="hidden md:inline-block">My Account</span>
                        </button>


                        <!-- <div class="hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none]">
                            <button id="hs-navbar-example-dropdown" type="button"
                                class="hs-dropdown-toggle space-x-2 p-2 flex items-center text-sm text-gray-800 hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:text-gray-200 dark:hover:bg-gray-700 dark:focus:bg-gray-700"
                                aria-haspopup="menu" aria-expanded="false" aria-label="Mega Menu">
                                <div alt="flag-img"
                                    [ngClass]="'fi fi-' + (translateService.currentLang | findFlagFromLanguageKey) + ' h-[1rem] w-[1rem] rounded-full'">
                                </div>                                
                            </button>

                            <div class="hs-dropdown-menu transition-[opacity,margin] ease-in-out duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 z-10 bg-white sm:shadow-md rounded-lg p-1 space-y-1 dark:bg-gray-900 sm:dark:border dark:border-gray-700 dark:divide-gray-700 before:absolute top-full sm:border before:-top-5 before:start-0 before:w-full before:h-5 hidden"
                                role="menu" aria-orientation="vertical" aria-labelledby="hs-navbar-example-dropdown">
                                <a *ngFor="let language of languages" (click)="changeLanguage(language)"
                                    class="flex items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-gray-300 dark:focus:bg-gray-700 dark:focus:text-gray-300"
                                    routerLink="/">
                                    <span alt="flag-img"
                                        [ngClass]="'fi fis fi-' + (language | findFlagFromLanguageKey) + ' rounded-full'">
                                    </span>
                                    <span class="text-sm text-gray-800 dark:text-white">
                                        {{ language | findLanguageFromKey }}
                                    </span>
                                </a>
                            </div>
                        </div> -->

                        <button type="button"
                            class="hs-dark-mode-active:hidden block hs-dark-mode font-medium text-gray-800 rounded-full hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:text-gray-200 dark:hover:bg-gray-800 dark:focus:bg-gray-800"
                            data-hs-theme-click-value="dark" (click)="toggleTheme()">
                            <span class="group inline-flex shrink-0 justify-center items-center size-9">
                                <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z"></path>
                                </svg>
                            </span>
                        </button>

                        <button type="button"
                            class="hs-dark-mode-active:block hidden hs-dark-mode font-medium text-gray-800 rounded-full hover:bg-gray-200 focus:outline-none focus:bg-gray-200 dark:text-gray-200 dark:hover:bg-gray-800 dark:focus:bg-gray-800"
                            data-hs-theme-click-value="light" (click)="toggleTheme()">
                            <span class="group inline-flex shrink-0 justify-center items-center size-9">
                                <svg class="shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <circle cx="12" cy="12" r="4"></circle>
                                    <path d="M12 2v2"></path>
                                    <path d="M12 20v2"></path>
                                    <path d="m4.93 4.93 1.41 1.41"></path>
                                    <path d="m17.66 17.66 1.41 1.41"></path>
                                    <path d="M2 12h2"></path>
                                    <path d="M20 12h2"></path>
                                    <path d="m6.34 17.66-1.41 1.41"></path>
                                    <path d="m19.07 4.93-1.41 1.41"></path>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <!-- End Button Group -->
                </div>
            </div>
        </div>
        <!-- End Collapse -->
    </nav>
</header>
<!-- ========== END HEADER ========== -->