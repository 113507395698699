<!-- Refund Policy Section -->
<div class="py-16 px-6 lg:px-8 bg-white dark:bg-gray-900">
    <div class="mx-auto max-w-7xl">
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-300">
                Refund Policy
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                Yashada Wellness & Fertility Clinic Refund Policy
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                    Effective Date: 23 February 2023<br />
                    Last Updated: 23 February 2023
                </p>
            </div>
        </div>
        <div class="mt-10">
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Cancellation</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                Patients may request a cancellation of their services provided by Yashada Wellness and Fertility Clinic
                by
                contacting our support team via email or phone. The cancellation request must include relevant
                patient information and the reason for cancellation. Cancellations will be processed in
                accordance with the terms outlined below.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Refund</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                We offer refunds under the following conditions:
            </p>
            <ul class="list-disc ml-8 mb-4 text-gray-600 dark:text-gray-400">
                <li class="mb-2">
                    <span class="font-semibold text-gray-900 dark:text-gray-100">Treatment Packages:</span>
                    For multi-session treatment packages, patients can cancel within the first 7 days of starting the
                    treatment
                    to receive a full refund. Cancellation requests received after this period will be refunded on a
                    prorated
                    basis, deducting the services utilized and an administration fee.
                </li>
                <li class="mb-2">
                    <span class="font-semibold text-gray-900 dark:text-gray-100">Single Session Treatments:</span>
                    For single session treatments, patients can request a cancellation 48 hours before the scheduled
                    appointment
                    to receive a full refund. Cancellations made within 48 hours of the appointment are not eligible for
                    a refund.
                </li>
                <li class="mb-2">
                    <span class="font-semibold text-gray-900 dark:text-gray-100">Third-Party Fees:</span>
                    Yashada Wellness and Fertility Clinic is not responsible for refunding any third-party fees incurred
                    as part of the treatment.
                </li>
            </ul>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Processing Time</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                Refunds will be processed within 7-10 business days from the date of the cancellation request. The
                refund will
                be issued using the original payment method unless otherwise specified.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Non-Refundable Items</h2>
            <p class="mb-4 text-gray-600 dark:text-gray-400">
                Certain services, such as personalized treatment plans or consultations, may be deemed non-refundable
                and will
                be clearly stated during the appointment booking or treatment plan discussion.
            </p>
            <p class="mt-4 mb-4 text-gray-600 dark:text-gray-400">
                Please note that this is a general outline and may not cover all specific scenarios. It is recommended
                to review
                the complete Cancellation & Refund Policy on Yashada Wellness and Fertility Clinic's official website
                for more
                detailed and up-to-date information.
            </p>
            <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Contact Us</h2>
            <p class="text-gray-600 dark:text-gray-400">
                If you have any questions or concerns about our Privacy Policy, please contact us at
                <a href="mailto:legal&#64;yashada.clinic"
                    class="text-indigo-500 dark:text-indigo-400 underline">legal&#64;yashada.clinic</a>.
            </p>
        </div>
    </div>
</div>