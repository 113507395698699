import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import videojs from 'video.js';
import Player from "video.js/dist/types/player";

@Component({
    selector: 'app-vjs-player',
    template: `
    <video #target class="video-js w-full object-cover rounded-t-xl" controls muted playsinline preload="none"></video>
  `,
    styleUrls: [],
    encapsulation: ViewEncapsulation.None,
})

export class VideoJsPlayerComponent implements OnInit, OnDestroy {
    @ViewChild('target', { static: true })
    target!: ElementRef;

    // See options: https://videojs.com/guides/options
    @Input()
    options!: {
        fluid?: boolean;
        aspectRatio?: string;
        autoplay: boolean;
        sources: {
            src: string;
            type: string;
        }[];
    };

    player!: Player;

    constructor(
        private elementRef: ElementRef,
    ) { }

    // Instantiate a Video.js player OnInit
    ngOnInit() {
        this.player = videojs(this.target.nativeElement, this.options, function onPlayerReady() {
            //console.log('onPlayerReady', this);
        });
    }

    // Dispose the player OnDestroy
    ngOnDestroy() {
        this.destroyPlayer();
    }

    destroyPlayer() {
        if (this.player) {
            this.player.dispose();
        }
    }
}