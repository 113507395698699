import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

/**
 * Handles keys for which translation is missing
 *
 * @export
 * @class SwachetMissingTranslationHandler
 * @implements {MissingTranslationHandler}
 */
export class AppMissingTranslationHandler implements MissingTranslationHandler {
    /**
     *  Handles keys for which translation is missing
     *
     * @param {MissingTranslationHandlerParams} params
     * @return {*}
     * @memberof SwachetMissingTranslationHandler
     */
    handle(params: MissingTranslationHandlerParams) {
        const key = params.key;
        return `NO-TRANSLATION-FOUND[${key}]`;
    }
}
