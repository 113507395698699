import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Job } from './job.model';

export interface MailChimpResponse {
  result: string;
  msg: string;
}

@Injectable({
  providedIn: 'root'
})
export class MailChimpService {
  mailChimpEndpoint =
  'https://swachet.us11.list-manage.com/subscribe/post?u=8d7a21713e76633f433ed83ce&amp;id=88490c5e62&amp;f_id=001772e0f0';

  constructor(private http: HttpClient) {}

  subscribe(name:string, email: string, job:Job): Promise<MailChimpResponse> {
    return new Promise<MailChimpResponse>((resolve, reject) => {
      if (!email || email.trim() === '') {
        reject(new Error('Invalid email'));
        return;
      }
      const params = new HttpParams()
        .set('FNAME', name)
        .set('EMAIL', email)
        .set('MESSAGE', job.job_code)        
        .set('b_8d7a21713e76633f433ed83ce_88490c5e62', ''); // hidden input name
      const mailChimpUrl = this.mailChimpEndpoint + params.toString();

      // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
      this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
