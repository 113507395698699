import { Component, Input, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  @Input() show = false;

  constructor(private loginService: LoginService) {

  }

  close() {
    this.loginService.hide();
  }

}
