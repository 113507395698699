import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-clinic-home-schedule',
  templateUrl: './clinic-home-schedule.component.html',
  styleUrls: ['./clinic-home-schedule.component.scss']
})
export class ClinicHomeScheduleComponent implements OnInit {
  appointmentForm!: FormGroup;
  isInternational: boolean = false;
  countryCodes = ['+1', '+91', '+44']; // Add more country codes as needed
  cities = ['Pune', 'Mumbai', 'Delhi']; // Add more cities as needed
  countries = ['United States', 'India', 'United Kingdom']; // Add more countries as needed

  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.appointmentForm = this.fb.group({
      name: ['', Validators.required],
      isInternational: [false],
      email: ['', [Validators.email]], // Optional email field
      countryCode: ['+91', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      city: ['', Validators.required],
      country: [''],
      comments: [''],
      consent: [false, Validators.requiredTrue]
    });

    // Subscribe to changes in the isInternational checkbox
    this.appointmentForm.get('isInternational')?.valueChanges.subscribe(value => {
      this.isInternational = value;
      this.toggleLocationFields(value);
    });
  }

  toggleLocationFields(isInternational: boolean): void {
    const cityControl = this.appointmentForm.get('city');
    const countryControl = this.appointmentForm.get('country');

    if (isInternational) {
      cityControl?.clearValidators();
      cityControl?.setValue('');
      countryControl?.setValidators([Validators.required]);
    } else {
      cityControl?.setValidators([Validators.required]);
      countryControl?.clearValidators();
      countryControl?.setValue('');
    }

    cityControl?.updateValueAndValidity();
    countryControl?.updateValueAndValidity();
  }

  onSubmit(): void {
    if (this.appointmentForm.valid) {
      // Handle form submission, e.g., send the data to the server
      console.log('Form Data:', this.appointmentForm.value);
    } else {
      // Handle form errors, e.g., show validation messages
      console.log('Form is invalid');
    }
  }

  // Getters for easy access in the template
  get name() {
    return this.appointmentForm.get('name');
  }

  get email() {
    return this.appointmentForm.get('email');
  }

  get mobile() {
    return this.appointmentForm.get('mobile');
  }

  get city() {
    return this.appointmentForm.get('city');
  }

  get country() {
    return this.appointmentForm.get('country');
  }

  get consent() {
    return this.appointmentForm.get('consent');
  }
}
