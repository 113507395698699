<!-- Terms of Use Section -->
<div class="py-16 px-6 lg:px-8 bg-white dark:bg-gray-900">
    <div class="mx-auto max-w-7xl">
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-300">
                Terms of Use
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                Yashada Wellness & Fertility Clinic Terms of Use
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                    Effective Date: 23 February 2023<br />
                    Last Updated: 23 February 2023
                </p>
            </div>
        </div>
        <div class="mt-10 space-y-6">
            <div>
                <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Acceptance of Terms</h2>
                <p class="mb-4 text-gray-600 dark:text-gray-400">
                    Welcome to Yashada Wellness. These Terms of Use govern your access and use of our website. By
                    accessing or using
                    our website, you agree to comply with these terms and all applicable laws and regulations. If you do
                    not agree with these terms, please do not use our website.
                </p>
            </div>
            <div>
                <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Intellectual Property</h2>
                <p class="mb-4 text-gray-600 dark:text-gray-400">
                    All content on our website, including text, graphics, images, logos, and audio/video materials, is
                    the property
                    of Yashada Wellness or its licensors and is protected by intellectual property laws. You may not
                    use, reproduce,
                    distribute, or modify any content from our website without prior written consent.
                </p>
            </div>
            <div>
                <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Use of Website</h2>
                <p class="mb-4 text-gray-600 dark:text-gray-400">
                    You agree to use our website for lawful purposes and in a manner that does not infringe upon or
                    restrict the
                    rights of others. Prohibited activities include but are not limited to:
                </p>
                <ul class="list-disc ml-8 mb-4 text-gray-600 dark:text-gray-400">
                    <li class="mb-2">Attempting to gain unauthorized access to our website or its related systems.</li>
                    <li class="mb-2">Engaging in any form of fraudulent or malicious activity.</li>
                    <li class="mb-2">Posting or transmitting any unlawful, defamatory, or harmful content.</li>
                    <li class="mb-2">Interfering with the proper functioning of our website.</li>
                    <li class="mb-2">Using automated means, such as bots or scripts, to access or interact with our
                        website.</li>
                </ul>
            </div>
            <div>
                <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Third-Party Links</h2>
                <p class="mb-4 text-gray-600 dark:text-gray-400">
                    Our website may contain links to third-party websites or services. These links are provided for your
                    convenience, and we have no control over the content or availability of these websites. We do not
                    endorse or assume any responsibility for any third-party websites or services.
                </p>
            </div>
            <div>
                <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Limitation of Liability</h2>
                <p class="mb-4 text-gray-600 dark:text-gray-400">
                    In no event shall Yashada Wellness or its affiliates be liable for any indirect, incidental,
                    special, or consequential damages arising out of or in connection with your use of our website. We
                    make no warranties or representations regarding the accuracy, reliability, or availability of our
                    website.
                </p>
            </div>
            <div>
                <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Indemnification</h2>
                <p class="mb-4 text-gray-600 dark:text-gray-400">
                    You agree to indemnify and hold harmless Yashada Wellness, its officers, directors, employees, and
                    agents from any claims, liabilities, damages, or expenses (including attorney's fees) arising out of
                    or in connection with your use of our website or any violation of these Terms of Use.
                </p>
            </div>
            <div>
                <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Modifications to Terms</h2>
                <p class="mb-4 text-gray-600 dark:text-gray-400">
                    We reserve the right to modify or update these Terms of Use at any time without prior notice. The
                    updated terms will be effective immediately upon posting on our website. Your continued use of our
                    website after any modifications signifies your acceptance of the updated terms.
                </p>
            </div>
            <div>
                <h2 class="text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">Contact Us</h2>
                <p class="text-gray-600 dark:text-gray-400">
                    If you have any questions or concerns about these Terms of Use, please contact us at
                    <a href="mailto:legal@yashada.clinic"
                        class="text-indigo-600 dark:text-indigo-400 underline">legal&#64;yashada.clinic</a>.
                </p>
            </div>
        </div>
    </div>
</div>