<div class="relative isolate bg-white dark:bg-gray-900 pb-32 pt-24 sm:pt-32">
    <div class="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
        aria-hidden="true">
        <div class="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] dark:from-[#ff6b6b] dark:to-[#4a4a4a]"
            style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)">
        </div>
    </div>
    <div class="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-32 opacity-25 blur-3xl sm:pt-40 xl:justify-end"
        aria-hidden="true">
        <div class="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] dark:from-[#ff6b6b] dark:to-[#4a4a4a] xl:ml-0 xl:mr-[calc(50%-12rem)]"
            style="clip-path: polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)">
        </div>
    </div>
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
        <div class="text-center">
            <h2 class="text-lg font-semibold text-indigo-600 dark:text-indigo-300">
                What Our Patients Say
            </h2>
            <h1 class="max-w-2xl mx-auto text-3xl font-bold tracking-tight text-gray-900 dark:text-gray-100">
                Transformative Experiences with Our Holistic Approach
            </h1>
            <div class="mt-6 max-w-xl mx-auto">
                <p class="text-lg leading-8 text-gray-600 dark:text-gray-400">
                    At Yashada Wellness and Fertility Clinic, our patients' success stories inspire us. Hear directly
                    from those who have experienced the profound impact of our holistic treatments and compassionate
                    care.
                </p>
            </div>
            <div class="mt-10 flex items-center justify-center gap-x-6">
                <button aria-label="Start Your Journey with Us"
                    class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    id="book-appointment" aria-haspopup="dialog" aria-expanded="false"
                    aria-controls="hs-scale-animation-modal" data-hs-overlay="#hs-scale-animation-modal">
                    Start Your Journey With Us
                </button>
            </div>
        </div>
        <div
            class="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 dark:text-gray-100 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
            <figure
                class="rounded-2xl bg-white dark:bg-gray-800 shadow-lg ring-1 ring-gray-900/5 dark:ring-gray-700 sm:col-span-2 xl:col-start-2 xl:row-end-1">
                <blockquote
                    class="p-6 text-lg font-semibold leading-7 tracking-tight text-gray-900 dark:text-gray-100 sm:p-12 sm:text-xl sm:leading-8">
                    <p>“I have never felt better in my life. The personalized Ayurvedic treatments and the support from
                        the team have truly transformed my well-being. Thank you for helping me find balance and
                        health!”</p>
                </blockquote>
                <figcaption
                    class="flex flex-wrap items-center gap-x-4 gap-y-4 border-t border-gray-900/10 dark:border-gray-700 px-6 py-4 sm:flex-nowrap">
                    <img class="h-10 w-10 flex-none rounded-full bg-gray-50 dark:bg-gray-700"
                        src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt="">
                    <div class="flex-auto">
                        <div class="font-semibold text-gray-900 dark:text-gray-100">Aarav Patel</div>
                        <div class="text-gray-600 dark:text-gray-400">aravpatel</div>
                    </div>
                    <button aria-label=" Start Your Journey with Us"
                        class="inline-flex items-center px-4 py-3 border border-transparent text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        aria-controls="hs-subscription-with-image" data-hs-overlay="#hs-subscription-with-image">
                        <div class="space-x-2"><i class="fa-regular fa-circle-play"></i>
                        </div>
                    </button>
                </figcaption>
            </figure>

            <div class="space-y-8 xl:contents xl:space-y-0">
                <div class="space-y-8 xl:row-span-2">
                    <figure
                        class="rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg ring-1 ring-gray-900/5 dark:ring-gray-700">
                        <blockquote class="text-gray-900 dark:text-gray-100">
                            <p>“The care I received at Yashada Wellness was unparalleled. Their holistic approach and
                                genuine concern made a significant difference in my fertility journey. I highly
                                recommend their services.”</p>
                        </blockquote>
                        <figcaption class="mt-6 flex items-center gap-x-4">
                            <img class="h-10 w-10 rounded-full bg-gray-50 dark:bg-gray-700"
                                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt="">
                            <div>
                                <div class="font-semibold text-gray-900 dark:text-gray-100">Maya Sharma</div>
                                <div class="text-gray-600 dark:text-gray-400">&#64;mayasharma</div>
                            </div>
                            <button aria-label=" Start Your Journey with Us"
                                class="inline-flex items-center px-4 py-3 border border-transparent text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                aria-controls="hs-subscription-with-image"
                                data-hs-overlay="#hs-subscription-with-image">
                                <div class="space-x-2"><i class="fa-regular fa-circle-play"></i>
                                </div>
                            </button>
                        </figcaption>
                    </figure>

                    <!-- More testimonials... -->
                </div>
                <div class="space-y-8 xl:row-start-1">
                    <figure
                        class="rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg ring-1 ring-gray-900/5 dark:ring-gray-700">
                        <blockquote class="text-gray-900 dark:text-gray-100">
                            <p>“The expertise and compassion of the team at Yashada Wellness made all the difference.
                                Their holistic methods were exactly what I needed to improve my overall health.”</p>
                        </blockquote>
                        <figcaption class="mt-6 flex items-center gap-x-4">
                            <img class="h-10 w-10 rounded-full bg-gray-50 dark:bg-gray-700"
                                src="https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt="">
                            <div>
                                <div class="font-semibold text-gray-900 dark:text-gray-100">Rohan Desai</div>
                                <div class="text-gray-600 dark:text-gray-400">&#64;rohandesai</div>
                            </div>
                            <button aria-label=" Start Your Journey with Us"
                                class="inline-flex items-center px-4 py-3 border border-transparent text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                aria-controls="hs-subscription-with-image"
                                data-hs-overlay="#hs-subscription-with-image">
                                <div class="space-x-2"><i class="fa-regular fa-circle-play"></i>
                                </div>
                            </button>
                        </figcaption>
                    </figure>

                    <!-- More testimonials... -->
                </div>
            </div>
            <div class="space-y-8 xl:contents xl:space-y-0">
                <div class="space-y-8 xl:row-start-1">
                    <figure
                        class="rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg ring-1 ring-gray-900/5 dark:ring-gray-700">
                        <blockquote class="text-gray-900 dark:text-gray-100">
                            <p>“The holistic approach at Yashada Wellness exceeded my expectations. Their personalized
                                care and attention to detail were truly remarkable.”</p>
                        </blockquote>
                        <figcaption class="mt-6 flex items-center gap-x-4">
                            <img class="h-10 w-10 rounded-full bg-gray-50 dark:bg-gray-700"
                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt="">
                            <div>
                                <div class="font-semibold text-gray-900 dark:text-gray-100">Ananya Gupta</div>
                                <div class="text-gray-600 dark:text-gray-400">&#64;ananyagupta</div>
                            </div>
                            <button aria-label=" Start Your Journey with Us"
                                class="inline-flex items-center px-4 py-3 border border-transparent text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                aria-controls="hs-subscription-with-image"
                                data-hs-overlay="#hs-subscription-with-image">
                                <div class="space-x-2"><i class="fa-regular fa-circle-play"></i>
                                </div>
                            </button>
                        </figcaption>
                    </figure>

                    <!-- More testimonials... -->
                </div>
                <div class="space-y-8 xl:row-span-2">
                    <figure
                        class="rounded-2xl bg-white dark:bg-gray-800 p-6 shadow-lg ring-1 ring-gray-900/5 dark:ring-gray-700">
                        <blockquote class="text-gray-900 dark:text-gray-100">
                            <p>“From the moment I walked in, I knew I was in the right place. The holistic treatments
                                and the team's dedication helped me achieve my health goals.”</p>
                        </blockquote>
                        <figcaption class="mt-6 flex items-center gap-x-4">
                            <img class="h-10 w-10 rounded-full bg-gray-50 dark:bg-gray-700"
                                src="https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt="">
                            <div>
                                <div class="font-semibold text-gray-900 dark:text-gray-100">Vikram Singh</div>
                                <div class="text-gray-600 dark:text-gray-400">&#64;vikramsingh</div>
                            </div>
                            <button aria-label=" Start Your Journey with Us"
                                class="inline-flex items-center px-4 py-3 border border-transparent text-sm rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                aria-controls="hs-subscription-with-image"
                                data-hs-overlay="#hs-subscription-with-image">
                                <div class="space-x-2"><i class="fa-regular fa-circle-play"></i>
                                </div>
                            </button>
                        </figcaption>
                    </figure>

                    <!-- More testimonials... -->
                </div>
            </div>
        </div>
    </div>
</div>