import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientDetailsComponent } from './patient/patient-details/patient-details.component';
import { PatientListComponent } from './patient/patient-list/patient-list.component';
import { DoctorHomeComponent } from './doctor/doctor-home/doctor-home.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { PatientHomeComponent } from './patient/patient-home/patient-home.component';
import { StaffHomeComponent } from './staff/staff-home/staff-home.component';
import { StaffListComponent } from './staff/staff-list/staff-list.component';
import { StaffDetailsComponent } from './staff/staff-details/staff-details.component';
import { ClinicAboutComponent } from './clinic/clinic-about/clinic-about.component';
import { ClinicBlogsComponent } from './clinic/clinic-blogs/clinic-blogs.component';
import { ClinicCasesComponent } from './clinic/clinic-cases/clinic-cases.component';
import { ClinicHomeComponent } from './clinic/clinic-home/clinic-home.component';
import { ClinicJobsComponent } from './clinic/clinic-jobs/clinic-jobs.component';
import { ClinicComponent } from './clinic/clinic/clinic.component';
import { LegalComponent } from './legal/legal/legal.component';
import { LegalHomeComponent } from './legal/legal-home/legal-home.component';
import { LegalCookiesComponent } from './legal/legal-cookies/legal-cookies.component';
import { LegalCreditsComponent } from './legal/legal-credits/legal-credits.component';
import { LegalPrivacyComponent } from './legal/legal-privacy/legal-privacy.component';
import { LegalTermsComponent } from './legal/legal-terms/legal-terms.component';
import { SpecialtyComponent } from './specialty/specialty/specialty.component';
import { SpecialtyFertilityComponent } from './specialty/specialty-fertility/specialty-fertility.component';
import { SpecialtyPanchakarmaComponent } from './specialty/specialty-panchakarma/specialty-panchakarma.component';
import { LegalRefundComponent } from './legal/legal-refund/legal-refund.component';
import { ClinicContactComponent } from './clinic/clinic-contact/clinic-contact.component';
import { SpecialtyWellnessComponent } from './specialty/specialty-wellness/specialty-wellness.component';
import { ClinicServicesComponent } from './clinic/clinic-services/clinic-services.component';
import { ClinicFacilitiesComponent } from './clinic/clinic-facilities/clinic-facilities.component';
import { ClinicPackagesComponent } from './clinic/clinic-packages/clinic-packages.component';
import { ClinicFaqsComponent } from './clinic/clinic-faqs/clinic-faqs.component';
import { ClinicTeamComponent } from './clinic/clinic-team/clinic-team.component';
import { StaffComponent } from './staff/staff/staff.component';
import { StaffCalenderComponent } from './staff/staff-calender/staff-calender.component';
import { PatientComponent } from './patient/patient/patient.component';
import { PatientCalenderComponent } from './patient/patient-calender/patient-calender.component';
import { DoctorCalenderComponent } from './doctor/doctor-calender/doctor-calender.component';
import { DoctorComponent } from './doctor/doctor/doctor.component';
import { DoctorAccountComponent } from './doctor/doctor-account/doctor-account.component';
import { DoctorDetailsComponent } from './doctor/doctor-details/doctor-details.component';
import { DoctorListComponent } from './doctor/doctor-list/doctor-list.component';
import { StaffAccountComponent } from './staff/staff-account/staff-account.component';
import { PatientAccountComponent } from './patient/patient-account/patient-account.component';
import { ClinicEducationComponent } from './clinic/clinic-education/clinic-education.component';

const routes: Routes = [
  {
    path: '',
    component: ClinicComponent,
    children: [
      { path: '', component: ClinicHomeComponent },
      { path: 'about', component: ClinicAboutComponent },
      { path: 'blogs', component: ClinicBlogsComponent },
      { path: 'cases', component: ClinicCasesComponent },
      { path: 'jobs', component: ClinicJobsComponent },
      { path: 'contact', component: ClinicContactComponent },
      { path: 'services', component: ClinicServicesComponent },
      { path: 'facilities', component: ClinicFacilitiesComponent },
      { path: 'packages', component: ClinicPackagesComponent },
      { path: 'education', component: ClinicEducationComponent },
      { path: 'faqs', component: ClinicFaqsComponent },
      { path: 'team', component: ClinicTeamComponent },
      {
        path: 'specialty',
        component: SpecialtyComponent,
        children: [
          { path: 'fertility', component: SpecialtyFertilityComponent },
          { path: 'wellness', component: SpecialtyWellnessComponent },
          { path: 'panchakarma', component: SpecialtyPanchakarmaComponent },
        ],
      },
      {
        path: 'legal',
        component: LegalComponent,
        children: [
          { path: '', component: LegalHomeComponent },
          { path: 'privacy', component: LegalPrivacyComponent },
          { path: 'terms', component: LegalTermsComponent },
          { path: 'cookies', component: LegalCookiesComponent },
          { path: 'credits', component: LegalCreditsComponent },
          { path: 'refund', component: LegalRefundComponent },
        ],
      },
      {
        path: 'doctor',
        component: DoctorComponent,
        children: [
          { path: '', component: DoctorHomeComponent },
          { path: 'list', component: DoctorListComponent },
          { path: 'details/:id', component: DoctorDetailsComponent },
          { path: 'account', component: DoctorAccountComponent },
          { path: 'calender', component: DoctorCalenderComponent },

        ],
      },
      {
        path: 'staff',
        component: StaffComponent,
        children: [
          { path: '', component: StaffHomeComponent },
          { path: 'list', component: StaffListComponent },
          { path: 'details/:id', component: StaffDetailsComponent },
          { path: 'account', component: StaffAccountComponent },
          { path: 'calender', component: StaffCalenderComponent },

        ],
      },
      {
        path: 'patient',
        component: PatientComponent,
        children: [
          { path: '', component: PatientHomeComponent },
          { path: 'list', component: PatientListComponent },
          { path: 'details/:id', component: PatientDetailsComponent },
          { path: 'account', component: PatientAccountComponent },
          { path: 'calender', component: PatientCalenderComponent },

        ],
      },
    ],
  },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
