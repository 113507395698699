import { Component, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { TabComponent } from '../tab/tab.component';


@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;
  activeTab: number = 0;

  ngAfterContentInit() {
    this.activateTab(0);
  }

  activateTab(index: number) {
    this.activeTab = index;
    this.tabs.forEach((tab, i) => tab.isActive = i === index);
  }
}
